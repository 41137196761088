var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "global-events" },
    [
      _vm.showModal
        ? [
            _c("transition", { attrs: { name: "modal" } }, [
              _c("div", { staticClass: "modal-mask" }, [
                _c(
                  "div",
                  {
                    class:
                      "modal-wrapper " +
                      (_vm.global_events[_vm.index].global_event_type == 3
                        ? "modal-wrapper-bulletin"
                        : ""),
                  },
                  [
                    _c("div", { staticClass: "button-moves" }, [
                      _vm.index > 0
                        ? _c(
                            "button",
                            {
                              staticClass: "button-move button-move-left",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.movement(-1)
                                },
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "arrow",
                                  attrs: {
                                    width: "8",
                                    height: "14",
                                    viewBox: "0 0 8 14",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M7 1L0.999999 7L7 13",
                                      stroke: "white",
                                      "stroke-width": "2",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.index < _vm.global_events.length - 1
                        ? _c(
                            "button",
                            {
                              staticClass: "button-move button-move-right",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.movement(1)
                                },
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "arrow",
                                  attrs: {
                                    width: "8",
                                    height: "14",
                                    viewBox: "0 0 8 14",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M1 13L7 7L1 1",
                                      stroke: "white",
                                      "stroke-width": "2",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _c(
                      "button",
                      {
                        staticClass: "button-move button-close",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.goToStart()
                          },
                        },
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticStyle: {
                              "enable-background": "new 0 0 490.4 490.4",
                            },
                            attrs: {
                              version: "1.1",
                              id: "Capa_1",
                              xmlns: "http://www.w3.org/2000/svg",
                              "xmlns:xlink": "http://www.w3.org/1999/xlink",
                              x: "0px",
                              y: "0px",
                              viewBox: "0 0 490.4 490.4",
                              "xml:space": "preserve",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M180.3,310.1c2.4,2.4,5.5,3.6,8.7,3.6s6.3-1.2,8.7-3.6l47.6-47.6l47.6,47.6c2.4,2.4,5.5,3.6,8.7,3.6s6.3-1.2,8.7-3.6\n                c4.8-4.8,4.8-12.5,0-17.3l-47.8-47.6l47.6-47.6c4.8-4.8,4.8-12.5,0-17.3s-12.5-4.8-17.3,0l-47.6,47.6l-47.6-47.6\n                c-4.8-4.8-12.5-4.8-17.3,0s-4.8,12.5,0,17.3l47.6,47.6l-47.6,47.6C175.5,297.6,175.5,305.3,180.3,310.1z",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        class:
                          "modal-container " +
                          (_vm.global_events[_vm.index].global_event_type == 3
                            ? "bulletin"
                            : ""),
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "content-event" },
                          [
                            _c("div", { staticClass: "event-header" }, [
                              _vm.global_events[_vm.index] != null
                                ? _c("img", {
                                    staticClass: "event-img-cover",
                                    attrs: {
                                      src: _vm.global_events[_vm.index].cover,
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                            ]),
                            _vm.global_events[_vm.index].global_event_type == 1
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "event-content" },
                                    [
                                      _c("p", { staticClass: "event-name" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.global_events[_vm.index].name
                                          )
                                        ),
                                      ]),
                                      _c(
                                        "p",
                                        { staticClass: "event-description" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.global_events[_vm.index]
                                                .description
                                            )
                                          ),
                                        ]
                                      ),
                                      _c("p", { staticClass: "event-date" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatDate")(
                                              _vm.global_events[_vm.index]
                                                .event_date
                                            )
                                          )
                                        ),
                                      ]),
                                      _c(
                                        "p",
                                        { staticClass: "event-time-zone" },
                                        [_vm._v("Horario (GMT-5)")]
                                      ),
                                      _c("count-down", {
                                        attrs: {
                                          year: parseInt(
                                            _vm.global_events[
                                              _vm.index
                                            ].event_date
                                              .split("T")[0]
                                              .split("-")[0]
                                          ),
                                          month: parseInt(
                                            _vm.global_events[
                                              _vm.index
                                            ].event_date
                                              .split("T")[0]
                                              .split("-")[1]
                                          ),
                                          day: parseInt(
                                            _vm.global_events[
                                              _vm.index
                                            ].event_date
                                              .split("T")[0]
                                              .split("-")[2]
                                          ),
                                          hour: parseInt(
                                            _vm.global_events[
                                              _vm.index
                                            ].event_date
                                              .split("T")[1]
                                              .split(":")[0]
                                          ),
                                          minute: 0,
                                          second: 0,
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "contentainer-actions" },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "event-join",
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.assist.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                      Regístrate gratis\n                    "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "go-start",
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.goToStart.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                      Cerrar\n                    "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            _vm.global_events[_vm.index].global_event_type == 2
                              ? [
                                  _c("div", { staticClass: "event-content" }, [
                                    _c("p", { staticClass: "event-name" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.global_events[_vm.index].name
                                        )
                                      ),
                                    ]),
                                    _c(
                                      "p",
                                      { staticClass: "event-description" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.global_events[_vm.index]
                                              .description
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "contentainer-actions-survey",
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "event-survey",
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.assist.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      Diligenciar encuesta\n                    "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "go-start",
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.goToStart.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      Cerrar\n                    "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                        _c("div", { staticClass: "dates" }),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }