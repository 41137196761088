var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notifications" },
    [
      _c(
        "vx-card",
        { attrs: { title: "Notificaciones" } },
        _vm._l(_vm.notifications, function (notification, index_notification) {
          return _c(
            "ul",
            { key: index_notification, staticClass: "activity-timeline" },
            [
              _c("li", [
                _c(
                  "div",
                  { class: _vm.classTimeLines[notification.type_id] },
                  [
                    _c("feather-icon", {
                      attrs: {
                        icon: _vm.icons[notification.type_id],
                        svgClasses: "text-white stroke-current w-5 h-5",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "timeline-info" }, [
                  _c("p", { staticClass: "font-semibold" }, [
                    _vm._v(_vm._s(notification.title)),
                  ]),
                  _c("span", { staticClass: "activity-desc" }, [
                    _vm._v(_vm._s(notification.content)),
                  ]),
                ]),
                _c("small", { staticClass: "text-grey activity-e-time" }, [
                  _vm._v(_vm._s(_vm.convertTimeStamp(notification.timestamp))),
                ]),
              ]),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }