<template>
  <div>
    <vs-popup background-color="rgba(255,255,255,.6)"  class=""  title="Proceso" :active.sync="popupShowActive">
      <div class="vx-col w-full lg:w-1/1 sm:w-1/1 mb-base">
        <div class="vx-card p-2">          
          <div class="text-center">
            <h4 style="color: #5F0090">{{project.data_basic.person_full_name}}</h4>
            <p class="text-grey">Cédula: {{project.data_basic.person_id}}</p>
            <p class="text-grey">{{project.data_basic.current_activity}}</p>
          </div>
          <vs-tabs v-if="Object.keys(details).length > 0">
            <vs-tab v-bind:label="detail.title" v-for="(detail, index_detail) in details" :key="index_detail">              
              <vs-table :data="users" noDataText="">
                <template slot="thead">
                  <vs-th>
                    {{detail.observations}}
                  </vs-th>
                </template>
              </vs-table>          
            </vs-tab>
          </vs-tabs>          
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
export default {
  props: {
    popupShowActive: Boolean,
    project: {},
    details: {}
  },
  data (){
    return{
      // project: this.$store.getters['todo/getProject'],
      colorx:"rgba(255,255,255,255)"
    }
  },
  methods: {

  },
  created(){

  },
  mounted(){

  },
  components:{

  }
}
</script>

<style>
  .vs-popup{
    width: 80% !important;
  }
</style>