var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-row go-to-poll" }, [
    _c("div", { staticClass: "vx-col w-full lg:w-1/1 mb-base col-link" }, [
      _vm.link != null
        ? _c(
            "a",
            {
              staticClass: "link-go-to-poll",
              attrs: { href: _vm.link, target: "_blank" },
            },
            [_vm._v(_vm._s(_vm.description))]
          )
        : _c("p", [_vm._v("Link no suministrado")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }