<template>
  <div>
    <vx-card v-if="project != {}">
      <vs-row vs-w="12">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="2" vs-sm="4" vs-xs="12" ></vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="4" vs-xs="12">
          <div class="text-center">
            <h4 style="color: #5F0090">{{this.project.data_basic.person_full_name}}</h4>
            <p class="text-grey">Cédula: {{this.project.data_basic.person_id}}</p>
            <p class="text-grey">{{this.project.data_basic.current_activity}}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="2" vs-sm="4" vs-xs="12">
          <div class="con-vs-avatar mx-auto my-6 block 80px" style="width: 80px; height: 80px; background: rgb(195, 195, 195);">
            <div class="con-img vs-avatar--con-img"><img v-bind:src="clientLogo" alt=""></div>
          </div>
        </vs-col>
      </vs-row>
      <vs-row vs-w="12">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="2" vs-sm="4" vs-xs="12" >
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="4" vs-xs="12">
          <vx-card>
            <div class="vx-row">
              <div class="vx-col w-full lg:w-1/2 mb-base" v-if="project.percentage.make_processes.length > 0">      
                <vs-list>
                  <vs-list-header title="Procesos Realizados" class="title-list"></vs-list-header>
                  <vs-list-item icon-pack="feather" icon="icon-check" class="item-list" v-bind:title="proccess" subtitle="" v-for="(proccess, index_proccess) in project.percentage.make_processes" :key="index_proccess"></vs-list-item>
                </vs-list>
              </div>      
              <div class="vx-col w-full lg:w-1/2 mb-base" v-if="project.percentage.missing_processes.length > 0">      
                <vs-list>
                  <vs-list-header title="Procesos faltantes" class="title-list"></vs-list-header>
                  <vs-list-item icon-pack="feather" icon="icon-clock" class="item-list" v-bind:title="proccess" subtitle="" v-for="(proccess, index_proccess) in project.percentage.missing_processes" :key="index_proccess">
                    <academic-details :project="project" v-if="proccess == 'Académico.' || proccess == 'Académico'"/>
                  </vs-list-item>
                </vs-list>            
              </div>        
            </div>
          </vx-card>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="2" vs-sm="4" vs-xs="12">
        </vs-col>
      </vs-row>      
      <div class="text-center" v-if="this.project.overall_final_name != ''">
        <h1 v-bind:style="'color: ' + colorConceptGeneralText(this.project.overall_final)">{{this.project.overall_final_name}}</h1>
      </div>
      <br>
      <div class="vx-row" v-if="this.project.overall_final_name != ''">              

        <div class="vx-col md:w-1/6 w-full ">
          <h6 class="table-title">Resultado:</h6>
        </div>                            
        <div class="vx-col md:w-1/6 w-full ">
          {{this.project.data_basic.final_result_title}}
        </div>              

        <div class="vx-col md:w-1/6 w-full">
          <h6 class="table-title">Fecha de creación:</h6>
        </div>                                    
        <div class="vx-col md:w-1/6 w-full">
          {{this.project.data_basic.created_at}}
        </div>

        <div class="vx-col md:w-1/6 w-full">
          <h6 class="table-title">Creado por:</h6>
        </div>                            
        <div class="vx-col md:w-1/6 w-full">
          {{this.project.data_basic.client_user_name}}
        </div>                                                                  

      </div>
      <br>
      <div class="vx-row">
        <div class="vx-col md:w-1/6 w-full ">
          <h6 class="table-title">Ciudad:</h6>    
        </div>                            
        <div class="vx-col md:w-1/6 w-full">
          {{this.project.data_basic.city_name}}
        </div>
        <div class="vx-col md:w-1/6 w-full">
          <h6 class="table-title">Tipo de proceso:</h6>
        </div>                                    
        <div class="vx-col md:w-1/6 w-full">
          {{this.project.project_type_name}}
        </div>                                              
      </div>
      <br>              
    </vx-card>
  </div>

</template>

<script>
import AcademicDetails from './academic-details.vue'
export default {
  props: {
    project: Object
  },
  data (){
    return{
      clientLogo: ''
    }
  },
  methods: {
    colorConceptGeneral(value){
      console.log("colorConceptGeneral ", value)
      var color = "rgb(153, 153, 153)"
      if(value >= 4){
        color = "rgb(80, 181, 75)"
      }
      if(value == 3){
        color = "rgb(250, 224, 2)"
      }        
      if(value <= 2){
        color = "rgb(201, 74, 23)"
      }        
      return color;
    }, 
    colorConceptGeneralText(value){
      var color = "rgb(153, 153, 153)"
      if(value == 0){
        color = "rgb(201, 74, 23)"
      }              
      if(value == 1){
        color = "rgb(80, 181, 75)"
      }
      if(value == 2){
        color = "rgb(250, 224, 2)"
      }
      return color;
    },           
    activeUserImg() {
      var userInfo = localStorage.getItem('userInfo');
      var photoUrl = JSON.parse(userInfo).photoUrl
      var photo = ''
      if(photoUrl != null){
          photoUrl = photoUrl.replace('//', 'https://')
          photoUrl = photoUrl.replace('development', 'production')
          photo = photoUrl;
      }else{
          photo = this.$store.state.AppActiveUser.img;
      }            
      return photo;
    }
  },
  created(){
    this.clientLogo = this.activeUserImg();
  },
  mounted(){

  },
  components:{
    'academic-details': AcademicDetails
  }
}
</script>

<style>
  .table-title{
    /* background-color: #fdf8ff; */
    font-weight: bold;
  }
  .text-row{
    font-size: 0.5rem !important;
  }
  .title-list{
    font-weight: 500;
  }
  .item-list{
    font-size: 0.7rem
  }
  .vs-icon.notranslate.icon-scale.feather.icon-check.null{
    color: green;
  }
  .vs-icon.notranslate.icon-scale.feather.icon-clock.null{
    color: red;
  }  
</style>