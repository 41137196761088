<template>
  <div class="notifications">
    <div id="popupsProject"></div>
    <div id="popupsProjectFormsMissing"></div>
    <div class="vx-row" v-if="projectShow != {}">
      <div class="vx-col w-full lg:w-1/3 mb-base" v-if="projectsInProcess != []">      
        <vx-card title="Nuevos">
          <ul class="activity-timeline" v-for="(projectInProcess, index_inProcess) in projectsInProcess" :key="index_inProcess">
            <li>
              <div class="timeline-info">
                <p class="font-semibold">{{ projectInProcess.full_name }}</p>
                <span class="activity-desc">Proceso: {{projectInProcess.project_type}}</span>                
              </div>
              <vs-row>
                <vs-col vs-offset="10" v-tooltip="'col - 1'" vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                  <vs-button class="button-notification" @click="showNotifications(projectInProcess.id)" icon="notification_important" size="26px" color="primary" v-if="projectInProcess.notifications.length > 0" type="flat"></vs-button>                
                </vs-col>
              </vs-row>              
              <vs-row vs-type="flex" vs-justify="space-between">
                <vs-col class="ago" vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                </vs-col>                
                <vs-col class="view-more-in-process" vs-type="flex" vs-justify="center" vs-align="center" vs-w="10">
                  <vs-button class="button-view-more" color="primary" type="flat" @click="getProjectFormsMissing(projectInProcess.id)">Pendiente por</vs-button>                  
                </vs-col>                                                
              </vs-row>                                        
              <div class="vx-col w-1/1 mb-3">
                <vx-tooltip color="primary" :text="informationAdvance(projectInProcess)">
                  <vs-progress class="block mt-1" :percent="parseInt(projectInProcess.percentage.percentage_complete)" color="primary"></vs-progress>
                </vx-tooltip>  
              </div>
            </li>
          </ul>
        </vx-card>          
      </div>
      <div class="vx-col w-full lg:w-1/3 mb-base" v-if="projectsPreliminaries != []">      
        <vx-card title="En proceso">
          <ul class="activity-timeline" v-for="(projectPreliminaries, index_Preliminaries) in projectsPreliminaries" :key="index_Preliminaries">
            <li>
              <div class="timeline-info">
                <p class="font-semibold">{{ projectPreliminaries.full_name }}</p>
                <span class="activity-desc">Proceso: {{projectPreliminaries.project_type}}</span>
              </div>   
              <vs-row>
                <vs-col vs-offset="10" v-tooltip="'col - 1'" vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                  <vs-button class="button-notification" @click="showNotifications(projectPreliminaries.id)" icon="notification_important" size="26px" color="primary" v-if="projectPreliminaries.notifications.length > 0" type="flat"></vs-button>                
                </vs-col>
              </vs-row>                                       
              <vs-row vs-type="flex" vs-justify="space-between">
                <vs-col class="ago-preliminary" vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                  Creado el: {{convertTimeStamp(projectPreliminaries.created_at)}}
                </vs-col>                
                <vs-col class="view-more" vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                  <vs-button  class="button-view-more" color="primary" type="flat" @click="getProjectFormsMissing(projectPreliminaries.id)">Pendiente por</vs-button>                  
                </vs-col>                                                
              </vs-row>                            
              <div class="vx-col w-1/1 mb-3">
                <vx-tooltip color="primary" v-bind:text="informationAdvance(projectPreliminaries)">
                  <vs-progress class="block mt-1" :percent="parseInt(projectPreliminaries.percentage.percentage_complete)" color="primary"></vs-progress>
                </vx-tooltip>  
              </div>
            </li>
          </ul>
        </vx-card>          
        <div class="point-notifications"></div>
      </div>
      <div class="vx-col w-full lg:w-1/3 mb-base" v-if="projectsFinished != []">      
        <vx-card title="Finalizado">
          <ul class="activity-timeline" v-for="(projectFinished, index_projectsFinished) in projectsFinished" :key="index_projectsFinished">
            <li>                
              <div class="timeline-info">
                <p class="font-semibold" @click="showProject(projectFinished.id)">{{ projectFinished.full_name }}</p>
                  <span class="activity-desc">Proceso: {{projectFinished.project_type}}</span>                  
                  <vs-row>
                    <vs-col class="text-result" vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                      <p class="font-semibold">{{projectFinished.overall_final_name}}</p>
                    </vs-col>
                    <vs-col vs-type="flex" vs-align="center" vs-w="6">
                      <vs-icon v-bind:icon="iconsOveral[projectFinished.overall_final]" size="medium" v-bind:color="colorsOveral[projectFinished.overall_final]"></vs-icon>                  
                    </vs-col>  
                  </vs-row>                  
              </div>                  
              <vs-row vs-type="flex" vs-justify="space-between">
                <vs-col class="ago" vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                </vs-col>                
                <vs-col class="view-more" vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                  <vs-button  class="button-view-more" color="primary" type="flat" @click="showProject(projectFinished.id)">Ver más</vs-button>                  
                </vs-col>                                                
              </vs-row>              
              <div class="vx-col w-1/1 mb-3">
                <vx-tooltip color="primary" text="100%">
                  <vs-progress class="block mt-1" :percent="projectFinished.percentage.percentage_complete" color="primary"></vs-progress>
                </vx-tooltip>  
              </div>              
            </li>
          </ul>
        </vx-card>                  
      </div>       
    </div>    
  </div>
</template>
<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import api from '../mixins/api'
import moment from 'moment'
import 'moment/locale/es'  // without this line it didn't work
moment.locale('es')
import ProjectComponent from '../components-globalwork/projects/Project'
import ProjectFormsMissing from '../components-globalwork/projects/ProjectFormsMissing'
import ProjectViewNotification from './projects/ProjectViewNotification'

import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
  mixins: [api],
  data(){
    return{
      popupShowActive: false,
      popupShowNotificationActive: false,
      projectShow: {},
      icons: ['PlayIcon', 'BellIcon', 'CheckIcon', 'AlertCircleIcon', 'FrownIcon'],
      iconsOveral: ['highlight_off', 'sentiment_satisfied_alt', 'warning', 'av_timer'],
      colorsOveral: ['danger', 'success', 'warning', 'warning'],
      classTimeLines: ['timeline-icon bg-primary', 'timeline-icon bg-dark', 'timeline-icon bg-success', 'timeline-icon bg-warning', 'timeline-icon bg-danger'],
      projectsInProcess: [],
      projectsPreliminaries: [],
      projectsFinished: []
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  methods:{
    ...mapMutations(['changeCurrentProject']),
    showNotifications(project_id){
      var user_id = this.userInfo.user.id  
      var path = "";
      var url = "";                
      path = "/api/v1/reports/notifications_project_news?project_id=" + project_id + "&user_id="+user_id;
      url = this.url()+path
      this.requestGetWithoutContent(url, {}).then(response => {
          if(response.data.success == true){
              this.popupShowNotificationActive = true;
              console.log("response.data.response ", response.data.response.details)
              var details = response.data.response.details                        
              var project = response.data.response.project
              var projectComponent = Vue.extend(ProjectViewNotification)
              var instanceProjectComponent = new projectComponent({
                  propsData: {project: project, details: details, popupShowActive: this.popupShowNotificationActive}
              })
              instanceProjectComponent.$mount()
              var popup = document.getElementById('popupViewNotification')
              popup.appendChild(instanceProjectComponent.$el)                    
          }else{
              console.log("error -> " + JSON.stringify(response.data.message));          
          }
      }, (err) => {
          console.log('err users -> ' +  JSON.stringify(err))
      })
    },
    informationAdvance(project){
      var information = project.percentage.percentage_complete+"% de avance. Pendiente por: "
      project.percentage.missing_processes.forEach(element => {
        information += element
      });      
      return information
    },
    convertTimeStamp(timestamp){
      var date = new Date(timestamp * 1000);
      return moment(date, "YYYYMMDD").format("DD/MM/YYYY");
    },
    showProject(id){
      this.getProject(id)       
    },
    getProject(project_id){
      let user_id = this.userInfo.user.id
      let path = "/api/v1/projects/" + project_id + "?user_id="+user_id;
      this.requestGetWithoutContent(this.url()+path, {}).then(response => {
        if(response.data.success == true){
          this.projectShow = response.data.data
          this.changeCurrentProject(this.projectShow)
          this.popupShowActive = true;
          var projectComponent = Vue.extend(ProjectComponent)
          var instanceProjectComponent = new projectComponent({
            propsData: {project: this.projectShow, userInfo:this.userInfo , popupShowActive: this.popupShowActive}
          })
          instanceProjectComponent.$mount()
          var popup = document.getElementById('popupsProject')
          popup.appendChild(instanceProjectComponent.$el)          
        }else{
          //console.log("error -> " + JSON.stringify(response.data.message));          
        }
      }, (err) => {
        console.log('err users -> ' +  JSON.stringify(err))
      }) 
    },
    getProjectFormsMissing(project_id){
      //console.log("***********getProject(project_id)***********");
      var user_id = this.userInfo.user.id
      var path = "/api/v1/projects/" + project_id + "?user_id="+user_id;
      this.requestGetWithoutContent(this.url()+path, {}).then(response => {
        if(response.data.success == true){
          this.projectShow = response.data.data
          this.changeCurrentProject(this.projectShow)
          this.popupShowActive = true;
          var projectComponent = Vue.extend(ProjectFormsMissing)
          var instanceProjectComponent = new projectComponent({
            propsData: {project: this.projectShow, popupShowActive: this.popupShowActive}
          })
          instanceProjectComponent.$mount()
          var popup = document.getElementById('popupsProjectFormsMissing')
          popup.appendChild(instanceProjectComponent.$el)          
        }else{
          //console.log("error -> " + JSON.stringify(response.data.message));          
        }
      }, (err) => {
        console.log('err users -> ' +  JSON.stringify(err))
      }) 
    }   
  },
  created: function(){
    var current_user = this.userInfo.user.id
    var path = "/api/v1/reports/clients_trello_status?current_user="+current_user;
    this.requestGetWithoutContent(this.url()+path, {}).then(response => {
      if(response.data.success == true){
        this.projectsInProcess = response.data.projects_in_process
        this.projectsPreliminaries = response.data.projects_preliminaries
        this.projectsFinished = response.data.projects_finished
      }else{
        //console.log(response.data.message);          
      }
    }, (err) => {
      console.log('err -> ' +  err)
    })  
  },
  watch: {

  },  
  components: {
    StatisticsCardLine,
    ProjectComponent,
    ProjectFormsMissing
  }
}
</script>
<style>
  .ago{
    justify-content: left !important;    
  }
  .text-result{
    justify-content: left !important;
    /* width: 25% !important;     */
  }  
  .view-more{
    width: 50% !important;    
  }
  .view-more-in-process{
    width: 100% !important;    
  }  
  .button-view-more{
    color: rgb(115, 103, 240) !important
  }
  .vs-collapse-item--header{
    padding: 0rem !important;
  }
  .missing-proccess{
    font-size: 1rem !important
  }
  .ago-preliminary{
    font-size: 0.7rem !important; 
  }
  .button-notification{
    position: absolute;

  }
  .vx-card__title{
    padding-bottom: 1rem;
  }
</style>


