import { render, staticRenderFns } from "./ListTraceabilityCompleted.vue?vue&type=template&id=045247bb&"
import script from "./ListTraceabilityCompleted.vue?vue&type=script&lang=js&"
export * from "./ListTraceabilityCompleted.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('045247bb')) {
      api.createRecord('045247bb', component.options)
    } else {
      api.reload('045247bb', component.options)
    }
    module.hot.accept("./ListTraceabilityCompleted.vue?vue&type=template&id=045247bb&", function () {
      api.rerender('045247bb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components-globalwork/traceability/ListTraceabilityCompleted.vue"
export default component.exports