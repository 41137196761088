var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-col w-full lg:w-1/1 mb-base cont-traceability" },
    [
      _c("div", { staticClass: "container-traceability" }, [
        _c(
          "div",
          { staticClass: "vx-row" },
          [
            _c("Column", {
              attrs: {
                records: _vm.request_process,
                type: _vm.eventTypes.filter(function (eventType) {
                  return eventType.name == "request_process"
                })[0],
              },
            }),
            _c("Column", {
              attrs: {
                records: _vm.information_completed,
                type: _vm.eventTypes.filter(function (eventType) {
                  return eventType.name == "information_completed"
                })[0],
              },
            }),
            _c("Column", {
              attrs: {
                records: _vm.request_validations,
                type: _vm.eventTypes.filter(function (eventType) {
                  return eventType.name == "request_validations"
                })[0],
              },
            }),
            _c("Column", {
              attrs: {
                records: _vm.verifications_response,
                type: _vm.eventTypes.filter(function (eventType) {
                  return eventType.name == "verifications_response"
                })[0],
              },
            }),
            _c("Column", {
              attrs: {
                records: _vm.project_finished,
                type: _vm.eventTypes.filter(function (eventType) {
                  return eventType.name == "project_finished"
                })[0],
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }