var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.type != null
    ? _c(
        "div",
        {
          staticClass:
            "vx-col w-full lg:w-1/5 mb-base col-projects-traceability",
        },
        [
          _c("div", { staticClass: "vx-card card-traceability" }, [
            _c(
              "div",
              {
                class:
                  "vx-card__header title-column-card-header " +
                  _vm.type.name +
                  " tooltip",
              },
              [
                _c("span", { staticClass: "tooltiptext" }, [
                  _vm._v(_vm._s(_vm._f("textDescription")(_vm.type.name))),
                ]),
                _c(
                  "div",
                  { staticClass: "card__title title-header-traceability" },
                  [
                    _c("img", {
                      staticClass: "img-header-traceability",
                      attrs: {
                        src: _vm.images.find(function (image) {
                          return image[_vm.type.name]
                        })[_vm.type.name],
                        alt: "",
                      },
                    }),
                    _c("h5", { staticClass: "title-colum-traceability" }, [
                      _vm._v(_vm._s(_vm._f("textTitle")(_vm.type.name))),
                    ]),
                  ]
                ),
              ]
            ),
          ]),
          _vm.records != null
            ? [
                _c(
                  "div",
                  {
                    class:
                      "vx-col w-full lg:w-1/1 mb-base traceability-column-projects " +
                      (_vm.records.length > 2 ? "blur" : ""),
                  },
                  [
                    _vm._l(_vm.records, function (record) {
                      return _c("Card", {
                        key: record.project.id,
                        attrs: { record: record, type: _vm.type },
                      })
                    }),
                    _vm.records.length >= 3
                      ? [
                          _c(
                            "div",
                            { staticClass: "container-button-view-more" },
                            [
                              _vm.message != ""
                                ? _c("p", { staticClass: "button-view-more" }, [
                                    _vm._v(_vm._s(_vm.message)),
                                  ])
                                : _c(
                                    "p",
                                    {
                                      staticClass: "button-view-more",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.getProjectsByTypeEvent.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Ver más")]
                                  ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }