<template>
  <div>

    <vs-card class="card-details-academic" v-if="showDetailsAcademic">
      <div class="vx-row">        
        <div class="vx-col w-full lg:w-1/2 mb-base">          
          <vs-button class="move-index move-index-left" color="dark" type="flat" @click.prevent="index += -1" v-if="index > 0">Anterior</vs-button>              
        </div>
        <div class="vx-col w-full lg:w-1/2 mb-base">      
          <vs-button class="move-index move-index-right" color="dark" type="flat" @click.prevent="index += 1" v-if="this.project.academic_references.length - 1 > index">Siguiente</vs-button>    
        </div>
      </div>     
      
      <div class="vs-list">
        <div class="vs-list--header title-list vs-header-list-primary">
          <div class="list-titles">
            <div class="vs-list--title">{{academic_reference.name_institution}}</div>            
          </div>
        </div>      
        <template v-for="step in steps">
          <div class="vs-list--item item-list" :key="step.key" v-if="academic_reference.checklist[step.key].status > 0">
            <div class="vs-list--icon">
              <vs-icon icon="alarm" color="warning" v-if="academic_reference.checklist[step.key].status == 1"></vs-icon>
              <vs-icon icon="done" color="success" v-if="academic_reference.checklist[step.key].status == 2"></vs-icon>
              <vs-icon icon="clear" color="danger" v-if="academic_reference.checklist[step.key].status == 3"></vs-icon>
            </div>
            <div class="list-titles">
              <div class="vs-list--title">{{step.text}}</div>
              <div class="vs-list--subtitle">{{step.textInformation[academic_reference.checklist[step.key].status]}}</div>
            </div>
          </div>          
        </template>
      </div>

    </vs-card>  

    <vs-button class="academic-details" color="dark" type="flat" @click.prevent="showAcademicCheckList" v-if="this.project.academic_references.length > 0">
      <template v-if="showDetailsAcademic">Ocultar detalles</template>
      <template v-else>Ver detalles</template>      
    </vs-button>

  </div>

</template>

<script>
import steps from '../../mixins/steps'
export default {
  mixins:[steps],
  props: {
    project: Object,
  },
  data (){
    return{
      index: null,
      academic_reference: null,
      showDetailsAcademic: false,
    }
  },
  methods: {
    showAcademicCheckList(){
      this.showDetailsAcademic = !this.showDetailsAcademic
      if(this.showDetailsAcademic) {
        this.index = 0
      } else {
        this.index = null
      }
    },
    setAcademicReference (){
      if (this.index != null){
        this.academic_reference = this.project.academic_references[this.index]
      }else{
        this.academic_reference = null
      }
      
    }
  },
  watch: {
    index() {
      this.setAcademicReference()
    }
  },
}
</script>

<style>
  .card-details-academic {
    position: absolute;
    margin-left: 12rem;
    z-index: 2;
  }
  .academic-details {
    font-weight: 700;
    font-size: 0.7rem;
  }

  .move-index {
    font-weight: 700;
    font-size: 0.7rem;    
    position: absolute;
    color: #626262 !important;
  }

  .move-index-left {
    top: 0;
    left: 0;
  }    

  .move-index-right {
    top: 0;
    right: 0;    
  }  
</style>