var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-traceability" },
    _vm._l(_vm.events, function (event, index) {
      return _c(
        "div",
        { key: event.event_type, staticClass: "events-traceability" },
        [
          index == 0
            ? [
                event.childrens.documents.length > 0 ||
                event.childrens.references.length > 0
                  ? _c("h4", { staticClass: "title-colum-event" }, [
                      _vm._v(_vm._s(event.event_type)),
                    ])
                  : _vm._e(),
                _c("RenderChildrensDocuments", {
                  attrs: { childrens: event.childrens.documents, img: _vm.img },
                }),
                _c("RenderChildrensReferences", {
                  attrs: {
                    childrens: event.childrens.references,
                    img: _vm.img,
                  },
                }),
              ]
            : [
                event.childrens.length > 0
                  ? _c("h4", { staticClass: "title-colum-event" }, [
                      _vm._v(_vm._s(event.event_type)),
                    ])
                  : _vm._e(),
                _c("RenderChildrensRequestResponse", {
                  attrs: { childrens: event.childrens, img: _vm.img },
                }),
              ],
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }