var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notifications" },
    [
      _vm.popupShowActive
        ? _c("ProjectComponent", {
            attrs: {
              popupShowActive: _vm.popupShowActive,
              project: _vm.projectShow,
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full lg:w-1/3 mb-base" },
          [
            _c(
              "vx-card",
              { attrs: { title: "Confiable" } },
              _vm._l(
                _vm.projectsTrust,
                function (projectTrust, index_inProcess) {
                  return _c(
                    "ul",
                    { key: index_inProcess, staticClass: "activity-timeline" },
                    [
                      _c(
                        "li",
                        [
                          _c("div", { staticClass: "timeline-info" }, [
                            _c("p", { staticClass: "font-semibold" }, [
                              _vm._v(_vm._s(projectTrust.full_name)),
                            ]),
                            _c("span", { staticClass: "activity-desc" }, [
                              _vm._v(
                                "Proceso: " + _vm._s(projectTrust.project_type)
                              ),
                            ]),
                          ]),
                          _c(
                            "vs-row",
                            {
                              attrs: {
                                "vs-type": "flex",
                                "vs-justify": "space-between",
                              },
                            },
                            [
                              _c(
                                "vs-col",
                                {
                                  staticClass: "ago",
                                  attrs: {
                                    "vs-type": "flex",
                                    "vs-justify": "center",
                                    "vs-align": "center",
                                    "vs-w": "6",
                                  },
                                },
                                [
                                  _c(
                                    "small",
                                    {
                                      staticClass: "text-grey activity-e-time",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.convertTimeStamp(
                                            projectTrust.created_at
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "vs-col",
                                {
                                  staticClass: "view-more",
                                  attrs: {
                                    "vs-type": "flex",
                                    "vs-justify": "center",
                                    "vs-align": "center",
                                    "vs-w": "6",
                                  },
                                },
                                [
                                  _c(
                                    "vs-button",
                                    {
                                      attrs: { color: "primary", type: "flat" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showProject(
                                            projectTrust.id
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Ver más")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "vx-col w-1/1 mb-3" },
                            [
                              _c("vs-progress", {
                                staticClass: "block mt-1",
                                attrs: { percent: 10, color: "primary" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              0
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-full lg:w-1/3 mb-base" },
          [
            _c(
              "vx-card",
              { attrs: { title: "No confiable" } },
              _vm._l(
                _vm.projectsUnTrust,
                function (projectUnTrust, index_Preliminaries) {
                  return _c(
                    "ul",
                    {
                      key: index_Preliminaries,
                      staticClass: "activity-timeline",
                    },
                    [
                      _c(
                        "li",
                        [
                          _c("div", { staticClass: "timeline-info" }, [
                            _c("p", { staticClass: "font-semibold" }, [
                              _vm._v(_vm._s(projectUnTrust.full_name)),
                            ]),
                            _c("span", { staticClass: "activity-desc" }, [
                              _vm._v(
                                "Proceso: " +
                                  _vm._s(projectUnTrust.project_type)
                              ),
                            ]),
                          ]),
                          _c(
                            "vs-row",
                            {
                              attrs: {
                                "vs-type": "flex",
                                "vs-justify": "space-between",
                              },
                            },
                            [
                              _c(
                                "vs-col",
                                {
                                  staticClass: "ago",
                                  attrs: {
                                    "vs-type": "flex",
                                    "vs-justify": "center",
                                    "vs-align": "center",
                                    "vs-w": "6",
                                  },
                                },
                                [
                                  _c(
                                    "small",
                                    {
                                      staticClass: "text-grey activity-e-time",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.convertTimeStamp(
                                            projectUnTrust.created_at
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "vs-col",
                                {
                                  staticClass: "view-more",
                                  attrs: {
                                    "vs-type": "flex",
                                    "vs-justify": "center",
                                    "vs-align": "center",
                                    "vs-w": "6",
                                  },
                                },
                                [
                                  _c(
                                    "vs-button",
                                    {
                                      attrs: { color: "primary", type: "flat" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showProject(
                                            projectUnTrust.id
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Ver más")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "vx-col w-1/1 mb-3" },
                            [
                              _c("vs-progress", {
                                staticClass: "block mt-1",
                                attrs: { percent: 60, color: "primary" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              0
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-full lg:w-1/3 mb-base" },
          [
            _c(
              "vx-card",
              { attrs: { title: "Confiable con observaciones" } },
              _vm._l(
                _vm.projectsUnReliable,
                function (projectUnReliable, index_projectsUnReliable) {
                  return _c(
                    "ul",
                    {
                      key: index_projectsUnReliable,
                      staticClass: "activity-timeline",
                    },
                    [
                      _c(
                        "li",
                        [
                          _c("div", { staticClass: "timeline-info" }, [
                            _c("p", { staticClass: "font-semibold" }, [
                              _vm._v(_vm._s(projectUnReliable.full_name)),
                            ]),
                            _c("span", { staticClass: "activity-desc" }, [
                              _vm._v(
                                "Proceso: " +
                                  _vm._s(projectUnReliable.project_type)
                              ),
                            ]),
                          ]),
                          _c(
                            "vs-row",
                            {
                              attrs: {
                                "vs-type": "flex",
                                "vs-justify": "space-between",
                              },
                            },
                            [
                              _c(
                                "vs-col",
                                {
                                  staticClass: "ago",
                                  attrs: {
                                    "vs-type": "flex",
                                    "vs-justify": "center",
                                    "vs-align": "center",
                                    "vs-w": "6",
                                  },
                                },
                                [
                                  _c(
                                    "small",
                                    {
                                      staticClass: "text-grey activity-e-time",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.convertTimeStamp(
                                            projectUnReliable.created_at
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "vs-col",
                                {
                                  staticClass: "view-more",
                                  attrs: {
                                    "vs-type": "flex",
                                    "vs-justify": "center",
                                    "vs-align": "center",
                                    "vs-w": "6",
                                  },
                                },
                                [
                                  _c(
                                    "vs-button",
                                    {
                                      attrs: { color: "primary", type: "flat" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showProject(
                                            projectUnReliable.id
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Ver más")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "vx-col w-1/1 mb-3" },
                            [
                              _c("vs-progress", {
                                staticClass: "block mt-1",
                                attrs: { percent: 100, color: "primary" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              0
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }