<template>
  <div class="render-childrens-documents" v-if="childrens != null">
    <template v-if="childrens.length > 0">
      <h4 class="subtitle-colum-event">Referencias</h4>
      <div class="event-children event-children-information-completed" v-for="children in childrens" :key="children">
        <div class="event-children-icon">
          <img :src="img" alt="En espera" />
        </div>
        <div class="event-children-content">
          <div class="event-checklist-item">{{ children.split(' ')[0] | textRule }}</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  export default {
    props: {
      childrens: null,
      img: null
    },
    filters: {
      textRule: function(value) {
        const arrayText = value.split(' ')
        const description = arrayText[0]
        switch (description) {
          case 'cv':
            return 'Hoja de vida'
          case 'habeas_data':
            return 'Habeas data'
          case 'academic_references_pending_for_create':
            return 'En espera de creación de referencias académicas por parte del candidato'
          case 'laboral_references_pending_for_create':
            return 'Esperando creación de referencias laborales'
          case 'family_references_pending_for_create':
            return 'En espera de creación de referencias académicas por parte del candidato'
          case 'personal_references_pending_for_create':
            return 'En espera de creación de referencias personales'
          case 'academic_references_pending_for_request_validations':
            return 'Solicitud de verificación de referencia académica pendiente'
          case 'laboral_references_pending_for_request_validations':
            return 'Solicitud de verificación de referencia laboral pendiente'
          case 'family_references_pending_for_request_validations':
            return 'Solicitud de verificación de referencia familiar pendiente'
          case 'personal_references_pending_for_request_validations':
            return 'Solicitud de verificación de referencia personal pendiente'
          case 'academic_references_pending_for_verifications_response':
            return 'En espera de respuesta de verificaciones académicas'
          case 'laboral_references_pending_for_verifications_response':
            return 'Esperando verificaciones laborales'
          case 'family_references_pending_for_verifications_response':
            return 'En espera de respuesta de verificaciones familiares'
          case 'personal_references_pending_for_verifications_response':
            return 'Esperando verificaciones personales'            
          case 'academic_reference_verified':
            return 'Referencia académica verificada'                                                
          case 'laboral_reference_verified':
            return 'Referencia laboral verificada'                                                
          case 'family_reference_verified':
            return 'Referencia familiar verificada'                                                
          case 'personal_reference_verified':
            return 'Referencia personal verificada'
          case 'request_data_candidate_finished':
            return 'El candidato respondió la solicitud de información'
          case 'assignment_academic_finished':
            return 'Asignación académica'
          case 'assignment_personal_finished':
            return 'Asignación personal'
          case 'assignment_laboral_finished':
            return 'Asignación laboral'
          case 'assignment_antecedents_finished':
            return 'Asignación de antecedentes'                        
          default:
            return description
        }      
      }
    }        
  }
</script>
