var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-row" },
    [
      _vm._l(Object.keys(_vm.classification), function (status) {
        return _c(
          "div",
          {
            key: status,
            staticClass:
              "vx-col w-full lg:w-1/5 mb-base col-projects-traceability",
          },
          [
            _c("div", { staticClass: "vx-card card-traceability" }, [
              _c(
                "div",
                {
                  class:
                    "vx-card__header title-column-card-header " +
                    status +
                    " tooltip",
                },
                [
                  _c("span", { staticClass: "tooltiptext" }, [
                    _vm._v(_vm._s(_vm._f("textDescription")(status))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "card__title title-header-traceability" },
                    [
                      _c("img", {
                        staticClass: "img-header-traceability",
                        attrs: {
                          src: _vm.images.find(function (image) {
                            return image[status]
                          })[status],
                          alt: "",
                        },
                      }),
                      _c("h5", { staticClass: "title-colum-traceability" }, [
                        _vm._v(_vm._s(_vm._f("textTitle")(status))),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
            _c(
              "div",
              {
                class:
                  "vx-col w-full lg:w-1/1 mb-base traceability-column-projects " +
                  (_vm.classification[status].length > 2 ? "blur" : ""),
              },
              _vm._l(_vm.classification[status], function (data) {
                return _c(
                  "div",
                  {
                    key: data.project.id,
                    staticClass:
                      "vx-card card-traceability card-traceability-project",
                  },
                  [
                    _c("div", { staticClass: "vx-card__header" }, [
                      _c("div", { staticClass: "card__title" }, [
                        _c("p", [_vm._v(_vm._s(data.project.project_type))]),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vx-card__collapsible-content vs-con-loading__container",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "vx-card__body card-body-traceability",
                          },
                          [
                            _c(
                              "h6",
                              {
                                staticClass: "full-name-traceability",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.goToDetails(data.project.id)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(data.project.full_name))]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "labels" }, [
                      data.project.status_name != null
                        ? _c(
                            "span",
                            {
                              class: "label " + data.project.status_name_class,
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("capitalize")(data.project.status_name)
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                      data.project.overall_final_name != null
                        ? _c(
                            "span",
                            {
                              class:
                                "label " +
                                data.project.overall_final_name_class,
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("capitalize")(
                                    data.project.overall_final_name
                                  )
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "event-checklist-progress",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.actionShowModal(data.checklist)
                          },
                        },
                      },
                      [
                        _c(
                          "p",
                          { staticClass: "event-checklist-progress-text" },
                          [_vm._v(_vm._s(data.checklist.progress))]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "event-date event-date-traceability" },
                      [_c("EventDate", { attrs: { event: data.event } })],
                      1
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        )
      }),
      _vm.checklist != null && _vm.showModal
        ? _c("TraceabilityModal", { attrs: { checklist: _vm.checklist } })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }