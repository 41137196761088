<template>
  <div class="timer">
    <div class="countdown" loaded>
      <div class="container-time">
        <p class="font-time">{{displayDays}}</p>
      </div>
      <div class="container-time">
        <p class="font-time">{{displayHours}}</p>
      </div>
      <div class="container-time">
        <p class="font-time">{{displayMinutes}}</p>
      </div>
      <div class="container-time">
        <p class="font-time">{{displaySeconds}}</p>
      </div>
    </div>
    <div class="description-time">
      <div class="container-time-text">
        <p class="description-time-item">Días</p>
      </div>
      <div class="container-time-text">
        <p class="description-time-item">Horas</p>
      </div>
      <div class="container-time-text">
        <p class="description-time-item">Minutos</p>
      </div>
      <div class="container-time-text">
        <p class="description-time-item">Segundos</p>
      </div>
    </div>    
  </div>
</template>
<script>
export default {
  props: {
    year: { type: Number, required: true, default: 2020 },
    month: { type: Number, required: true, default: 0 },
    day: { type: Number, required: true, default: 0 },
    hour: { type: Number, required: false, default: 0 },
    minute: { type: Number, required: false, default: 0 },
    second: { type: Number, required: false, default: 0 },
    milliseconds: { type: Number, required: false, default: 0 }
  },
  data() {
    return {
      displayDays: 0,
      displayHours: 0,
      displayMinutes: 0,
      displaySeconds: 0,
      loaded: false,
      expired: false
    }
  },
  computed: {
    _seconds: () => 1000,
    _minutes() {
      return this._seconds * 60
    },
    _hours() {
      return this._minutes * 60
    },
    _days() {
      return this._hours * 24
    },
    END() {
      return new Date(
        this.year,
        this.month - 1,
        this.day,
        this.hour,
        this.second,
        this.minute,
        this.milliseconds
      )
    }
  },
  mounted() {
    this.showRemaining()
  },
  methods: {
    formatNum: num => (num < 10 ? '0' + num : num),
    showRemaining() {
      const TIMER = setInterval(() => {
        const NOW = new Date()
        const DISTANCE = this.END.getTime() - NOW.getTime()
        if (DISTANCE < 0) {
          clearInterval(TIMER)
          this.expired = true
          this.loaded = true
          return
        }
        const DAYS = Math.floor(DISTANCE / this._days)
        const HOURS = Math.floor((DISTANCE % this._days) / this._hours)
        const MINUTES = Math.floor((DISTANCE % this._hours) / this._minutes)
        const SECONDS = Math.floor((DISTANCE % this._minutes) / this._seconds)
        this.displayMinutes = this.formatNum(MINUTES)
        this.displaySeconds = this.formatNum(SECONDS)
        this.displayHours = this.formatNum(HOURS)
        this.displayDays = this.formatNum(DAYS)
        this.loaded = true
      }, 1000)
    }
  }
}
</script>

<style scoped>

  .countdown {
    display: flex;
    flex-direction: row;
    justify-content: center;        
  }

  .timer {
    display: flex;
    flex-direction: column;
  }

  .container-time {
    width: 56px;
    height: 56px;
    background: #F2EDFC;
    border-radius: 5px;
    margin-top: 16px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container-time-text  {
    width: 56px;
    display: flex;
    align-items: center;
    justify-content: center;    
    margin-top: 8px;
    margin-right: 16px;
  }

  .font-time {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #626262;
  }

  .font-time-description {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    color: #626262;    
  }

  .container-data {
  }

  .font-time-description {
    margin-top: 10px;
  }
  
  .description-time {
    display: flex;
    flex-direction: row;
    justify-content: center;            
  }

  .description-time-item {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    color: #626262;
  }
</style>