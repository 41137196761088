<template>
  <div class="event-modal-traceability" v-if="checklist != null && missing != null">
    <div :class="`modal-childrens-completed-pending ${onlyColumn()}` ">
      <span class="close-modal-event" @click.prevent="actionHideModal"><vs-icon icon="close"></vs-icon></span>
      <div class="container-completed-pending">
        <div class="column-container-completed-pending">
          <div class="list-events-childrens">
            <h4 class="title-colum">Procesos realizados</h4>
            <ListTraceabilityCompleted :events="checklist.completed_events" :img="imgCheck" />
          </div>
        </div>
        <div class="column-container-completed-pending" v-if="missing">
          <div class="list-events-childrens">
            <h4 class="title-colum">Procesos pendientes</h4>
            <ListTraceabilityPending :events="checklist.pending_events" :img="imgTimer" />
          </div>          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { eventBus } from './../../main'
  import ListTraceabilityCompleted from './ListTraceabilityCompleted.vue'
  import ListTraceabilityPending from './ListTraceabilityPending.vue'
  export default {
    props: {
      checklist: null,
      missing: null
    },
    data() {
      return {
        imgCheck: 'https://res.cloudinary.com/dhcti0lrk/image/upload/v1613741109/globalwork/steps/check_dmbbtl.png',
        imgTimer: 'https://res.cloudinary.com/dhcti0lrk/image/upload/v1613741142/globalwork/steps/clock_pvsfvg.png'
      }
    },
    methods: {
      actionHideModal() {
        document.querySelector(".vx-navbar-wrapper").style['z-index'] = 41000;
        eventBus.$emit('hideTraceabilityModal')
      },
      onlyColumn() {
        if (this.missing) {
          return 'one-column'
        } else {
          return 'two-column'
        }
      }
    },
    components: {
      ListTraceabilityCompleted,
      ListTraceabilityPending
    },
  }
</script>
