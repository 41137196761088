var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.project != {}
        ? _c(
            "vx-card",
            [
              _c(
                "vs-row",
                { attrs: { "vs-w": "12" } },
                [
                  _c("vs-col", {
                    attrs: {
                      "vs-type": "flex",
                      "vs-justify": "center",
                      "vs-align": "center",
                      "vs-lg": "2",
                      "vs-sm": "4",
                      "vs-xs": "12",
                    },
                  }),
                  _c(
                    "vs-col",
                    {
                      attrs: {
                        "vs-type": "flex",
                        "vs-justify": "center",
                        "vs-align": "center",
                        "vs-lg": "8",
                        "vs-sm": "4",
                        "vs-xs": "12",
                      },
                    },
                    [
                      _c("div", { staticClass: "text-center" }, [
                        _c("h4", { staticStyle: { color: "#5F0090" } }, [
                          _vm._v(
                            _vm._s(this.project.data_basic.person_full_name)
                          ),
                        ]),
                        _c("p", { staticClass: "text-grey" }, [
                          _vm._v(
                            "Cédula: " +
                              _vm._s(this.project.data_basic.person_id)
                          ),
                        ]),
                        _c("p", { staticClass: "text-grey" }, [
                          _vm._v(
                            _vm._s(this.project.data_basic.current_activity)
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "vs-col",
                    {
                      attrs: {
                        "vs-type": "flex",
                        "vs-justify": "center",
                        "vs-align": "center",
                        "vs-lg": "2",
                        "vs-sm": "4",
                        "vs-xs": "12",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "con-vs-avatar mx-auto my-6 block 80px",
                          staticStyle: {
                            width: "80px",
                            height: "80px",
                            background: "rgb(195, 195, 195)",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "con-img vs-avatar--con-img" },
                            [
                              _c("img", {
                                attrs: { src: _vm.clientLogo, alt: "" },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "vs-row",
                { attrs: { "vs-w": "12" } },
                [
                  _c("vs-col", {
                    attrs: {
                      "vs-type": "flex",
                      "vs-justify": "center",
                      "vs-align": "center",
                      "vs-lg": "2",
                      "vs-sm": "4",
                      "vs-xs": "12",
                    },
                  }),
                  _c(
                    "vs-col",
                    {
                      attrs: {
                        "vs-type": "flex",
                        "vs-justify": "center",
                        "vs-align": "center",
                        "vs-lg": "8",
                        "vs-sm": "4",
                        "vs-xs": "12",
                      },
                    },
                    [
                      _c("vx-card", [
                        _c("div", { staticClass: "vx-row" }, [
                          _vm.project.percentage.make_processes.length > 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "vx-col w-full lg:w-1/2 mb-base",
                                },
                                [
                                  _c(
                                    "vs-list",
                                    [
                                      _c("vs-list-header", {
                                        staticClass: "title-list",
                                        attrs: { title: "Procesos Realizados" },
                                      }),
                                      _vm._l(
                                        _vm.project.percentage.make_processes,
                                        function (proccess, index_proccess) {
                                          return _c("vs-list-item", {
                                            key: index_proccess,
                                            staticClass: "item-list",
                                            attrs: {
                                              "icon-pack": "feather",
                                              icon: "icon-check",
                                              title: proccess,
                                              subtitle: "",
                                            },
                                          })
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.project.percentage.missing_processes.length > 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "vx-col w-full lg:w-1/2 mb-base",
                                },
                                [
                                  _c(
                                    "vs-list",
                                    [
                                      _c("vs-list-header", {
                                        staticClass: "title-list",
                                        attrs: { title: "Procesos faltantes" },
                                      }),
                                      _vm._l(
                                        _vm.project.percentage
                                          .missing_processes,
                                        function (proccess, index_proccess) {
                                          return _c(
                                            "vs-list-item",
                                            {
                                              key: index_proccess,
                                              staticClass: "item-list",
                                              attrs: {
                                                "icon-pack": "feather",
                                                icon: "icon-clock",
                                                title: proccess,
                                                subtitle: "",
                                              },
                                            },
                                            [
                                              proccess == "Académico." ||
                                              proccess == "Académico"
                                                ? _c("academic-details", {
                                                    attrs: {
                                                      project: _vm.project,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("vs-col", {
                    attrs: {
                      "vs-type": "flex",
                      "vs-justify": "center",
                      "vs-align": "center",
                      "vs-lg": "2",
                      "vs-sm": "4",
                      "vs-xs": "12",
                    },
                  }),
                ],
                1
              ),
              this.project.overall_final_name != ""
                ? _c("div", { staticClass: "text-center" }, [
                    _c(
                      "h1",
                      {
                        style:
                          "color: " +
                          _vm.colorConceptGeneralText(
                            this.project.overall_final
                          ),
                      },
                      [_vm._v(_vm._s(this.project.overall_final_name))]
                    ),
                  ])
                : _vm._e(),
              _c("br"),
              this.project.overall_final_name != ""
                ? _c("div", { staticClass: "vx-row" }, [
                    _c("div", { staticClass: "vx-col md:w-1/6 w-full " }, [
                      _c("h6", { staticClass: "table-title" }, [
                        _vm._v("Resultado:"),
                      ]),
                    ]),
                    _c("div", { staticClass: "vx-col md:w-1/6 w-full " }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(this.project.data_basic.final_result_title) +
                          "\n      "
                      ),
                    ]),
                    _c("div", { staticClass: "vx-col md:w-1/6 w-full" }, [
                      _c("h6", { staticClass: "table-title" }, [
                        _vm._v("Fecha de creación:"),
                      ]),
                    ]),
                    _c("div", { staticClass: "vx-col md:w-1/6 w-full" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(this.project.data_basic.created_at) +
                          "\n      "
                      ),
                    ]),
                    _c("div", { staticClass: "vx-col md:w-1/6 w-full" }, [
                      _c("h6", { staticClass: "table-title" }, [
                        _vm._v("Creado por:"),
                      ]),
                    ]),
                    _c("div", { staticClass: "vx-col md:w-1/6 w-full" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(this.project.data_basic.client_user_name) +
                          "\n      "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c("br"),
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col md:w-1/6 w-full " }, [
                  _c("h6", { staticClass: "table-title" }, [_vm._v("Ciudad:")]),
                ]),
                _c("div", { staticClass: "vx-col md:w-1/6 w-full" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(this.project.data_basic.city_name) +
                      "\n      "
                  ),
                ]),
                _c("div", { staticClass: "vx-col md:w-1/6 w-full" }, [
                  _c("h6", { staticClass: "table-title" }, [
                    _vm._v("Tipo de proceso:"),
                  ]),
                ]),
                _c("div", { staticClass: "vx-col md:w-1/6 w-full" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(this.project.project_type_name) +
                      "\n      "
                  ),
                ]),
              ]),
              _c("br"),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }