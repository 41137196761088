<template>
  <div class="vx-row">
    <div class="vx-col w-full lg:w-1/5 mb-base col-projects-traceability" v-for="status in Object.keys(classification)" :key="status">      
      <div class="vx-card card-traceability">        
        <div :class="`vx-card__header title-column-card-header ${status} tooltip`">
          <span class="tooltiptext">{{ status | textDescription }}</span>
          <div class="card__title title-header-traceability">
            <img class="img-header-traceability" :src="images.find(image => image[status])[status]" alt="">
            <h5 class="title-colum-traceability">{{ status | textTitle }}</h5>
          </div>          
        </div>        
      </div>
      <div :class="`vx-col w-full lg:w-1/1 mb-base traceability-column-projects ${classification[status].length > 2 ? 'blur' : ''}`">
        <div class="vx-card card-traceability card-traceability-project" v-for="data in classification[status]" :key="data.project.id">
          <div class="vx-card__header">
            <div class="card__title">
              <p>{{data.project.project_type}}</p>                
            </div>          
          </div>
          <div class="vx-card__collapsible-content vs-con-loading__container">
            <div class="vx-card__body card-body-traceability">
              <h6 class="full-name-traceability" @click.prevent="goToDetails(data.project.id)">{{data.project.full_name}}</h6>
            </div>
          </div>
          <div class="labels">
            <!-- status -->
            <span :class="`label ${data.project.status_name_class}`" v-if="data.project.status_name != null">{{data.project.status_name | capitalize }}</span> 
            <!-- overall_final -->
            <span :class="`label ${data.project.overall_final_name_class}`" v-if="data.project.overall_final_name != null">{{data.project.overall_final_name | capitalize }}</span>
          </div>
          <div class="event-checklist-progress" @click.prevent="actionShowModal(data.checklist)">
            <p class="event-checklist-progress-text">{{ data.checklist.progress }}</p>            
          </div>                    
          <div class="event-date event-date-traceability">
            <EventDate :event="data.event" />
          </div>          
        </div>
      </div>        
    </div>    
    <TraceabilityModal :checklist="checklist" v-if="checklist != null && showModal"/>
    
  </div>
</template>

<script>
  import EventDate from './../projects/events/EventDate.vue'  
  import TraceabilityModal from './TraceabilityModal.vue'
  import { eventBus } from './../../main'
  export default {
    data() {
      return {
        showModal: false,
        checklist: null,
        images: [
          {
            'request_process': 'https://res.cloudinary.com/dhcti0lrk/image/upload/v1615930877/globalwork/traceability/Solicitud_del_proceso_fnvphg.png'
          },
          {
            'information_completed': 'https://res.cloudinary.com/dhcti0lrk/image/upload/v1615930877/globalwork/traceability/Informacio%CC%81n_completa_del_candidato_glv2z7.png'
          },
          {
            'request_validations': 'https://res.cloudinary.com/dhcti0lrk/image/upload/v1615930877/globalwork/traceability/Solicitud_de_verificaciones_1_wrl9bl.png'
          },
          {
            'verifications_response': 'https://res.cloudinary.com/dhcti0lrk/image/upload/v1615930877/globalwork/traceability/Respuestas_de_verificaciones_1_jndlew.png'
          },
          {
            'project_finished': 'https://res.cloudinary.com/dhcti0lrk/image/upload/v1615930877/globalwork/traceability/Cierre_y_resultados_del_proceso_1_ifcmsy.png'
          }          
        ],
      }
    },
    props: {
      classification: null
    },
    methods: {
      goToDetails(project_id) {
        window.open(`/project/${project_id}`, '_blank')
      },
      actionShowModal(checklist) {
        document.querySelector(".vx-navbar-wrapper").style['z-index'] = 1;
        document.getElementById('navBar').style = 'z-index:1;'
        this.checklist = checklist 
        this.showModal = true
      },                       
    },
    filters: {
      textTitle: function(value) {
        let result = ''
        switch (value) {
          case 'request_process':
            result = 'Solicitudes creadas'
            break;
          case 'information_completed':
            result = 'Solicitud de documentación para inicio de verificaciones'
            break;
          case 'request_validations':
            result = 'En proceso de verificaciones'
            break;
          case 'verifications_response':
            result = 'Respuesta de verificaciones'
            break;                                            
          case 'project_finished':
            result = 'Resultado final de los procesos'
            break;            
          default:
            result = value
            break;
        }
        return result
      },
      textDescription: function(value) {
        let result = ''
        switch (value) {
          case 'request_process':
            result = 'En esta etapa se valida que el proceso fue creado de forma exitosa por parte del cliente y solicitaremos la documentación para iniciar con las verificaciones'
            break;
          case 'information_completed':
            result = 'En esta etapa estamos solicitando la documentación a  la empresa y al candidato para iniciar el proceso de verificaciones'
            break;
          case 'request_validations':
            result = 'En esta etapa, estamos verificando la información del candidato de manera inmediata'
            break;
          case 'verifications_response':
            result = 'En esta etapa, podrás revisar resultados de las verificaciones que ya están finalizadas y encontrarás detalles de las verificaciones  que no han podido finalizarse'
            break;                                            
          case 'project_finished':
            result = 'En esta etapa, encontrarás los resultados finales de los procesos solicitados. Podrás revisar los detalles y acceder al informe completo'
            break;            
          default:
            result = value
            break;
        }
        return result
      },      
      capitalize: function(value) {
        if (typeof value !== 'string') return ''
        value = value.toLowerCase()
        return value.charAt(0).toUpperCase() + value.slice(1)
      }      
    },
    components: {
      EventDate,
      TraceabilityModal
    },
    mounted () {
      eventBus.$on('hideTraceabilityModal', () => {
        document.getElementById('navBar').style = 'z-index:41000;'
        this.checklist = null 
        this.showModal = false
      })      
    }
  }
</script>
