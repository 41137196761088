<template>
  <div class="render-children" v-if="childrens != null">
    <div class="event-children event-children--information-completed" v-for="children in childrens" :key="children[0]">
      <template v-if="children[2]">
        <div class="event-children-icon">
          <img :src="img" alt="En espera" />
        </div>
      </template>
      <div class="event-children-icon" v-else>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#408530" class="bi bi-chat-left-dots" viewBox="0 0 16 16">
          <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
          <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
        </svg>
      </div>
      <div class="event-children-content">
        <div class="event-checklist-item">{{ children[0] }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      childrens: null,
      img: null
    }
  }
</script>
