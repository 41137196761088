var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        [
          _c("div", { staticClass: "text-center" }, [
            _c("h4", [_vm._v("Pendiente por")]),
          ]),
          Object.keys(_vm.project.observations).length > 0
            ? _c(
                "vs-tabs",
                _vm._l(
                  _vm.formsMissing(),
                  function (observations, index_observation) {
                    return _c(
                      "vs-tab",
                      {
                        key: index_observation,
                        attrs: { label: "Equipo " + observations[0].type },
                      },
                      [
                        _c(
                          "vs-table",
                          { attrs: { noDataText: "" } },
                          [
                            _c("template", { slot: "header" }),
                            _c(
                              "template",
                              { slot: "thead" },
                              [
                                _c("vs-th", [
                                  _vm._v("\n              Fecha\n            "),
                                ]),
                                _c("vs-th", [
                                  _vm._v(
                                    "\n              Observación\n            "
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._l(
                              observations,
                              function (observation, index_observation) {
                                return _c(
                                  "vs-tr",
                                  { key: index_observation },
                                  [
                                    _c(
                                      "vs-td",
                                      {
                                        attrs: { data: observation.created_at },
                                      },
                                      [
                                        _vm._v(
                                          " \n                " +
                                            _vm._s(
                                              _vm.humanizeDate(
                                                observation.created_at
                                              )
                                            ) +
                                            "                 \n              "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "vs-td",
                                      {
                                        attrs: { data: observation.created_at },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(observation.observation) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  }
                ),
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }