<template>
  <div class="vx-row go-to-poll">
    <div class="vx-col w-full lg:w-1/1 mb-base col-link">      
      <a class="link-go-to-poll" :href="link" v-if="link != null" target="_blank">{{ description }}</a>
      <p v-else>Link no suministrado</p>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['description', 'link'] 
  }
</script>
<style scoped>
  .go-to-poll {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: flex-end !important;
    margin-left: 0.3rem;
  }
  .col-link {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .link-go-to-poll {
    color: #5F0091;
    text-decoration: none;
  }
</style>
