<template>
  <div class="vx-col w-full lg:w-1/5 mb-base col-projects-traceability" v-if="type != null">
    <div class="vx-card card-traceability">
      <div :class="`vx-card__header title-column-card-header ${type.name} tooltip`">
        <span class="tooltiptext">{{ type.name | textDescription }}</span>
        <div class="card__title title-header-traceability">
          <img class="img-header-traceability" :src="images.find(image => image[type.name])[type.name]" alt=""/>
          <h5 class="title-colum-traceability">{{ type.name | textTitle }}</h5>
        </div>
      </div>
    </div>
    <template v-if="records != null">
      <div :class="`vx-col w-full lg:w-1/1 mb-base traceability-column-projects ${records.length > 2 ? 'blur' : ''}`">      
        <Card :record="record" :type="type" v-for="record in records" :key="record.project.id"/>
        <template v-if="records.length >= 3">
          <div class="container-button-view-more">
            <p class="button-view-more" v-if="message != ''">{{message}}</p>
            <p class="button-view-more" @click.prevent="getProjectsByTypeEvent" v-else>Ver más</p>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
  import Card from './Card.vue'
  import api from './../../mixins/api'
  import { mapGetters, mapMutations } from 'vuex'
  export default {
    mixins: [api],
    props: {
      type: null,
      records: null
    },
    computed: {
      ...mapGetters(['userInfo'])
    },    
    data() {
      return {
        showModal: false,
        checklist: null,
        images: [
          {
            'request_process': 'https://res.cloudinary.com/dhcti0lrk/image/upload/v1615930877/globalwork/traceability/Solicitud_del_proceso_fnvphg.png'
          },
          {
            'information_completed': 'https://res.cloudinary.com/dhcti0lrk/image/upload/v1615930877/globalwork/traceability/Informacio%CC%81n_completa_del_candidato_glv2z7.png'
          },
          {
            'request_validations': 'https://res.cloudinary.com/dhcti0lrk/image/upload/v1615930877/globalwork/traceability/Solicitud_de_verificaciones_1_wrl9bl.png'
          },
          {
            'verifications_response': 'https://res.cloudinary.com/dhcti0lrk/image/upload/v1615930877/globalwork/traceability/Respuestas_de_verificaciones_1_jndlew.png'
          },
          {
            'project_finished': 'https://res.cloudinary.com/dhcti0lrk/image/upload/v1615930877/globalwork/traceability/Cierre_y_resultados_del_proceso_1_ifcmsy.png'
          }          
        ],
        per_page: 10,
        page: 2,
        message: ''
      }
    },
    methods: {
      ...mapMutations(['addElementsToColumnsEventType']),
      getProjectsByTypeEvent() {
        this.requestGetWithoutContent(`${this.url()}api/v1/projects/projects_by_event_type?user_id=${this.userInfo.user.id}&event_type=${this.type.id}&per_page=${this.per_page}&page=${this.page}`, {}).then((response) => {
          const elements = response.data
          if (elements.length == 0) {
            this.message = 'No se encontraron más registros'
          } else {
            elements.forEach(data => {
              this.addElementsToColumnsEventType({ event_type: this.type.name, data: data})
            })
            this.page += 1
          }
        })        
      }
    },
    filters: {
      textTitle: function(value) {
        let result = ''
        switch (value) {
          case 'request_process':
            result = 'Solicitudes creadas'
            break;
          case 'information_completed':
            result = 'Solicitud de documentación para inicio de verificaciones'
            break;
          case 'request_validations':
            result = 'En proceso de verificaciones'
            break;
          case 'verifications_response':
            result = 'Respuesta de verificaciones'
            break;                                            
          case 'project_finished':
            result = 'Resultado final de los procesos'
            break;            
          default:
            result = value
            break;
        }
        return result
      },
      textDescription: function(value) {
        let result = ''
        switch (value) {
          case 'request_process':
            result = 'En esta etapa se valida que el proceso fue creado de forma exitosa por parte del cliente y solicitaremos la documentación para iniciar con las verificaciones'
            break;
          case 'information_completed':
            result = 'En esta etapa estamos solicitando la documentación a  la empresa y al candidato para iniciar el proceso de verificaciones'
            break;
          case 'request_validations':
            result = 'En esta etapa, estamos verificando la información del candidato de manera inmediata'
            break;
          case 'verifications_response':
            result = 'En esta etapa, podrás revisar resultados de las verificaciones que ya están finalizadas y encontrarás detalles de las verificaciones  que no han podido finalizarse'
            break;                                            
          case 'project_finished':
            result = 'En esta etapa, encontrarás los resultados finales de los procesos solicitados. Podrás revisar los detalles y acceder al informe completo'
            break;            
          default:
            result = value
            break;
        }
        return result
      },
    },
    components: {
      Card
    }    
  }
</script>

<style scoped>

</style>