<template>
  <div class="notifications">
    <ProjectComponent :popupShowActive="popupShowActive" :project="projectShow" v-if="popupShowActive"/>    
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/3 mb-base">      
      <vx-card title="Confiable">
        <ul class="activity-timeline" v-for="(projectTrust, index_inProcess) in projectsTrust" :key="index_inProcess">
          <li>
            <!-- <div v-bind:class="classTimeLines[notification.type_id]">
              <feather-icon v-bind:icon="icons[notification.type_id]" svgClasses="text-white stroke-current w-5 h-5"></feather-icon>
            </div> -->
            <div class="timeline-info">
              <p class="font-semibold">{{ projectTrust.full_name }}</p>
              <span class="activity-desc">Proceso: {{projectTrust.project_type}}</span>
            </div>            
            <vs-row vs-type="flex" vs-justify="space-between">
              <vs-col class="ago" vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                <small class="text-grey activity-e-time">{{convertTimeStamp(projectTrust.created_at)}}</small>
              </vs-col>                
              <!-- <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6"></vs-col>                 -->
              <vs-col class="view-more" vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                <vs-button color="primary" type="flat" @click="showProject(projectTrust.id)">Ver más</vs-button>                  
              </vs-col>                                                
            </vs-row>                                                    
            <div class="vx-col w-1/1 mb-3">
              <vs-progress class="block mt-1" :percent="10" color="primary"></vs-progress>
            </div>
          </li>
        </ul>
      </vx-card>          
      </div>
      <div class="vx-col w-full lg:w-1/3 mb-base">      
        <vx-card title="No confiable">
          <ul class="activity-timeline" v-for="(projectUnTrust, index_Preliminaries) in projectsUnTrust" :key="index_Preliminaries">
            <li>
              <!-- <div v-bind:class="classTimeLines[notification.type_id]">
                <feather-icon v-bind:icon="icons[notification.type_id]" svgClasses="text-white stroke-current w-5 h-5"></feather-icon>
              </div> -->
              <div class="timeline-info">
                <p class="font-semibold">{{ projectUnTrust.full_name }}</p>
                <span class="activity-desc">Proceso: {{projectUnTrust.project_type}}</span>
              </div>
              <vs-row vs-type="flex" vs-justify="space-between">
                <vs-col class="ago" vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                  <small class="text-grey activity-e-time">{{convertTimeStamp(projectUnTrust.created_at)}}</small>
                </vs-col>                
                <!-- <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6"></vs-col>                 -->
                <vs-col class="view-more" vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                  <vs-button color="primary" type="flat" @click="showProject(projectUnTrust.id)">Ver más</vs-button>                  
                </vs-col>                                                
              </vs-row>                                             
              <div class="vx-col w-1/1 mb-3">
                <vs-progress class="block mt-1" :percent="60" color="primary"></vs-progress>
              </div>
            </li>
          </ul>
        </vx-card>          
      </div>
      <div class="vx-col w-full lg:w-1/3 mb-base">      
        <vx-card title="Confiable con observaciones">
          <ul class="activity-timeline" v-for="(projectUnReliable, index_projectsUnReliable) in projectsUnReliable" :key="index_projectsUnReliable">
            <li>
              <!-- <div v-bind:class="classTimeLines[notification.type_id]">
                <feather-icon v-bind:icon="icons[notification.type_id]" svgClasses="text-white stroke-current w-5 h-5"></feather-icon>
              </div> -->
              <div class="timeline-info">
                <p class="font-semibold">{{ projectUnReliable.full_name }}</p>
                <span class="activity-desc">Proceso: {{projectUnReliable.project_type}}</span>
              </div>
              <vs-row vs-type="flex" vs-justify="space-between">
                <vs-col class="ago" vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                  <small class="text-grey activity-e-time">{{convertTimeStamp(projectUnReliable.created_at)}}</small>
                </vs-col>                
                <!-- <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6"></vs-col>                 -->
                <vs-col class="view-more" vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                  <vs-button color="primary" type="flat" @click="showProject(projectUnReliable.id)">Ver más</vs-button>                  
                </vs-col>                                                
              </vs-row>                                                       
              <div class="vx-col w-1/1 mb-3">
                <vs-progress class="block mt-1" :percent="100" color="primary"></vs-progress>
              </div>
            </li>
          </ul>
        </vx-card>                  
      </div>       
    </div>
    <!-- {{this.projectsTrust}}
    {{this.projectsUnTrust}}
    {{this.projectsUnReliable}} -->
  </div>
</template>
<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import api from '../mixins/api'
import moment from 'moment'
import 'moment/locale/es'  // without this line it didn't work
moment.locale('es')
import ProjectComponent from '../components-globalwork/projects/Project'
import { mapGetters } from 'vuex'
export default {
  mixins: [api],
  data(){
    return{
      popupShowActive: false,
      projectShow: {},      
      icons: ['PlayIcon', 'BellIcon', 'CheckIcon', 'AlertCircleIcon', 'FrownIcon'],
      classTimeLines: ['timeline-icon bg-primary', 'timeline-icon bg-dark', 'timeline-icon bg-success', 'timeline-icon bg-warning', 'timeline-icon bg-danger'],
      projectsTrust: [],
      projectsUnTrust: [],
      projectsUnReliable: []
    }
  },
  methods:{
    convertTimeStamp(timestamp){
      var date = new Date(timestamp * 1000);
      return moment(date, "YYYYMMDD").fromNow();
    },
    showProject(id){
      //console.log("***********showProject " + id + " ***********");
      this.popupShowActive = true;
      this.getProject(id)       
    },
    getProject(project_id){
      //console.log("***********getProject(project_id)***********");
      var user_id = this.userInfo.user.id
      var path = "/api/v1/projects/" + project_id + "?user_id="+user_id;
      this.requestGetWithoutContent(this.url()+path, {}).then(response => {
        //console.log('response', JSON.stringify(response))
        if(response.data.success == true){
          this.projectShow = response.data.data
        }else{
          //console.log("error -> " + JSON.stringify(response.data.message));          
        }
      }, (err) => {
        console.log('err users -> ' +  JSON.stringify(err))
      }) 
    },            
  },
  created: function(){
    var current_user = this.userInfo.user.id
    var path = "/api/v1/reports/clients_trello_overall_final?current_user="+current_user;
    this.requestGetWithoutContent(this.url()+path, {}).then(response => {
      ////console.log('response -> ' + response);
      if(response.data.success == true){
        //console.log('data -> ', JSON.stringify(response.data))
        this.projectsTrust = response.data.projects_trust
        this.projectsUnTrust = response.data.projects_untrust
        this.projectsUnReliable = response.data.projects_unreliable
      }else{
        //console.log(response.data.message);          
      }
    }, (err) => {
      console.log('err -> ' +  err)
    })  
  },
  watch: {

  }, 
  computed: {
    ...mapGetters(['userInfo']),
  }, 
  components: {
    StatisticsCardLine,
    ProjectComponent
  }
}
</script>
<style>

</style>


