<template>
  <div v-if="date != null">
    {{ date | formatDate }}
  </div>
</template>

<script>
  import moment from 'moment'
  import 'moment/locale/es'
  moment.locale('es')
  export default {
    data() {
      return {
        date: null
      }
    },
    props: {
      event: null
    },
    methods: {
      selectDate () {
        if (this.event.childrens.length > 0) {
          this.date = this.event.childrens[0].updated_at 
        } else {
          this.date = this.event.updated_at 
        }
      },      
    },
    mounted () {
      this.selectDate()
    },
    filters: {
      formatDate: function(value) {
        return `${moment(value).format('L LTS')}`
      }
    }        
  }
</script>

<style scoped>

</style>