var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "timer" }, [
    _c("div", { staticClass: "countdown", attrs: { loaded: "" } }, [
      _c("div", { staticClass: "container-time" }, [
        _c("p", { staticClass: "font-time" }, [
          _vm._v(_vm._s(_vm.displayDays)),
        ]),
      ]),
      _c("div", { staticClass: "container-time" }, [
        _c("p", { staticClass: "font-time" }, [
          _vm._v(_vm._s(_vm.displayHours)),
        ]),
      ]),
      _c("div", { staticClass: "container-time" }, [
        _c("p", { staticClass: "font-time" }, [
          _vm._v(_vm._s(_vm.displayMinutes)),
        ]),
      ]),
      _c("div", { staticClass: "container-time" }, [
        _c("p", { staticClass: "font-time" }, [
          _vm._v(_vm._s(_vm.displaySeconds)),
        ]),
      ]),
    ]),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "description-time" }, [
      _c("div", { staticClass: "container-time-text" }, [
        _c("p", { staticClass: "description-time-item" }, [_vm._v("Días")]),
      ]),
      _c("div", { staticClass: "container-time-text" }, [
        _c("p", { staticClass: "description-time-item" }, [_vm._v("Horas")]),
      ]),
      _c("div", { staticClass: "container-time-text" }, [
        _c("p", { staticClass: "description-time-item" }, [_vm._v("Minutos")]),
      ]),
      _c("div", { staticClass: "container-time-text" }, [
        _c("p", { staticClass: "description-time-item" }, [_vm._v("Segundos")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }