<template>
  <div class="line-area-chart">
    <vx-card v-bind:title="'Resúmen de procesos. Período de tiempo: ' + startDate + ' - ' + endDate">
      <form>
        <!-- <datepicker v-validate="'required'" class="pickadate-limits" name="dateStart" v-model="form.dateStart"></datepicker>
        <span class="text-danger text-sm" v-show="errors.has('dateStart')">Requerido</span>
        <br/>
        Fecha de finalización:
        <datepicker v-validate="'required'" class="pickadate-limits" name="dateEnd" v-model="form.dateEnd"></datepicker>
        <span class="text-danger text-sm" v-show="errors.has('dateEnd')">Requerido</span>
        <br/> -->
        <!-- Ciudad: <v-select v-validate="'required'" label="text" :options="cities" name="city" v-model="form.city"></v-select>
        <span class="text-danger text-sm" v-show="errors.has('city')">Requerido</span> -->
        <!-- <vs-button type="filled" @click.prevent="submitForm" class="mt-5 block">Consultar</vs-button> -->
        <vue-apex-charts type="area" height="350" :options="lineAreaChartSpline.chartOptions" :series="lineAreaChartSpline.series"></vue-apex-charts>            
      </form>
    </vx-card>  
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import api from '../mixins/api'
import { mapGetters } from 'vuex'
export default {
  mixins: [api],
  data(){
    return{
      startDate: '',
      endDate: '',
      form: {
        dateStart: '',
        dateEnd: '',
        city: ''
      },
      cities: [
        { value: null, text: 'Por favor seleccione una opción' },
{id: 1, label: 'MEDELLIN'},
{id: 2, label: 'ABEJORRAL'},
{id: 3, label: 'ABRIAQUI'},
{id: 4, label: 'ALEJANDRIA'},
{id: 5, label: 'AMAGA'},
{id: 6, label: 'AMALFI'},
{id: 7, label: 'ANDES'},
{id: 8, label: 'ANGELOPOLIS'},
{id: 9, label: 'ANGOSTURA'},
{id: 10, label: 'ANORI'},
{id: 11, label: 'ANTIOQUIA'},
{id: 12, label: 'ANZA'},
{id: 13, label: 'APARTADO'},
{id: 14, label: 'ARBOLETES'},
{id: 15, label: 'ARGELIA'},
{id: 16, label: 'ARMENIA'},
{id: 17, label: 'BARBOSA'},
{id: 18, label: 'BELMIRA'},
{id: 19, label: 'BELLO'},
{id: 20, label: 'BETANIA'},
{id: 21, label: 'BETULIA'},
{id: 22, label: 'BOLIVAR'},
{id: 23, label: 'BRICEÑO'},
{id: 24, label: 'BURITICA'},
{id: 25, label: 'CACERES'},
{id: 26, label: 'CAICEDO'},
{id: 27, label: 'CALDAS'},
{id: 28, label: 'CAMPAMENTO'},
{id: 29, label: 'CAÑASGORDAS'},
{id: 30, label: 'CARACOLI'},
{id: 31, label: 'CARAMANTA'},
{id: 32, label: 'CAREPA'},
{id: 33, label: 'CARMEN DE VIBORAL'},
{id: 34, label: 'CAROLINA'},
{id: 35, label: 'CAUCASIA'},
{id: 36, label: 'CHIGORODO'},
{id: 37, label: 'CISNEROS'},
{id: 38, label: 'COCORNA'},
{id: 39, label: 'CONCEPCION'},
{id: 40, label: 'CONCORDIA'},
{id: 41, label: 'COPACABANA'},
{id: 42, label: 'DABEIBA'},
{id: 43, label: 'DON MATIAS'},
{id: 44, label: 'EBEJICO'},
{id: 45, label: 'EL BAGRE'},
{id: 46, label: 'ENTRERRIOS'},
{id: 47, label: 'ENVIGADO'},
{id: 48, label: 'FREDONIA'},
{id: 49, label: 'FRONTINO'},
{id: 50, label: 'GIRALDO'},
{id: 51, label: 'GIRARDOTA'},
{id: 52, label: 'GOMEZ PLATA'},
{id: 53, label: 'GRANADA'},
{id: 54, label: 'GUADALUPE'},
{id: 55, label: 'GUARNE'},
{id: 56, label: 'GUATAPE'},
{id: 57, label: 'HELICONIA'},
{id: 58, label: 'HISPANIA'},
{id: 59, label: 'ITAGUI'},
{id: 60, label: 'ITUANGO'},
{id: 61, label: 'JARDIN'},
{id: 62, label: 'JERICO'},
{id: 63, label: 'LA CEJA'},
{id: 64, label: 'LA ESTRELLA'},
{id: 65, label: 'LA PINTADA'},
{id: 66, label: 'LA UNION'},
{id: 67, label: 'LIBORINA'},
{id: 68, label: 'MACEO'},
{id: 69, label: 'MARINILLA'},
{id: 70, label: 'MONTEBELLO'},
{id: 71, label: 'MURINDO'},
{id: 72, label: 'MUTATA'},
{id: 73, label: 'NARIÑO'},
{id: 74, label: 'NECOCLI'},
{id: 75, label: 'NECHI'},
{id: 76, label: 'OLAYA'},
{id: 77, label: 'PEÑOL'},
{id: 78, label: 'PEQUE'},
{id: 79, label: 'PUEBLORRICO'},
{id: 80, label: 'PUERTO BERRIO'},
{id: 81, label: 'PUERTO NARE (LA MAGDALENA)'},
{id: 82, label: 'PUERTO TRIUNFO'},
{id: 83, label: 'REMEDIOS'},
{id: 84, label: 'RETIRO'},
{id: 85, label: 'RIONEGRO'},
{id: 86, label: 'SABANALARGA'},
{id: 87, label: 'SABANETA'},
{id: 88, label: 'SALGAR'},
{id: 89, label: 'SAN ANDRES'},
{id: 90, label: 'SAN CARLOS'},
{id: 91, label: 'SAN FRANCISCO'},
{id: 92, label: 'SAN JERONIMO'},
{id: 93, label: 'SAN JOSE DE LA MONTAÑA'},
{id: 94, label: 'SAN JUAN DE URABA'},
{id: 95, label: 'SAN LUIS'},
{id: 96, label: 'SAN PEDRO'},
{id: 97, label: 'SAN PEDRO DE URABA'},
{id: 98, label: 'SAN RAFAEL'},
{id: 99, label: 'SAN ROQUE'},
{id: 100, label: 'SAN VICENTE'},
{id: 101, label: 'SANTA BARBARA'},
{id: 102, label: 'SANTA ROSA DE OSOS'},
{id: 103, label: 'SANTO DOMINGO'},
{id: 104, label: 'SANTUARIO'},
{id: 105, label: 'SEGOVIA'},
{id: 106, label: 'SONSON'},
{id: 107, label: 'SOPETRAN'},
{id: 108, label: 'TAMESIS'},
{id: 109, label: 'TARAZA'},
{id: 110, label: 'TARSO'},
{id: 111, label: 'TITIRIBI'},
{id: 112, label: 'TOLEDO'},
{id: 113, label: 'TURBO'},
{id: 114, label: 'URAMITA'},
{id: 115, label: 'URRAO'},
{id: 116, label: 'VALDIVIA'},
{id: 117, label: 'VALPARAISO'},
{id: 118, label: 'VEGACHI'},
{id: 119, label: 'VENECIA'},
{id: 120, label: 'VIGIA DEL FUERTE'},
{id: 121, label: 'YALI'},
{id: 122, label: 'YARUMAL'},
{id: 123, label: 'YOLOMBO'},
{id: 124, label: 'YONDO'},
{id: 125, label: 'ZARAGOZA'},
{id: 126, label: 'BARRANQUILLA (DISTRITO ESPECIAL INDUSTRIAL Y PORTUARIO DE BARRANQUILLA)'},
{id: 127, label: 'BARANOA'},
{id: 128, label: 'CAMPO DE LA CRUZ'},
{id: 129, label: 'CANDELARIA'},
{id: 130, label: 'GALAPA'},
{id: 131, label: 'JUAN DE ACOSTA'},
{id: 132, label: 'LURUACO'},
{id: 133, label: 'MALAMBO'},
{id: 134, label: 'MANATI'},
{id: 135, label: 'PALMAR DE VARELA'},
{id: 136, label: 'PIOJO'},
{id: 137, label: 'POLO NUEVO'},
{id: 138, label: 'PONEDERA'},
{id: 139, label: 'PUERTO COLOMBIA'},
{id: 140, label: 'REPELON'},
{id: 141, label: 'SABANAGRANDE'},
{id: 142, label: 'SABANALARGA'},
{id: 143, label: 'SANTA LUCIA'},
{id: 144, label: 'SANTO TOMAS'},
{id: 145, label: 'SOLEDAD'},
{id: 146, label: 'SUAN'},
{id: 147, label: 'TUBARA'},
{id: 148, label: 'USIACURI'},
{id: 149, label: 'Santa Fe de Bogotá'},
{id: 150, label: 'USAQUEN'},
{id: 151, label: 'CHAPINERO'},
{id: 152, label: 'SANTA FE'},
{id: 153, label: 'SAN CRISTOBAL'},
{id: 154, label: 'USME'},
{id: 155, label: 'TUNJUELITO'},
{id: 156, label: 'BOSA'},
{id: 157, label: 'KENNEDY'},
{id: 158, label: 'FONTIBON'},
{id: 159, label: 'ENGATIVA'},
{id: 160, label: 'SUBA'},
{id: 161, label: 'BARRIOS UNIDOS'},
{id: 162, label: 'TEUSAQUILLO'},
{id: 163, label: 'MARTIRES'},
{id: 164, label: 'ANTONIO NARIÑO'},
{id: 165, label: 'PUENTE ARANDA'},
{id: 166, label: 'CANDELARIA'},
{id: 167, label: 'RAFAEL URIBE'},
{id: 168, label: 'CIUDAD BOLIVAR'},
{id: 169, label: 'SUMAPAZ'},
{id: 170, label: 'CARTAGENA (DISTRITO TURISTICO Y CULTURAL DE CARTAGENA)'},
{id: 171, label: 'ACHI'},
{id: 172, label: 'ALTOS DEL ROSARIO'},
{id: 173, label: 'ARENAL'},
{id: 174, label: 'ARJONA'},
{id: 175, label: 'ARROYOHONDO'},
{id: 176, label: 'BARRANCO DE LOBA'},
{id: 177, label: 'CALAMAR'},
{id: 178, label: 'CANTAGALLO'},
{id: 179, label: 'CICUCO'},
{id: 180, label: 'CORDOBA'},
{id: 181, label: 'CLEMENCIA'},
{id: 182, label: 'EL CARMEN DE BOLIVAR'},
{id: 183, label: 'EL GUAMO'},
{id: 184, label: 'EL PEÑON'},
{id: 185, label: 'HATILLO DE LOBA'},
{id: 186, label: 'MAGANGUE'},
{id: 187, label: 'MAHATES'},
{id: 188, label: 'MARGARITA'},
{id: 189, label: 'MARIA LA BAJA'},
{id: 190, label: 'MONTECRISTO'},
{id: 191, label: 'MOMPOS'},
{id: 192, label: 'MORALES'},
{id: 193, label: 'PINILLOS'},
{id: 194, label: 'REGIDOR'},
{id: 195, label: 'RIO VIEJO'},
{id: 196, label: 'SAN CRISTOBAL'},
{id: 197, label: 'SAN ESTANISLAO'},
{id: 198, label: 'SAN FERNANDO'},
{id: 199, label: 'SAN JACINTO'},
{id: 200, label: 'SAN JACINTO DEL CAUCA'},
{id: 201, label: 'SAN JUAN NEPOMUCENO'},
{id: 202, label: 'SAN MARTIN DE LOBA'},
{id: 203, label: 'SAN PABLO'},
{id: 204, label: 'SANTA CATALINA'},
{id: 205, label: 'SANTA ROSA'},
{id: 206, label: 'SANTA ROSA DEL SUR'},
{id: 207, label: 'SIMITI'},
{id: 208, label: 'SOPLAVIENTO'},
{id: 209, label: 'TALAIGUA NUEVO'},
{id: 210, label: 'TIQUISIO (PUERTO RICO)'},
{id: 211, label: 'TURBACO'},
{id: 212, label: 'TURBANA'},
{id: 213, label: 'VILLANUEVA'},
{id: 214, label: 'ZAMBRANO'},
{id: 215, label: 'TUNJA'},
{id: 216, label: 'ALMEIDA'},
{id: 217, label: 'AQUITANIA'},
{id: 218, label: 'ARCABUCO'},
{id: 219, label: 'BELEN'},
{id: 220, label: 'BERBEO'},
{id: 221, label: 'BETEITIVA'},
{id: 222, label: 'BOAVITA'},
{id: 223, label: 'BOYACA'},
{id: 224, label: 'BRICEÑO'},
{id: 225, label: 'BUENAVISTA'},
{id: 226, label: 'BUSBANZA'},
{id: 227, label: 'CALDAS'},
{id: 228, label: 'CAMPOHERMOSO'},
{id: 229, label: 'CERINZA'},
{id: 230, label: 'CHINAVITA'},
{id: 231, label: 'CHIQUINQUIRA'},
{id: 232, label: 'CHISCAS'},
{id: 233, label: 'CHITA'},
{id: 234, label: 'CHITARAQUE'},
{id: 235, label: 'CHIVATA'},
{id: 236, label: 'CIENEGA'},
{id: 237, label: 'COMBITA'},
{id: 238, label: 'COPER'},
{id: 239, label: 'CORRALES'},
{id: 240, label: 'COVARACHIA'},
{id: 241, label: 'CUBARA'},
{id: 242, label: 'CUCAITA'},
{id: 243, label: 'CUITIVA'},
{id: 244, label: 'CHIQUIZA'},
{id: 245, label: 'CHIVOR'},
{id: 246, label: 'DUITAMA'},
{id: 247, label: 'EL COCUY'},
{id: 248, label: 'EL ESPINO'},
{id: 249, label: 'FIRAVITOBA'},
{id: 250, label: 'FLORESTA'},
{id: 251, label: 'GACHANTIVA'},
{id: 252, label: 'GAMEZA'},
{id: 253, label: 'GARAGOA'},
{id: 254, label: 'GUACAMAYAS'},
{id: 255, label: 'GUATEQUE'},
{id: 256, label: 'GUAYATA'},
{id: 257, label: 'GUICAN'},
{id: 258, label: 'IZA'},
{id: 259, label: 'JENESANO'},
{id: 260, label: 'JERICO'},
{id: 261, label: 'LABRANZAGRANDE'},
{id: 262, label: 'LA CAPILLA'},
{id: 263, label: 'LA VICTORIA'},
{id: 264, label: 'LA UVITA'},
{id: 265, label: 'VILLA DE LEIVA'},
{id: 266, label: 'MACANAL'},
{id: 267, label: 'MARIPI'},
{id: 268, label: 'MIRAFLORES'},
{id: 269, label: 'MONGUA'},
{id: 270, label: 'MONGUI'},
{id: 271, label: 'MONIQUIRA'},
{id: 272, label: 'MOTAVITA'},
{id: 273, label: 'MUZO'},
{id: 274, label: 'NOBSA'},
{id: 275, label: 'NUEVO COLON'},
{id: 276, label: 'OICATA'},
{id: 277, label: 'OTANCHE'},
{id: 278, label: 'PACHAVITA'},
{id: 279, label: 'PAEZ'},
{id: 280, label: 'PAIPA'},
{id: 281, label: 'PAJARITO'},
{id: 282, label: 'PANQUEBA'},
{id: 283, label: 'PAUNA'},
{id: 284, label: 'PAYA'},
{id: 285, label: 'PAZ DEL RIO'},
{id: 286, label: 'PESCA'},
{id: 287, label: 'PISBA'},
{id: 288, label: 'PUERTO BOYACA'},
{id: 289, label: 'QUIPAMA'},
{id: 290, label: 'RAMIRIQUI'},
{id: 291, label: 'RAQUIRA'},
{id: 292, label: 'RONDON'},
{id: 293, label: 'SABOYA'},
{id: 294, label: 'SACHICA'},
{id: 295, label: 'SAMACA'},
{id: 296, label: 'SAN EDUARDO'},
{id: 297, label: 'SAN JOSE DE PARE'},
{id: 298, label: 'SAN LUIS DE GACENO'},
{id: 299, label: 'SAN MATEO'},
{id: 300, label: 'SAN MIGUEL DE SEMA'},
{id: 301, label: 'SAN PABLO DE BORBUR'},
{id: 302, label: 'SANTANA'},
{id: 303, label: 'SANTA MARIA'},
{id: 304, label: 'SANTA ROSA DE VITERBO'},
{id: 305, label: 'SANTA SOFIA'},
{id: 306, label: 'SATIVANORTE'},
{id: 307, label: 'SATIVASUR'},
{id: 308, label: 'SIACHOQUE'},
{id: 309, label: 'SOATA'},
{id: 310, label: 'SOCOTA'},
{id: 311, label: 'SOCHA'},
{id: 312, label: 'SOGAMOSO'},
{id: 313, label: 'SOMONDOCO'},
{id: 314, label: 'SORA'},
{id: 315, label: 'SOTAQUIRA'},
{id: 316, label: 'SORACA'},
{id: 317, label: 'SUSACON'},
{id: 318, label: 'SUTAMARCHAN'},
{id: 319, label: 'SUTATENZA'},
{id: 320, label: 'TASCO'},
{id: 321, label: 'TENZA'},
{id: 322, label: 'TIBANA'},
{id: 323, label: 'TIBASOSA'},
{id: 324, label: 'TINJACA'},
{id: 325, label: 'TIPACOQUE'},
{id: 326, label: 'TOCA'},
{id: 327, label: 'TOGUI'},
{id: 328, label: 'TOPAGA'},
{id: 329, label: 'TOTA'},
{id: 330, label: 'TUNUNGUA'},
{id: 331, label: 'TURMEQUE'},
{id: 332, label: 'TUTA'},
{id: 333, label: 'TUTASA'},
{id: 334, label: 'UMBITA'},
{id: 335, label: 'VENTAQUEMADA'},
{id: 336, label: 'VIRACACHA'},
{id: 337, label: 'ZETAQUIRA'},
{id: 338, label: 'MANIZALES'},
{id: 339, label: 'AGUADAS'},
{id: 340, label: 'ANSERMA'},
{id: 341, label: 'ARANZAZU'},
{id: 342, label: 'BELALCAZAR'},
{id: 343, label: 'CHINCHINA'},
{id: 344, label: 'FILADELFIA'},
{id: 345, label: 'LA DORADA'},
{id: 346, label: 'LA MERCED'},
{id: 347, label: 'MANZANARES'},
{id: 348, label: 'MARMATO'},
{id: 349, label: 'MARQUETALIA'},
{id: 350, label: 'MARULANDA'},
{id: 351, label: 'NEIRA'},
{id: 352, label: 'NORCASIA'},
{id: 353, label: 'PACORA'},
{id: 354, label: 'PALESTINA'},
{id: 355, label: 'PENSILVANIA'},
{id: 356, label: 'RIOSUCIO'},
{id: 357, label: 'RISARALDA'},
{id: 358, label: 'SALAMINA'},
{id: 359, label: 'SAMANA'},
{id: 360, label: 'SAN JOSE'},
{id: 361, label: 'SUPIA'},
{id: 362, label: 'VICTORIA'},
{id: 363, label: 'VILLAMARIA'},
{id: 364, label: 'VITERBO'},
{id: 365, label: 'FLORENCIA'},
{id: 366, label: 'ALBANIA'},
{id: 367, label: 'BELEN DE LOS ANDAQUIES'},
{id: 368, label: 'CARTAGENA DEL CHAIRA'},
{id: 369, label: 'CURILLO'},
{id: 370, label: 'EL DONCELLO'},
{id: 371, label: 'EL PAUJIL'},
{id: 372, label: 'LA MONTAÑITA'},
{id: 373, label: 'MILAN'},
{id: 374, label: 'MORELIA'},
{id: 375, label: 'PUERTO RICO'},
{id: 376, label: 'SAN JOSE DE FRAGUA'},
{id: 377, label: 'SAN VICENTE DEL CAGUAN'},
{id: 378, label: 'SOLANO'},
{id: 379, label: 'SOLITA'},
{id: 380, label: 'VALPARAISO'},
{id: 381, label: 'POPAYAN'},
{id: 382, label: 'ALMAGUER'},
{id: 383, label: 'ARGELIA'},
{id: 384, label: 'BALBOA'},
{id: 385, label: 'BOLIVAR'},
{id: 386, label: 'BUENOS AIRES'},
{id: 387, label: 'CAJIBIO'},
{id: 388, label: 'CALDONO'},
{id: 389, label: 'CALOTO'},
{id: 390, label: 'CORINTO'},
{id: 391, label: 'EL TAMBO'},
{id: 392, label: 'FLORENCIA'},
{id: 393, label: 'GUAPI'},
{id: 394, label: 'INZA'},
{id: 395, label: 'JAMBALO'},
{id: 396, label: 'LA SIERRA'},
{id: 397, label: 'LA VEGA'},
{id: 398, label: 'LOPEZ (MICAY)'},
{id: 399, label: 'MERCADERES'},
{id: 400, label: 'MIRANDA'},
{id: 401, label: 'MORALES'},
{id: 402, label: 'PADILLA'},
{id: 403, label: 'PAEZ (BELALCAZAR)'},
{id: 404, label: 'PATIA (EL BORDO)'},
{id: 405, label: 'PIAMONTE'},
{id: 406, label: 'PIENDAMO'},
{id: 407, label: 'PUERTO TEJADA'},
{id: 408, label: 'PURACE (COCONUCO)'},
{id: 409, label: 'ROSAS'},
{id: 410, label: 'SAN SEBASTIAN'},
{id: 411, label: 'SANTANDER DE QUILICHAO'},
{id: 412, label: 'SANTA ROSA'},
{id: 413, label: 'SILVIA'},
{id: 414, label: 'SOTARA (PAISPAMBA)'},
{id: 415, label: 'SUAREZ'},
{id: 416, label: 'TIMBIO'},
{id: 417, label: 'TIMBIQUI'},
{id: 418, label: 'TORIBIO'},
{id: 419, label: 'TOTORO'},
{id: 420, label: 'VILLARICA'},
{id: 421, label: 'VALLEDUPAR'},
{id: 422, label: 'AGUACHICA'},
{id: 423, label: 'AGUSTIN CODAZZI'},
{id: 424, label: 'ASTREA'},
{id: 425, label: 'BECERRIL'},
{id: 426, label: 'BOSCONIA'},
{id: 427, label: 'CHIMICHAGUA'},
{id: 428, label: 'CHIRIGUANA'},
{id: 429, label: 'CURUMANI'},
{id: 430, label: 'EL COPEY'},
{id: 431, label: 'EL PASO'},
{id: 432, label: 'GAMARRA'},
{id: 433, label: 'GONZALEZ'},
{id: 434, label: 'LA GLORIA'},
{id: 435, label: 'LA JAGUA IBIRICO'},
{id: 436, label: 'MANAURE (BALCON DEL CESAR)'},
{id: 437, label: 'PAILITAS'},
{id: 438, label: 'PELAYA'},
{id: 439, label: 'PUEBLO BELLO'},
{id: 440, label: 'RIO DE ORO'},
{id: 441, label: 'LA PAZ (ROBLES)'},
{id: 442, label: 'SAN ALBERTO'},
{id: 443, label: 'SAN DIEGO'},
{id: 444, label: 'SAN MARTIN'},
{id: 445, label: 'TAMALAMEQUE'},
{id: 446, label: 'MONTERIA'},
{id: 447, label: 'AYAPEL'},
{id: 448, label: 'BUENAVISTA'},
{id: 449, label: 'CANALETE'},
{id: 450, label: 'CERETE'},
{id: 451, label: 'CHIMA'},
{id: 452, label: 'CHINU'},
{id: 453, label: 'CIENAGA DE ORO'},
{id: 454, label: 'COTORRA'},
{id: 455, label: 'LA APARTADA'},
{id: 456, label: 'LORICA'},
{id: 457, label: 'LOS CORDOBAS'},
{id: 458, label: 'MOMIL'},
{id: 459, label: 'MONTELIBANO'},
{id: 460, label: 'MOÑITOS'},
{id: 461, label: 'PLANETA RICA'},
{id: 462, label: 'PUEBLO NUEVO'},
{id: 463, label: 'PUERTO ESCONDIDO'},
{id: 464, label: 'PUERTO LIBERTADOR'},
{id: 465, label: 'PURISIMA'},
{id: 466, label: 'SAHAGUN'},
{id: 467, label: 'SAN ANDRES SOTAVENTO'},
{id: 468, label: 'SAN ANTERO'},
{id: 469, label: 'SAN BERNARDO DEL VIENTO'},
{id: 470, label: 'SAN CARLOS'},
{id: 471, label: 'SAN PELAYO'},
{id: 472, label: 'TIERRALTA'},
{id: 473, label: 'VALENCIA'},
{id: 474, label: 'AGUA DE DIOS'},
{id: 475, label: 'ALBAN'},
{id: 476, label: 'ANAPOIMA'},
{id: 477, label: 'ANOLAIMA'},
{id: 478, label: 'ARBELAEZ'},
{id: 479, label: 'BELTRAN'},
{id: 480, label: 'BITUIMA'},
{id: 481, label: 'BOJACA'},
{id: 482, label: 'CABRERA'},
{id: 483, label: 'CACHIPAY'},
{id: 484, label: 'CAJICA'},
{id: 485, label: 'CAPARRAPI'},
{id: 486, label: 'CAQUEZA'},
{id: 487, label: 'CARMEN DE CARUPA'},
{id: 488, label: 'CHAGUANI'},
{id: 489, label: 'CHIA'},
{id: 490, label: 'CHIPAQUE'},
{id: 491, label: 'CHOACHI'},
{id: 492, label: 'CHOCONTA'},
{id: 493, label: 'COGUA'},
{id: 494, label: 'COTA'},
{id: 495, label: 'CUCUNUBA'},
{id: 496, label: 'EL COLEGIO'},
{id: 497, label: 'EL PEÑON'},
{id: 498, label: 'EL ROSAL'},
{id: 499, label: 'FACATATIVA'},
{id: 500, label: 'FOMEQUE'},
{id: 501, label: 'FOSCA'},
{id: 502, label: 'FUNZA'},
{id: 503, label: 'FUQUENE'},
{id: 504, label: 'FUSAGASUGA'},
{id: 505, label: 'GACHALA'},
{id: 506, label: 'GACHANCIPA'},
{id: 507, label: 'GACHETA'},
{id: 508, label: 'GAMA'},
{id: 509, label: 'GIRARDOT'},
{id: 510, label: 'GRANADA'},
{id: 511, label: 'GUACHETA'},
{id: 512, label: 'GUADUAS'},
{id: 513, label: 'GUASCA'},
{id: 514, label: 'GUATAQUI'},
{id: 515, label: 'GUATAVITA'},
{id: 516, label: 'GUAYABAL DE SIQUIMA'},
{id: 517, label: 'GUAYABETAL'},
{id: 518, label: 'GUTIERREZ'},
{id: 519, label: 'JERUSALEN'},
{id: 520, label: 'JUNIN'},
{id: 521, label: 'LA CALERA'},
{id: 522, label: 'LA MESA'},
{id: 523, label: 'LA PALMA'},
{id: 524, label: 'LA PEÑA'},
{id: 525, label: 'LA VEGA'},
{id: 526, label: 'LENGUAZAQUE'},
{id: 527, label: 'MACHETA'},
{id: 528, label: 'MADRID'},
{id: 529, label: 'MANTA'},
{id: 530, label: 'MEDINA'},
{id: 531, label: 'MOSQUERA'},
{id: 532, label: 'NARIÑO'},
{id: 533, label: 'NEMOCON'},
{id: 534, label: 'NILO'},
{id: 535, label: 'NIMAIMA'},
{id: 536, label: 'NOCAIMA'},
{id: 537, label: 'VENECIA (OSPINA PEREZ)'},
{id: 538, label: 'PACHO'},
{id: 539, label: 'PAIME'},
{id: 540, label: 'PANDI'},
{id: 541, label: 'PARATEBUENO'},
{id: 542, label: 'PASCA'},
{id: 543, label: 'PUERTO SALGAR'},
{id: 544, label: 'PULI'},
{id: 545, label: 'QUEBRADANEGRA'},
{id: 546, label: 'QUETAME'},
{id: 547, label: 'QUIPILE'},
{id: 548, label: 'APULO (RAFAEL REYES)'},
{id: 549, label: 'RICAURTE'},
{id: 550, label: 'SAN ANTONIO DEL TEQUENDAMA'},
{id: 551, label: 'SAN BERNARDO'},
{id: 552, label: 'SAN CAYETANO'},
{id: 553, label: 'SAN FRANCISCO'},
{id: 554, label: 'SAN JUAN DE RIOSECO'},
{id: 555, label: 'SASAIMA'},
{id: 556, label: 'SESQUILE'},
{id: 557, label: 'SIBATE'},
{id: 558, label: 'SILVANIA'},
{id: 559, label: 'SIMIJACA'},
{id: 560, label: 'SOACHA'},
{id: 561, label: 'SOPO'},
{id: 562, label: 'SUBACHOQUE'},
{id: 563, label: 'SUESCA'},
{id: 564, label: 'SUPATA'},
{id: 565, label: 'SUSA'},
{id: 566, label: 'SUTATAUSA'},
{id: 567, label: 'TABIO'},
{id: 568, label: 'TAUSA'},
{id: 569, label: 'TENA'},
{id: 570, label: 'TENJO'},
{id: 571, label: 'TIBACUY'},
{id: 572, label: 'TIBIRITA'},
{id: 573, label: 'TOCAIMA'},
{id: 574, label: 'TOCANCIPA'},
{id: 575, label: 'TOPAIPI'},
{id: 576, label: 'UBALA'},
{id: 577, label: 'UBAQUE'},
{id: 578, label: 'UBATE'},
{id: 579, label: 'UNE'},
{id: 580, label: 'UTICA'},
{id: 581, label: 'VERGARA'},
{id: 582, label: 'VIANI'},
{id: 583, label: 'VILLAGOMEZ'},
{id: 584, label: 'VILLAPINZON'},
{id: 585, label: 'VILLETA'},
{id: 586, label: 'VIOTA'},
{id: 587, label: 'YACOPI'},
{id: 588, label: 'ZIPACON'},
{id: 589, label: 'ZIPAQUIRA'},
{id: 590, label: 'QUIBDO (SAN FRANCISCO DE QUIBDO)'},
{id: 591, label: 'ACANDI'},
{id: 592, label: 'ALTO BAUDO (PIE DE PATO)'},
{id: 593, label: 'ATRATO'},
{id: 594, label: 'BAGADO'},
{id: 595, label: 'BAHIA SOLANO (MUTIS)'},
{id: 596, label: 'BAJO BAUDO (PIZARRO)'},
{id: 597, label: 'BOJAYA (BELLAVISTA)'},
{id: 598, label: 'CANTON DE SAN PABLO (MANAGRU)'},
{id: 599, label: 'CONDOTO'},
{id: 600, label: 'EL CARMEN DE ATRATO'},
{id: 601, label: 'LITORAL DEL BAJO SAN JUAN (SANTA GENOVEVA DE DOCORDO)'},
{id: 602, label: 'ISTMINA'},
{id: 603, label: 'JURADO'},
{id: 604, label: 'LLORO'},
{id: 605, label: 'MEDIO ATRATO'},
{id: 606, label: 'MEDIO BAUDO'},
{id: 607, label: 'NOVITA'},
{id: 608, label: 'NUQUI'},
{id: 609, label: 'RIOQUITO'},
{id: 610, label: 'RIOSUCIO'},
{id: 611, label: 'SAN JOSE DEL PALMAR'},
{id: 612, label: 'SIPI'},
{id: 613, label: 'TADO'},
{id: 614, label: 'UNGUIA'},
{id: 615, label: 'UNION PANAMERICANA'},
{id: 616, label: 'NEIVA'},
{id: 617, label: 'ACEVEDO'},
{id: 618, label: 'AGRADO'},
{id: 619, label: 'AIPE'},
{id: 620, label: 'ALGECIRAS'},
{id: 621, label: 'ALTAMIRA'},
{id: 622, label: 'BARAYA'},
{id: 623, label: 'CAMPOALEGRE'},
{id: 624, label: 'COLOMBIA'},
{id: 625, label: 'ELIAS'},
{id: 626, label: 'GARZON'},
{id: 627, label: 'GIGANTE'},
{id: 628, label: 'GUADALUPE'},
{id: 629, label: 'HOBO'},
{id: 630, label: 'IQUIRA'},
{id: 631, label: 'ISNOS (SAN JOSE DE ISNOS)'},
{id: 632, label: 'LA ARGENTINA'},
{id: 633, label: 'LA PLATA'},
{id: 634, label: 'NATAGA'},
{id: 635, label: 'OPORAPA'},
{id: 636, label: 'PAICOL'},
{id: 637, label: 'PALERMO'},
{id: 638, label: 'PALESTINA'},
{id: 639, label: 'PITAL'},
{id: 640, label: 'PITALITO'},
{id: 641, label: 'RIVERA'},
{id: 642, label: 'SALADOBLANCO'},
{id: 643, label: 'SAN AGUSTIN'},
{id: 644, label: 'SANTA MARIA'},
{id: 645, label: 'SUAZA'},
{id: 646, label: 'TARQUI'},
{id: 647, label: 'TESALIA'},
{id: 648, label: 'TELLO'},
{id: 649, label: 'TERUEL'},
{id: 650, label: 'TIMANA'},
{id: 651, label: 'VILLAVIEJA'},
{id: 652, label: 'YAGUARA'},
{id: 653, label: 'RIOHACHA'},
{id: 654, label: 'BARRANCAS'},
{id: 655, label: 'DIBULLA'},
{id: 656, label: 'DISTRACCION'},
{id: 657, label: 'EL MOLINO'},
{id: 658, label: 'FONSECA'},
{id: 659, label: 'HATONUEVO'},
{id: 660, label: 'LA JAGUA DEL PILAR'},
{id: 661, label: 'MAICAO'},
{id: 662, label: 'MANAURE'},
{id: 663, label: 'SAN JUAN DEL CESAR'},
{id: 664, label: 'URIBIA'},
{id: 665, label: 'URUMITA'},
{id: 666, label: 'VILLANUEVA'},
{id: 667, label: 'SANTA MARTA (DISTRITO TURISTICO CULTURAL E HISTORICO DE SANTA MARTA)'},
{id: 668, label: 'ALGARROBO'},
{id: 669, label: 'ARACATACA'},
{id: 670, label: 'ARIGUANI (EL DIFICIL)'},
{id: 671, label: 'CERRO SAN ANTONIO'},
{id: 672, label: 'CHIVOLO'},
{id: 673, label: 'CIENAGA'},
{id: 674, label: 'CONCORDIA'},
{id: 675, label: 'EL BANCO'},
{id: 676, label: 'EL PIÑON'},
{id: 677, label: 'EL RETEN'},
{id: 678, label: 'FUNDACION'},
{id: 679, label: 'GUAMAL'},
{id: 680, label: 'PEDRAZA'},
{id: 681, label: 'PIJIÑO DEL CARMEN (PIJIÑO)'},
{id: 682, label: 'PIVIJAY'},
{id: 683, label: 'PLATO'},
{id: 684, label: 'PUEBLOVIEJO'},
{id: 685, label: 'REMOLINO'},
{id: 686, label: 'SABANAS DE SAN ANGEL'},
{id: 687, label: 'SALAMINA'},
{id: 688, label: 'SAN SEBASTIAN DE BUENAVISTA'},
{id: 689, label: 'SAN ZENON'},
{id: 690, label: 'SANTA ANA'},
{id: 691, label: 'SITIONUEVO'},
{id: 692, label: 'TENERIFE'},
{id: 693, label: 'VILLAVICENCIO'},
{id: 694, label: 'ACACIAS'},
{id: 695, label: 'BARRANCA DE UPIA'},
{id: 696, label: 'CABUYARO'},
{id: 697, label: 'CASTILLA LA NUEVA'},
{id: 698, label: 'SAN LUIS DE CUBARRAL'},
{id: 699, label: 'CUMARAL'},
{id: 700, label: 'EL CALVARIO'},
{id: 701, label: 'EL CASTILLO'},
{id: 702, label: 'EL DORADO'},
{id: 703, label: 'FUENTE DE ORO'},
{id: 704, label: 'GRANADA'},
{id: 705, label: 'GUAMAL'},
{id: 706, label: 'MAPIRIPAN'},
{id: 707, label: 'MESETAS'},
{id: 708, label: 'LA MACARENA'},
{id: 709, label: 'LA URIBE'},
{id: 710, label: 'LEJANIAS'},
{id: 711, label: 'PUERTO CONCORDIA'},
{id: 712, label: 'PUERTO GAITAN'},
{id: 713, label: 'PUERTO LOPEZ'},
{id: 714, label: 'PUERTO LLERAS'},
{id: 715, label: 'PUERTO RICO'},
{id: 716, label: 'RESTREPO'},
{id: 717, label: 'SAN CARLOS DE GUAROA'},
{id: 718, label: 'SAN JUAN DE ARAMA'},
{id: 719, label: 'SAN JUANITO'},
{id: 720, label: 'SAN MARTIN'},
{id: 721, label: 'VISTAHERMOSA'},
{id: 722, label: 'PASTO (SAN JUAN DE PASTO)'},
{id: 723, label: 'ALBAN (SAN JOSE)'},
{id: 724, label: 'ALDANA'},
{id: 725, label: 'ANCUYA'},
{id: 726, label: 'ARBOLEDA (BERRUECOS)'},
{id: 727, label: 'BARBACOAS'},
{id: 728, label: 'BELEN'},
{id: 729, label: 'BUESACO'},
{id: 730, label: 'COLON (GENOVA)'},
{id: 731, label: 'CONSACA'},
{id: 732, label: 'CONTADERO'},
{id: 733, label: 'CORDOBA'},
{id: 734, label: 'CUASPUD (CARLOSAMA)'},
{id: 735, label: 'CUMBAL'},
{id: 736, label: 'CUMBITARA'},
{id: 737, label: 'CHACHAGUI'},
{id: 738, label: 'EL CHARCO'},
{id: 739, label: 'EL PEÑOL'},
{id: 740, label: 'EL ROSARIO'},
{id: 741, label: 'EL TABLON'},
{id: 742, label: 'EL TAMBO'},
{id: 743, label: 'FUNES'},
{id: 744, label: 'GUACHUCAL'},
{id: 745, label: 'GUAITARILLA'},
{id: 746, label: 'GUALMATAN'},
{id: 747, label: 'ILES'},
{id: 748, label: 'IMUES'},
{id: 749, label: 'IPIALES'},
{id: 750, label: 'LA CRUZ'},
{id: 751, label: 'LA FLORIDA'},
{id: 752, label: 'LA LLANADA'},
{id: 753, label: 'LA TOLA'},
{id: 754, label: 'LA UNION'},
{id: 755, label: 'LEIVA'},
{id: 756, label: 'LINARES'},
{id: 757, label: 'LOS ANDES (SOTOMAYOR)'},
{id: 758, label: 'MAGUI (PAYAN)'},
{id: 759, label: 'MALLAMA (PIEDRANCHA)'},
{id: 760, label: 'MOSQUERA'},
{id: 761, label: 'OLAYA HERRERA (BOCAS DE SATINGA)'},
{id: 762, label: 'OSPINA'},
{id: 763, label: 'FRANCISCO PIZARRO (SALAHONDA)'},
{id: 764, label: 'POLICARPA'},
{id: 765, label: 'POTOSI'},
{id: 766, label: 'PROVIDENCIA'},
{id: 767, label: 'PUERRES'},
{id: 768, label: 'PUPIALES'},
{id: 769, label: 'RICAURTE'},
{id: 770, label: 'ROBERTO PAYAN (SAN JOSE)'},
{id: 771, label: 'SAMANIEGO'},
{id: 772, label: 'SANDONA'},
{id: 773, label: 'SAN BERNARDO'},
{id: 774, label: 'SAN LORENZO'},
{id: 775, label: 'SAN PABLO'},
{id: 776, label: 'SAN PEDRO DE CARTAGO'},
{id: 777, label: 'SANTA BARBARA (ISCUANDE)'},
{id: 778, label: 'SANTA CRUZ (GUACHAVES)'},
{id: 779, label: 'SAPUYES'},
{id: 780, label: 'TAMINANGO'},
{id: 781, label: 'TANGUA'},
{id: 782, label: 'TUMACO'},
{id: 783, label: 'TUQUERRES'},
{id: 784, label: 'YACUANQUER'},
{id: 785, label: 'CUCUTA'},
{id: 786, label: 'ABREGO'},
{id: 787, label: 'ARBOLEDAS'},
{id: 788, label: 'BOCHALEMA'},
{id: 789, label: 'BUCARASICA'},
{id: 790, label: 'CACOTA'},
{id: 791, label: 'CACHIRA'},
{id: 792, label: 'CHINACOTA'},
{id: 793, label: 'CHITAGA'},
{id: 794, label: 'CONVENCION'},
{id: 795, label: 'CUCUTILLA'},
{id: 796, label: 'DURANIA'},
{id: 797, label: 'EL CARMEN'},
{id: 798, label: 'EL TARRA'},
{id: 799, label: 'EL ZULIA'},
{id: 800, label: 'GRAMALOTE'},
{id: 801, label: 'HACARI'},
{id: 802, label: 'HERRAN'},
{id: 803, label: 'LABATECA'},
{id: 804, label: 'LA ESPERANZA'},
{id: 805, label: 'LA PLAYA'},
{id: 806, label: 'LOS PATIOS'},
{id: 807, label: 'LOURDES'},
{id: 808, label: 'MUTISCUA'},
{id: 809, label: 'OCAÑA'},
{id: 810, label: 'PAMPLONA'},
{id: 811, label: 'PAMPLONITA'},
{id: 812, label: 'PUERTO SANTANDER'},
{id: 813, label: 'RAGONVALIA'},
{id: 814, label: 'SALAZAR'},
{id: 815, label: 'SAN CALIXTO'},
{id: 816, label: 'SAN CAYETANO'},
{id: 817, label: 'SANTIAGO'},
{id: 818, label: 'SARDINATA'},
{id: 819, label: 'SILOS'},
{id: 820, label: 'TEORAMA'},
{id: 821, label: 'TIBU'},
{id: 822, label: 'TOLEDO'},
{id: 823, label: 'VILLACARO'},
{id: 824, label: 'VILLA DEL ROSARIO'},
{id: 825, label: 'ARMENIA'},
{id: 826, label: 'BUENAVISTA'},
{id: 827, label: 'CALARCA'},
{id: 828, label: 'CIRCASIA'},
{id: 829, label: 'CORDOBA'},
{id: 830, label: 'FILANDIA'},
{id: 831, label: 'GENOVA'},
{id: 832, label: 'LA TEBAIDA'},
{id: 833, label: 'MONTENEGRO'},
{id: 834, label: 'PIJAO'},
{id: 835, label: 'QUIMBAYA'},
{id: 836, label: 'SALENTO'},
{id: 837, label: 'PEREIRA'},
{id: 838, label: 'APIA'},
{id: 839, label: 'BALBOA'},
{id: 840, label: 'BELEN DE UMBRIA'},
{id: 841, label: 'DOS QUEBRADAS'},
{id: 842, label: 'GUATICA'},
{id: 843, label: 'LA CELIA'},
{id: 844, label: 'LA VIRGINIA'},
{id: 845, label: 'MARSELLA'},
{id: 846, label: 'MISTRATO'},
{id: 847, label: 'PUEBLO RICO'},
{id: 848, label: 'QUINCHIA'},
{id: 849, label: 'SANTA ROSA DE CABAL'},
{id: 850, label: 'SANTUARIO'},
{id: 851, label: 'BUCARAMANGA'},
{id: 852, label: 'AGUADA'},
{id: 853, label: 'ALBANIA'},
{id: 854, label: 'ARATOCA'},
{id: 855, label: 'BARBOSA'},
{id: 856, label: 'BARICHARA'},
{id: 857, label: 'BARRANCABERMEJA'},
{id: 858, label: 'BETULIA'},
{id: 859, label: 'BOLIVAR'},
{id: 860, label: 'CABRERA'},
{id: 861, label: 'CALIFORNIA'},
{id: 862, label: 'CAPITANEJO'},
{id: 863, label: 'CARCASI'},
{id: 864, label: 'CEPITA'},
{id: 865, label: 'CERRITO'},
{id: 866, label: 'CHARALA'},
{id: 867, label: 'CHARTA'},
{id: 868, label: 'CHIMA'},
{id: 869, label: 'CHIPATA'},
{id: 870, label: 'CIMITARRA'},
{id: 871, label: 'CONCEPCION'},
{id: 872, label: 'CONFINES'},
{id: 873, label: 'CONTRATACION'},
{id: 874, label: 'COROMORO'},
{id: 875, label: 'CURITI'},
{id: 876, label: 'EL CARMEN DE CHUCURY'},
{id: 877, label: 'EL GUACAMAYO'},
{id: 878, label: 'EL PEÑON'},
{id: 879, label: 'EL PLAYON'},
{id: 880, label: 'ENCINO'},
{id: 881, label: 'ENCISO'},
{id: 882, label: 'FLORIAN'},
{id: 883, label: 'FLORIDABLANCA'},
{id: 884, label: 'GALAN'},
{id: 885, label: 'GAMBITA'},
{id: 886, label: 'GIRON'},
{id: 887, label: 'GUACA'},
{id: 888, label: 'GUADALUPE'},
{id: 889, label: 'GUAPOTA'},
{id: 890, label: 'GUAVATA'},
{id: 891, label: 'GUEPSA'},
{id: 892, label: 'HATO'},
{id: 893, label: 'JESUS MARIA'},
{id: 894, label: 'JORDAN'},
{id: 895, label: 'LA BELLEZA'},
{id: 896, label: 'LANDAZURI'},
{id: 897, label: 'LA PAZ'},
{id: 898, label: 'LEBRIJA'},
{id: 899, label: 'LOS SANTOS'},
{id: 900, label: 'MACARAVITA'},
{id: 901, label: 'MALAGA'},
{id: 902, label: 'MATANZA'},
{id: 903, label: 'MOGOTES'},
{id: 904, label: 'MOLAGAVITA'},
{id: 905, label: 'OCAMONTE'},
{id: 906, label: 'OIBA'},
{id: 907, label: 'ONZAGA'},
{id: 908, label: 'PALMAR'},
{id: 909, label: 'PALMAS DEL SOCORRO'},
{id: 910, label: 'PARAMO'},
{id: 911, label: 'PIEDECUESTA'},
{id: 912, label: 'PINCHOTE'},
{id: 913, label: 'PUENTE NACIONAL'},
{id: 914, label: 'PUERTO PARRA'},
{id: 915, label: 'PUERTO WILCHES'},
{id: 916, label: 'RIONEGRO'},
{id: 917, label: 'SABANA DE TORRES'},
{id: 918, label: 'SAN ANDRES'},
{id: 919, label: 'SAN BENITO'},
{id: 920, label: 'SAN GIL'},
{id: 921, label: 'SAN JOAQUIN'},
{id: 922, label: 'SAN JOSE DE MIRANDA'},
{id: 923, label: 'SAN MIGUEL'},
{id: 924, label: 'SAN VICENTE DE CHUCURI'},
{id: 925, label: 'SANTA BARBARA'},
{id: 926, label: 'SANTA HELENA DEL OPON'},
{id: 927, label: 'SIMACOTA'},
{id: 928, label: 'SOCORRO'},
{id: 929, label: 'SUAITA'},
{id: 930, label: 'SUCRE'},
{id: 931, label: 'SURATA'},
{id: 932, label: 'TONA'},
{id: 933, label: 'VALLE SAN JOSE'},
{id: 934, label: 'VELEZ'},
{id: 935, label: 'VETAS'},
{id: 936, label: 'VILLANUEVA'},
{id: 937, label: 'ZAPATOCA'},
{id: 938, label: 'SINCELEJO'},
{id: 939, label: 'BUENAVISTA'},
{id: 940, label: 'CAIMITO'},
{id: 941, label: 'COLOSO (RICAURTE)'},
{id: 942, label: 'COROZAL'},
{id: 943, label: 'CHALAN'},
{id: 944, label: 'GALERAS (NUEVA GRANADA)'},
{id: 945, label: 'GUARANDA'},
{id: 946, label: 'LA UNION'},
{id: 947, label: 'LOS PALMITOS'},
{id: 948, label: 'MAJAGUAL'},
{id: 949, label: 'MORROA'},
{id: 950, label: 'OVEJAS'},
{id: 951, label: 'PALMITO'},
{id: 952, label: 'SAMPUES'},
{id: 953, label: 'SAN BENITO ABAD'},
{id: 954, label: 'SAN JUAN DE BETULIA'},
{id: 955, label: 'SAN MARCOS'},
{id: 956, label: 'SAN ONOFRE'},
{id: 957, label: 'SAN PEDRO'},
{id: 958, label: 'SINCE'},
{id: 959, label: 'SUCRE'},
{id: 960, label: 'TOLU'},
{id: 961, label: 'TOLUVIEJO'},
{id: 962, label: 'IBAGUE'},
{id: 963, label: 'ALPUJARRA'},
{id: 964, label: 'ALVARADO'},
{id: 965, label: 'AMBALEMA'},
{id: 966, label: 'ANZOATEGUI'},
{id: 967, label: 'ARMERO (GUAYABAL)'},
{id: 968, label: 'ATACO'},
{id: 969, label: 'CAJAMARCA'},
{id: 970, label: 'CARMEN APICALA'},
{id: 971, label: 'CASABIANCA'},
{id: 972, label: 'CHAPARRAL'},
{id: 973, label: 'COELLO'},
{id: 974, label: 'COYAIMA'},
{id: 975, label: 'CUNDAY'},
{id: 976, label: 'DOLORES'},
{id: 977, label: 'ESPINAL'},
{id: 978, label: 'FALAN'},
{id: 979, label: 'FLANDES'},
{id: 980, label: 'FRESNO'},
{id: 981, label: 'GUAMO'},
{id: 982, label: 'HERVEO'},
{id: 983, label: 'HONDA'},
{id: 984, label: 'ICONONZO'},
{id: 985, label: 'LERIDA'},
{id: 986, label: 'LIBANO'},
{id: 987, label: 'MARIQUITA'},
{id: 988, label: 'MELGAR'},
{id: 989, label: 'MURILLO'},
{id: 990, label: 'NATAGAIMA'},
{id: 991, label: 'ORTEGA'},
{id: 992, label: 'PALOCABILDO'},
{id: 993, label: 'PIEDRAS'},
{id: 994, label: 'PLANADAS'},
{id: 995, label: 'PRADO'},
{id: 996, label: 'PURIFICACION'},
{id: 997, label: 'RIOBLANCO'},
{id: 998, label: 'RONCESVALLES'},
{id: 999, label: 'ROVIRA'},
{id: 1000, label: 'SALDAÑA'},
{id: 1001, label: 'SAN ANTONIO'},
{id: 1002, label: 'SAN LUIS'},
{id: 1003, label: 'SANTA ISABEL'},
{id: 1004, label: 'SUAREZ'},
{id: 1005, label: 'VALLE DE SAN JUAN'},
{id: 1006, label: 'VENADILLO'},
{id: 1007, label: 'VILLAHERMOSA'},
{id: 1008, label: 'VILLARRICA'},
{id: 1009, label: 'CALI (SANTIAGO DE CALI)'},
{id: 1010, label: 'ALCALA'},
{id: 1011, label: 'ANDALUCIA'},
{id: 1012, label: 'ANSERMANUEVO'},
{id: 1013, label: 'ARGELIA'},
{id: 1014, label: 'BOLIVAR'},
{id: 1015, label: 'BUENAVENTURA'},
{id: 1016, label: 'BUGA'},
{id: 1017, label: 'BUGALAGRANDE'},
{id: 1018, label: 'CAICEDONIA'},
{id: 1019, label: 'CALIMA (DARIEN)'},
{id: 1020, label: 'CANDELARIA'},
{id: 1021, label: 'CARTAGO'},
{id: 1022, label: 'DAGUA'},
{id: 1023, label: 'EL AGUILA'},
{id: 1024, label: 'EL CAIRO'},
{id: 1025, label: 'EL CERRITO'},
{id: 1026, label: 'EL DOVIO'},
{id: 1027, label: 'FLORIDA'},
{id: 1028, label: 'GINEBRA'},
{id: 1029, label: 'GUACARI'},
{id: 1030, label: 'JAMUNDI'},
{id: 1031, label: 'LA CUMBRE'},
{id: 1032, label: 'LA UNION'},
{id: 1033, label: 'LA VICTORIA'},
{id: 1034, label: 'OBANDO'},
{id: 1035, label: 'PALMIRA'},
{id: 1036, label: 'PRADERA'},
{id: 1037, label: 'RESTREPO'},
{id: 1038, label: 'RIOFRIO'},
{id: 1039, label: 'ROLDANILLO'},
{id: 1040, label: 'SAN PEDRO'},
{id: 1041, label: 'SEVILLA'},
{id: 1042, label: 'TORO'},
{id: 1043, label: 'TRUJILLO'},
{id: 1044, label: 'TULUA'},
{id: 1045, label: 'ULLOA'},
{id: 1046, label: 'VERSALLES'},
{id: 1047, label: 'VIJES'},
{id: 1048, label: 'YOTOCO'},
{id: 1049, label: 'YUMBO'},
{id: 1050, label: 'ZARZAL'},
{id: 1051, label: 'ARAUCA'},
{id: 1052, label: 'ARAUQUITA'},
{id: 1053, label: 'CRAVO NORTE'},
{id: 1054, label: 'FORTUL'},
{id: 1055, label: 'PUERTO RONDON'},
{id: 1056, label: 'SARAVENA'},
{id: 1057, label: 'TAME'},
{id: 1058, label: 'YOPAL'},
{id: 1059, label: 'AGUAZUL'},
{id: 1060, label: 'CHAMEZA'},
{id: 1061, label: 'HATO COROZAL'},
{id: 1062, label: 'LA SALINA'},
{id: 1063, label: 'MANI'},
{id: 1064, label: 'MONTERREY'},
{id: 1065, label: 'NUNCHIA'},
{id: 1066, label: 'OROCUE'},
{id: 1067, label: 'PAZ DE ARIPORO'},
{id: 1068, label: 'PORE'},
{id: 1069, label: 'RECETOR'},
{id: 1070, label: 'SABANALARGA'},
{id: 1071, label: 'SACAMA'},
{id: 1072, label: 'SAN LUIS DE PALENQUE'},
{id: 1073, label: 'TAMARA'},
{id: 1074, label: 'TAURAMENA'},
{id: 1075, label: 'TRINIDAD'},
{id: 1076, label: 'VILLANUEVA'},
{id: 1077, label: 'MOCOA'},
{id: 1078, label: 'COLON'},
{id: 1079, label: 'ORITO'},
{id: 1080, label: 'PUERTO ASIS'},
{id: 1081, label: 'PUERTO CAICEDO'},
{id: 1082, label: 'PUERTO GUZMAN'},
{id: 1083, label: 'PUERTO LEGUIZAMO'},
{id: 1084, label: 'SIBUNDOY'},
{id: 1085, label: 'SAN FRANCISCO'},
{id: 1086, label: 'SAN MIGUEL (LA DORADA)'},
{id: 1087, label: 'SANTIAGO'},
{id: 1088, label: 'LA HORMIGA (VALLE DEL GUAMUEZ)'},
{id: 1089, label: 'VILLAGARZON'},
{id: 1090, label: 'SAN ANDRES'},
{id: 1091, label: 'PROVIDENCIA'},
{id: 1092, label: 'LETICIA'},
{id: 1093, label: 'EL ENCANTO'},
{id: 1094, label: 'LA CHORRERA'},
{id: 1095, label: 'LA PEDRERA'},
{id: 1096, label: 'LA VICTORIA'},
{id: 1097, label: 'MIRITI-PARANA'},
{id: 1098, label: 'PUERTO ALEGRIA'},
{id: 1099, label: 'PUERTO ARICA'},
{id: 1100, label: 'PUERTO NARIÑO'},
{id: 1101, label: 'PUERTO SANTANDER'},
{id: 1102, label: 'TARAPACA'},
{id: 1103, label: 'PUERTO INIRIDA'},
{id: 1104, label: 'BARRANCO MINAS'},
{id: 1105, label: 'SAN FELIPE'},
{id: 1106, label: 'PUERTO COLOMBIA'},
{id: 1107, label: 'LA GUADALUPE'},
{id: 1108, label: 'CACAHUAL'},
{id: 1109, label: 'PANA PANA (CAMPO ALEGRE)'},
{id: 1110, label: 'MORICHAL (MORICHAL NUEVO)'},
{id: 1111, label: 'SAN JOSE DEL GUAVIARE'},
{id: 1112, label: 'CALAMAR'},
{id: 1113, label: 'EL RETORNO'},
{id: 1114, label: 'MIRAFLORES'},
{id: 1115, label: 'MITU'},
{id: 1116, label: 'CARURU'},
{id: 1117, label: 'PACOA'},
{id: 1118, label: 'TARAIRA'},
{id: 1119, label: 'PAPUNAUA (MORICHAL)'},
{id: 1120, label: 'YAVARATE'},
{id: 1121, label: 'PUERTO CARREÑO'},
{id: 1122, label: 'LA PRIMAVERA'},
{id: 1123, label: 'SANTA RITA'},
{id: 1124, label: 'SANTA ROSALIA'},
{id: 1125, label: 'SAN JOSE DE OCUNE'},
{id: 1126, label: 'CUMARIBO'},
{id: 1128, label: 'FLORIDABLANCA '},
{id: 1129, label: 'BARCELONA '},
{id: 1131, label: 'SANTANDER'},
{id: 1133, label: 'Meta'},
{id: 1134, label: 'EL HUILA'},
{id: 1127, label: 'Caracas'},
{id: 1132, label: 'SANTA ELENA'},
{id: 1135, label: 'TOLIMA '},
{id: 1138, label: 'EXTRANJERO'},
{id: 1140, label: 'Campeche, Atlantico'},
{id: 1142, label: 'Subia'},
{id: 1143, label: 'España'},
{id: 1145, label: 'Londres'},
{id: 1146, label: 'ARGENTINA '},
{id: 1147, label: 'QUITO'},
{id: 1148, label: 'Quito'},
{id: 1149, label: 'Panamá'},
{id: 1150, label: 'GUAYAQUIL'},
{id: 1151, label: 'Pizarro'},
{id: 1152, label: 'Santiago de Veraguas'},
{id: 1154, label: 'México '},
{id: 1155, label: 'Mysore'},
{id: 1156, label: 'Montreal'},
{id: 1157, label: 'MIAMI'},
{id: 1158, label: 'Estados Unidos'},
{id: 1159, label: 'El Tigre'},
{id: 1160, label: 'Lima'},
{id: 10001, label: 'Ciudad de México'},
{id: 1130, label: 'Guajira'},
{id: 1161, label: 'Currulao (Turbo)'},
{id: 1162, label: 'Ciudad de México'},
{id: 1136, label: 'Brasilia'},
{id: 1137, label: 'San Andres '},
{id: 1164, label: 'Valparaiso'},
{id: 1165, label: 'Coveñas'},
{id: 1139, label: 'Santa fe de Antioquia'},
{id: 1166, label: 'Media Luna'},
{id: 1141, label: 'CUNDINAMARCA'},
{id: 1163, label: 'BRASILIA'},
{id: 1144, label: 'RIOACHA'},
{id: 1153, label: 'CHIRIQUÍ / BARÚ '},
      ],
      themeColors: ['#7367F0', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E'],
      lineAreaChartSpline: {
        series: [{
            name: 'Confiable',
            data: [100, 200, 300, 400, 500, 600, 700]
          }, {
            name: 'No confiable',
            data: [50, 100, 150, 200, 250, 300, 350]
          },
          {
            name: 'Confiable con observaciones',
            data: [10, 60, 110, 160, 210, 260, 310]
          }          
        ],
        chartOptions: {
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },
          colors: this.themeColors,
          xaxis: {
            type: 'datetime',
            categories: ["2018-09-19T00:00:00", "2018-09-19T01:30:00", "2018-09-19T02:30:00",
              "2018-09-19T03:30:00", "2018-09-19T04:30:00", "2018-09-19T05:30:00",
              "2018-09-19T06:30:00"
            ],
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy HH:mm'
            },

          }
        }
      }            
    }
  },
  methods:{
    submitForm() {
      this.$validator.validateAll().then(result => {        
        if(result) {
          var user_id = this.userInfo.user.id
          var start_date = this.convertDate(this.form.dateStart.toString());
          var end_date = this.convertDate(this.form.dateEnd.toString());
          var parameters = "?start_date=" + start_date + "&end_date=" + end_date + "&current_user=" + user_id
          var path = "/api/v1/reports/clients_range_chart" + parameters
          this.getData(path)
        }else{
          this.$vs.notify({color:'danger',title:'Error',text:'Todos los campos son necesarios', position: 'bottom-left'})      
        }
      })
    },
    getData(path){
      this.requestGetWithoutContent(this.url()+path, {}).then(response => {
        if(response.data.success == true){
          this.lineAreaChartSpline.series = response.data.series
          this.lineAreaChartSpline.series[0].data = response.data.series[0].data
          this.lineAreaChartSpline.series[1].data = response.data.series[1].data
          this.lineAreaChartSpline.series[2].data = response.data.series[2].data
          this.startDate = response.data.start_date
          this.endDate = response.data.end_date
          // //console.log("this.lineAreaChartSpline")
          // //console.log(JSON.stringify(this.lineAreaChartSpline));
          // this.totalCount = response.data.total_count
          this.$vs.notify({color:'success',title:'Obteniendo información',text:'Armando gráfico', position: 'bottom-left'})      
        }else{
          //console.log("fail")
        }
      }, (err) => {
        console.log("err -> " + err);
      })          
    },
    convertDate(date){
      //console.log("d", date)
      var parts = date.split(" ");
      var months = {Jan: "01",Feb: "02",Mar: "03",Apr: "04",May: "05",Jun: "06",Jul: "07",Aug: "08",Sep: "09",Oct: "10",Nov: "11",Dec: "12"};
      return parts[3]+"-"+months[parts[1]]+"-"+parts[2];
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  created: function(){
    var user_id = this.userInfo.user.id    
    var parameters = "?current_user=" + user_id
    var path = "/api/v1/reports/clients_range_chart" + parameters    
    this.getData(path)
  },  
  components: {
    VueApexCharts,
    Datepicker,
    vSelect
  }
}
</script>
<style>
  .vdp-datepicker input{
    width: 100%
  }
</style>

