<template>
  <div class="search-container">
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/1 mb-base">
        <h2 class="search-container-title">Seguimiento rápido</h2>
        <p class="search-container-text">Busca la trazabilidad de tus procesos y visualiza las últimas observaciones.</p>
        <vs-input class="w-full" shadow v-model="inputSearch" placeholder="Escribe aquí nombre, número de documento o ID">
          <!-- <feather-icon
            class="sm:inline-flex xl:hidden cursor-pointer mr-1"
            icon="MenuIcon"
          ></feather-icon> -->
        </vs-input>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '../../mixins/api.js'
import { mapMutations } from 'vuex'

export default ({
  mixins: [api],
  emits: ['refreshPage', 'updateLoading'],
  props: ['page'],

  data() {
    return {
      inputSearch:'',
      path: '',
      perPage: 7,
      loadingSearch: null,
      timer: undefined
    }
  },
  computed:{
    projects:{
      get(){
        return this.$store.state.fastTrackingProjects
      },
      set(val){
        this.$store.dispatch("updateFastTrackingProjects", val);
      }
    },
    projectsCount:{
      get(){
        return this.$store.state.fastTrackingProjectsCount
      },
      set(val){
        this.$store.dispatch("updateFastTrackingProjectsCount", val);
      }
    }
  },
  watch: {
    inputSearch(){
      this.getFastTrackingProjects()
      this.$emit("refreshPage");
    },
    page(){
      this.getFastTrackingProjects()
    },
    loadingSearch: {
      handler: function(newVal, oldVal) { // watch it
        this.$emit("updateLoading", newVal);
      },
      deep: true
    }
  },

  methods: {
    ...mapMutations(["UPDATE_FAST_TRACKING_PROJECTS", "UPDATE_FAST_TRACKING_PROJECTS_COUNT"]),
    ...mapGetters(['fastTrackingProjects', 'fastTrackingProjectsCount']),

    getFastTrackingProjects() {
      clearTimeout(this.timer)

      this.timer = setTimeout(() => {
        this.loadingSearch = true
        if (this.inputSearch.length > 2 || this.inputSearch.length == 0) {
          this.path = "/api/v1/projects/fast_tracking?page="+this.page+"&limit="+this.perPage+"&query="+this.inputSearch
          this.requestGetWithoutContent(this.url()+this.path, {}).then(response => {
            if(response.data.success == true){
              this.projects = response.data.projects
              this.projectsCount = Math.ceil(response.data.projects_count/7)
            }else{
              console.log("error -> " + response.data.message);
            }
            this.loadingSearch = false
          }, (err) => {
            console.log('err users -> ' +  JSON.stringify(err))
          })
        }
      }, 200)

    }
  },
  created() {
    this.loadingSearch = null
    this.projects = this.getFastTrackingProjects()
  }
})
</script>

<style lang="scss">
.search-container{
  /* Auto layout */
  padding: 24px 18px;
  gap: 8px;
  height: 150px;
  /* Morado traslucido */
  background: #F2EDFC;
  border-radius: 16px;

  &-title{
    /* Título H5 */

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;

    /* Morado Globalwork */
    color: #5F008F;
  }

  &-text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    margin: 10px 0px;

    /* Morado Dark */
    color: #3C1A4E;
  }

  &-bar{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;
    gap: 16px;

    width: 1346px;
    height: 48px;

    /* Blanco */
    background: #FFFFFF;
    border-radius: 8px;

    flex: none;
    order: 0;
    flex-grow: 0;
  }
}
</style>
