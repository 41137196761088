<template>
  <div class="list-traceability">
    <div class="events-traceability" v-for="(event, index) in events" :key="event.event_type">      
      <template v-if="index == 0">
        <h4 class="title-colum-event" v-if="event.childrens.documents.length > 0 || event.childrens.references.length > 0">{{event.event_type}}</h4>
        <RenderChildrensDocuments :childrens="event.childrens.documents" :img="img" />
        <RenderChildrensReferences :childrens="event.childrens.references" :img="img" />
      </template>
      <template v-else>
        <h4 class="title-colum-event" v-if="event.childrens.length > 0">{{event.event_type}}</h4>
        <RenderChildrensRequestResponse :childrens="event.childrens" :img="img" />
      </template>
    </div>
  </div>
</template>

<script>
  import RenderChildrensDocuments from './RenderChildrensDocuments.vue'
  import RenderChildrensReferences from './RenderChildrensReferences.vue'
  import RenderChildrensRequestResponse from './RenderChildrensRequestResponse.vue'  
  export default {
    props: {
      events: null,
      img: null
    },
    components: {
      RenderChildrensDocuments,
      RenderChildrensReferences,
      RenderChildrensRequestResponse
    },
  }
</script>
