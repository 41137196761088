<template>
  <div class="notifications">
    <vx-card title="Notificaciones">
      <ul class="activity-timeline" v-for="(notification, index_notification) in notifications" :key="index_notification">
        <li>
          <div v-bind:class="classTimeLines[notification.type_id]">
            <feather-icon v-bind:icon="icons[notification.type_id]" svgClasses="text-white stroke-current w-5 h-5"></feather-icon>
          </div>
          <div class="timeline-info">
            <p class="font-semibold">{{ notification.title }}</p>
            <span class="activity-desc">{{ notification.content }}</span>
          </div>
          <small class="text-grey activity-e-time">{{convertTimeStamp(notification.timestamp)}}</small>
        </li>
      </ul>
    </vx-card>    
  </div>
</template>
<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import api from '../mixins/api'
import moment from 'moment'
import 'moment/locale/es'  // without this line it didn't work
import { mapGetters } from 'vuex'
moment.locale('es')
export default {
  mixins: [api],
  data(){
    return{
      // 0 Proceso iniciado
      // 1 Novedad en proceso
      // 2 Proceso finalizado
      // 3 Documento falso detectado
      // 4 Noverdad en fiscalia
      icons: ['PlayIcon', 'BellIcon', 'CheckIcon', 'AlertCircleIcon', 'FrownIcon'],
      classTimeLines: ['timeline-icon bg-primary', 'timeline-icon bg-dark', 'timeline-icon bg-success', 'timeline-icon bg-warning', 'timeline-icon bg-danger'],
      notifications: []
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },  
  methods:{
    convertTimeStamp(timestamp){
      var date = new Date(timestamp * 1000);
      return moment(date, "YYYYMMDD").fromNow();
    }    
  },
  created: function(){
    var client_id = this.userInfo.user.client_id
    console.log("client_id -> " + client_id)
    var path = "/api/v1/clients/notifications?client_id="+client_id;
    this.requestGetWithoutContent(this.url()+path, {}).then(response => {
      ////console.log('response -> ' + response);
      if(response.data.success == true){
        this.notifications = response.data.data
      }else{
        console.log(response.data.message);          
      }
    }, (err) => {
      console.log('err -> ' +  err)
    })  
  },
  watch: {

  },  
  components: {
    StatisticsCardLine
  }
}
</script>
<style>

</style>


