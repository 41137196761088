var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "line-area-chart" },
    [
      _c(
        "vx-card",
        {
          attrs: {
            title:
              "Resúmen de procesos. Período de tiempo: " +
              _vm.startDate +
              " - " +
              _vm.endDate,
          },
        },
        [
          _c(
            "form",
            [
              _c("vue-apex-charts", {
                attrs: {
                  type: "area",
                  height: "350",
                  options: _vm.lineAreaChartSpline.chartOptions,
                  series: _vm.lineAreaChartSpline.series,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }