var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.date != null
    ? _c("div", [
        _vm._v("\n  " + _vm._s(_vm._f("formatDate")(_vm.date)) + "\n"),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }