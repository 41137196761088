var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ProjectHeaderPreliminary", { attrs: { project: _vm.project } }),
      _c("br"),
      _c("ProjectObservationsFormMissing", { attrs: { project: _vm.project } }),
      _c("br"),
      _c(
        "vx-card",
        [
          _c(
            "vs-button",
            {
              staticStyle: { background: "#640064" },
              attrs: { type: "gradient", href: { url: "/project" } },
            },
            [_vm._v("Ver resultado")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }