<template>
  <div class="vx-col w-full lg:w-1/1 mb-base cont-traceability">
    <div class="container-traceability">
      <div class="vx-row">
        <Column :records="request_process" :type="eventTypes.filter( eventType => eventType.name == 'request_process')[0]" />
        <Column :records="information_completed" :type="eventTypes.filter( eventType => eventType.name == 'information_completed')[0]" />
        <Column :records="request_validations" :type="eventTypes.filter( eventType => eventType.name == 'request_validations')[0]" />
        <Column :records="verifications_response" :type="eventTypes.filter( eventType => eventType.name == 'verifications_response')[0]" />
        <Column :records="project_finished" :type="eventTypes.filter( eventType => eventType.name == 'project_finished')[0]" />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Column from './Column.vue'
  export default {
    computed: {
      ...mapGetters(['request_process', 'information_completed', 'request_validations', 'verifications_response', 'project_finished', 'eventTypes'])
    },
    components: {
      Column
    }
  }
</script>

<style scoped>

</style>