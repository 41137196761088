<template>
  <div>
    <vx-card>        
      <div class="text-center">
        <h4>Pendiente por</h4>
      </div>
      <vs-tabs v-if="Object.keys(project.observations).length > 0">
        <vs-tab v-bind:label="'Equipo ' + observations[0].type" v-for="(observations, index_observation) in formsMissing()" :key="index_observation">
          <vs-table noDataText="">
            <template slot="header">
            </template>
            <template slot="thead">
              <vs-th>
                Fecha
              </vs-th>
              <vs-th>
                Observación
              </vs-th>
            </template>

            <template>
              <vs-tr v-for="(observation, index_observation) in observations" :key="index_observation" >
                <vs-td :data="observation.created_at"> 
                  {{ humanizeDate(observation.created_at) }}                 
                </vs-td>

                <vs-td :data="observation.created_at">
                  {{observation.observation}}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>          
        </vs-tab>
      </vs-tabs> 
    </vx-card>                  
  </div>
</template>

<script>
export default {
  props: {
    project: Object
  },
  data (){
    return{

    }
  },
  methods: {
    formsMissing(){      
      var forms = []

      this.project.percentage.missing_processes.forEach(missing_proccess => {
        this.project.observations.forEach(projectObservation => {
          if(projectObservation[0].type == missing_proccess){
            forms.push(projectObservation)
          }          
        });
      });
      return forms
    },
    humanizeDate(date){
      var arrayDate = date.split("T")
      var stringDate = arrayDate[0]
      var hour = arrayDate[1].split(".")[0]
      return stringDate + ", " + hour
    },
    chunk(array, size) {
      const chunked_arr = [];      
      if(array != null){
        for (let i = 0; i < array.length; i++) {
          const last = chunked_arr[chunked_arr.length - 1];
          if (!last || last.length === size) {
            chunked_arr.push([array[i]]);
          } else {
            last.push(array[i]);
          }
        }
      }
      return chunked_arr;
    }
  },
  created(){

  },
  mounted(){

  },
  components:{

  }
}
</script>

<style>

</style>