var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "search-container" }, [
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full lg:w-1/1 mb-base" },
        [
          _c("h2", { staticClass: "search-container-title" }, [
            _vm._v("Seguimiento rápido"),
          ]),
          _c("p", { staticClass: "search-container-text" }, [
            _vm._v(
              "Busca la trazabilidad de tus procesos y visualiza las últimas observaciones."
            ),
          ]),
          _c("vs-input", {
            staticClass: "w-full",
            attrs: {
              shadow: "",
              placeholder: "Escribe aquí nombre, número de documento o ID",
            },
            model: {
              value: _vm.inputSearch,
              callback: function ($$v) {
                _vm.inputSearch = $$v
              },
              expression: "inputSearch",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }