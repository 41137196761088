var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { attrs: { id: "popupsProject" } }),
    _c(
      "div",
      { attrs: { id: "search-bar-section" } },
      [
        _c("search-bar", {
          attrs: { page: this.currentPage },
          on: {
            refreshPage: function ($event) {
              return _vm.setPage()
            },
            updateLoading: _vm.loadingState,
          },
        }),
      ],
      1
    ),
    _vm.loading
      ? _c(
          "div",
          _vm._l(7, function (index) {
            return _c("div", { key: index }, [
              _c(
                "div",
                { staticClass: "process-detail-container" },
                [
                  _c(
                    "vs-row",
                    [
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-w": "3",
                            "vs-type": "flex",
                            "vs-justify": "center",
                            "vs-align": "center",
                          },
                        },
                        [
                          _c("vs-col", { attrs: { "vs-w": "4" } }, [
                            _c("div", {
                              staticClass: "skeleton skeleton-circle-border",
                            }),
                            _c("p", { staticClass: "skeleton skeleton-text" }),
                          ]),
                          _c("vs-col", { attrs: { "vs-w": "8" } }, [
                            _c("p", { staticClass: "skeleton skeleton-title" }),
                            _c("p", { staticClass: "skeleton skeleton-text" }),
                            _c("p", {
                              staticClass: "skeleton skeleton-text-60",
                            }),
                            _c("p", {
                              staticClass: "skeleton skeleton-text-60",
                            }),
                            _c("p", {
                              staticClass: "skeleton skeleton-text-20",
                            }),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-w": "6",
                            "vs-type": "flex",
                            "vs-justify": "start",
                            "vs-align": "center",
                          },
                        },
                        [
                          _c(
                            "vs-row",
                            {
                              staticClass: "process-detail-container-border",
                              attrs: { direction: "column" },
                            },
                            [
                              _c("vs-col", [
                                _c("p", {
                                  staticClass: "skeleton skeleton-subtitle-40",
                                }),
                              ]),
                              _c(
                                "vs-col",
                                [
                                  _c(
                                    "vs-row",
                                    { attrs: { direction: "row" } },
                                    [
                                      _c(
                                        "vs-col",
                                        {
                                          attrs: {
                                            "vs-w": "10",
                                            "vs-type": "flex",
                                            "vs-justify": "start",
                                            "vs-align": "center",
                                          },
                                        },
                                        _vm._l(5, function (index) {
                                          return _c("div", {
                                            key: index,
                                            staticClass:
                                              "skeleton skeleton-circle",
                                          })
                                        }),
                                        0
                                      ),
                                      _c(
                                        "vs-col",
                                        {
                                          attrs: {
                                            "vs-w": "2",
                                            "vs-type": "flex",
                                            "vs-justify": "end",
                                            "vs-align": "center",
                                          },
                                        },
                                        [
                                          _c("p", {
                                            staticClass:
                                              "skeleton skeleton-text-60",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-w": "2",
                            "vs-type": "flex",
                            "vs-justify": "center",
                            "vs-align": "center",
                          },
                        },
                        [
                          _c(
                            "vs-row",
                            { attrs: { direction: "column" } },
                            [
                              _c("vs-col", [
                                _c("p", {
                                  staticClass: "skeleton skeleton-subtitle-40",
                                }),
                              ]),
                              _c("vs-col", [
                                _c("div", {
                                  staticClass: "skeleton skeleton-circle",
                                }),
                              ]),
                              _c("vs-col", [
                                _c("div", [
                                  _c("img", {
                                    staticClass: "skeleton-star",
                                    attrs: {
                                      src: require("../../assets/images/fast-tracking/utils/stars/star-loader.png"),
                                    },
                                  }),
                                  _c("img", {
                                    staticClass: "skeleton-star",
                                    attrs: {
                                      src: require("../../assets/images/fast-tracking/utils/stars/star-loader.png"),
                                    },
                                  }),
                                  _c("img", {
                                    staticClass: "skeleton-star",
                                    attrs: {
                                      src: require("../../assets/images/fast-tracking/utils/stars/star-loader.png"),
                                    },
                                  }),
                                  _c("img", {
                                    staticClass: "skeleton-star",
                                    attrs: {
                                      src: require("../../assets/images/fast-tracking/utils/stars/star-loader.png"),
                                    },
                                  }),
                                  _c("img", {
                                    staticClass: "skeleton-star",
                                    attrs: {
                                      src: require("../../assets/images/fast-tracking/utils/stars/star-loader.png"),
                                    },
                                  }),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-w": "1",
                            "vs-type": "flex",
                            "vs-justify": "end",
                            "vs-align": "center",
                          },
                        },
                        [_c("div", { staticClass: "skeleton skeleton-circle" })]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          }),
          0
        )
      : _c("div", [
          _vm.fastTracking.length > 0
            ? _c(
                "div",
                _vm._l(_vm.fastTracking, function (project, projectIndex) {
                  return _c("div", { key: project.id }, [
                    _c(
                      "div",
                      { staticClass: "process-detail-container" },
                      [
                        _c(
                          "vs-row",
                          [
                            _c(
                              "vs-col",
                              {
                                staticStyle: { padding: "0px" },
                                attrs: {
                                  "vs-w": "3",
                                  "vs-type": "flex",
                                  "vs-justify": "center",
                                  "vs-align": "center",
                                },
                              },
                              [
                                _c(
                                  "vs-col",
                                  { attrs: { "vs-w": "4" } },
                                  [
                                    _c(
                                      "vs-row",
                                      {
                                        staticStyle: { padding: "0px" },
                                        attrs: {
                                          "vs-type": "flex",
                                          "vs-justify": "center",
                                          "vs-align": "center",
                                          "vs-direction": "column",
                                        },
                                      },
                                      [
                                        _c(
                                          "vs-col",
                                          {
                                            staticStyle: { padding: "0px" },
                                            attrs: { "vs-w": "12" },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "icon-percentage legendClass",
                                              },
                                              [
                                                _c(
                                                  "vue-ellipse-progress",
                                                  {
                                                    attrs: {
                                                      progress:
                                                        project.fihished_validations_rate,
                                                      size: 75,
                                                      thickness: 4,
                                                      color: "#5F008F",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        attrs: {
                                                          slot: "legend-value",
                                                        },
                                                        slot: "legend-value",
                                                      },
                                                      [_vm._v("%")]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "icon-popup" },
                                              [_vm._v("Porcentaje de avance")]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "vs-col",
                                          {
                                            staticStyle: { padding: "0px" },
                                            attrs: { "vs-w": "12" },
                                          },
                                          [
                                            _c("div", [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "process-detail-container-text process-detail-container-validation-rate",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      project.fihished_validations
                                                    ) +
                                                      "/" +
                                                      _vm._s(
                                                        project.total_validations
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("vs-col", { attrs: { "vs-w": "8" } }, [
                                  _c(
                                    "h5",
                                    {
                                      staticClass:
                                        "process-detail-container-title",
                                    },
                                    [_vm._v(_vm._s(project.person_full_name))]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "process-detail-container-text",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(project.project_type_name) +
                                          " " +
                                          _vm._s(project.polygraphy_type) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "process-detail-container-text",
                                    },
                                    [_vm._v(_vm._s(project.city_name))]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "process-detail-container-text",
                                    },
                                    [_vm._v(_vm._s(project.person_id))]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "process-detail-container-text-bold",
                                    },
                                    [_vm._v("ID " + _vm._s(project.id))]
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c("vs-col", { attrs: { "vs-w": "6" } }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "process-detail-container-border",
                                },
                                [
                                  _c(
                                    "h5",
                                    {
                                      staticClass:
                                        "process-detail-container-title",
                                    },
                                    [_vm._v("Observaciones")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "vx-row" },
                                    [
                                      _vm._l(
                                        project.validations,
                                        function (validation) {
                                          return _c(
                                            "div",
                                            {
                                              key: validation.id,
                                              staticStyle: {
                                                padding: "0px 10px",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "icon-container",
                                                  attrs: {
                                                    id:
                                                      "con-" +
                                                      project.id +
                                                      "-" +
                                                      validation.icon_name,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.displayObservations(
                                                        [validation],
                                                        validation.icon_name,
                                                        project
                                                      ),
                                                        _vm.updateObservationRead(
                                                          project.id,
                                                          validation,
                                                          projectIndex
                                                        ),
                                                        _vm.removeBadge(
                                                          project.id,
                                                          validation.icon_name
                                                        ),
                                                        _vm.updatePilaNotificationRead(
                                                          validation
                                                        )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm.unseen_notifications(
                                                    validation
                                                  ) > 0
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "unread-observations",
                                                          attrs: {
                                                            id:
                                                              "obs-" +
                                                              project.id +
                                                              "-" +
                                                              validation.icon_name,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.unseen_notifications(
                                                                validation
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c("img", {
                                                    staticClass:
                                                      "icon-circle-background",
                                                    attrs: {
                                                      src: _vm.icon_url_validation(
                                                        validation.icon_name,
                                                        project.remote
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "icon-popup" },
                                                [
                                                  _vm._v(
                                                    _vm._s(validation.name)
                                                  ),
                                                ]
                                              ),
                                              validation.done
                                                ? _c("div", [
                                                    _c("img", {
                                                      staticClass: "icon-check",
                                                      attrs: {
                                                        src: _vm.icon_url_check(
                                                          validation.qualification
                                                        ),
                                                      },
                                                    }),
                                                    validation.qualification !=
                                                    null
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "icon-popup",
                                                          },
                                                          [
                                                            _c("img", {
                                                              staticClass:
                                                                "icon-star",
                                                              attrs: {
                                                                src: _vm.icon_url_star(
                                                                  validation.qualification
                                                                ),
                                                              },
                                                            }),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          )
                                        }
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "process-detail-container-title process-detail-container-show-all",
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  _vm.displayObservations(
                                                    project.validations,
                                                    "todos",
                                                    project
                                                  ),
                                                    _vm.updateAllObservationRead(
                                                      project
                                                    )
                                                },
                                              },
                                            },
                                            [_vm._v("Ver todas")]
                                          ),
                                        ]
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "vs-col",
                              {
                                attrs: {
                                  "vs-w": "2",
                                  "vs-type": "flex",
                                  "vs-justify": "start",
                                  "vs-align": "center",
                                },
                              },
                              [
                                _c("div", [
                                  project.finished
                                    ? _c("div", [
                                        project.overall_final_name ===
                                        "Confiable"
                                          ? _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "process-detail-container-title-trust",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    project.overall_final_name
                                                  )
                                                ),
                                              ]
                                            )
                                          : project.overall_final_name ===
                                            "Confiable Con Observaciones"
                                          ? _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "process-detail-container-title-unreliable",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    project.overall_final_name
                                                  )
                                                ),
                                              ]
                                            )
                                          : project.overall_final_name ===
                                            "Finalizado Con Restricciones"
                                          ? _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "process-detail-container-title-restriction",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    project.overall_final_name
                                                  )
                                                ),
                                              ]
                                            )
                                          : project.overall_final_name ===
                                            "No Confiable"
                                          ? _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "process-detail-container-title-untrust",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    project.overall_final_name
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c("div", [
                                          _c("img", {
                                            staticClass: "icon-face",
                                            attrs: {
                                              src: _vm.icon_url_face(
                                                project.overall_final_name
                                              ),
                                            },
                                          }),
                                        ]),
                                        project.overall_general > 0 &&
                                        project.overall_general < 6
                                          ? _c("div", [
                                              _c("img", {
                                                staticClass: "icon-star",
                                                attrs: {
                                                  src: _vm.star_color_concept(
                                                    project.overall_final_name,
                                                    project.overall_general
                                                  ),
                                                },
                                              }),
                                            ])
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                            _c(
                              "vs-col",
                              {
                                attrs: {
                                  "vs-w": "1",
                                  "vs-type": "flex",
                                  "vs-justify": "start",
                                  "vs-align": "center",
                                },
                              },
                              [
                                _c(
                                  "vs-button",
                                  {
                                    staticClass: "icon-container",
                                    staticStyle: {
                                      background: "white !important",
                                      padding: "0px !important",
                                    },
                                    attrs: { href: { url: "/project" } },
                                    on: {
                                      click: function ($event) {
                                        return _vm.getProject(project)
                                      },
                                    },
                                  },
                                  [
                                    _c("chevron-right-icon", {
                                      staticClass: "custom-class",
                                      attrs: { size: "1.5x" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "vs-row",
                          [
                            _c("vs-col", { attrs: { "vs-w": "3" } }),
                            _c("vs-col", { attrs: { "vs-w": "6" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "vx-row",
                                  staticStyle: { padding: "0px 10px" },
                                },
                                [
                                  _c(
                                    "transition",
                                    { attrs: { name: "slide" } },
                                    [
                                      _vm.currentProject.id == project.id &&
                                      _vm.toggleObservations
                                        ? _c(
                                            "div",
                                            { staticClass: "w-full" },
                                            _vm._l(
                                              _vm.currentValidations,
                                              function (currentValidation) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: currentValidation.id,
                                                    staticClass: "w-full",
                                                  },
                                                  [
                                                    currentValidation
                                                      .observations.length > 0
                                                      ? _c(
                                                          "div",
                                                          _vm._l(
                                                            currentValidation.observations,
                                                            function (
                                                              observation,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key: observation.id,
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "observation-card",
                                                                    },
                                                                    [
                                                                      index ===
                                                                      0
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "vx-row",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "vs-col",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      "vs-type":
                                                                                        "flex",
                                                                                      "vs-justify":
                                                                                        "start",
                                                                                      "vs-align":
                                                                                        "center",
                                                                                      w: "12",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "img",
                                                                                    {
                                                                                      staticClass:
                                                                                        "icon-obs",
                                                                                      attrs:
                                                                                        {
                                                                                          src: _vm.icon_url_validation(
                                                                                            currentValidation.icon_name,
                                                                                            project.remote
                                                                                          ),
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "process-detail-container-title",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          currentValidation.name
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "observation-text",
                                                                          domProps:
                                                                            {
                                                                              innerHTML:
                                                                                _vm._s(
                                                                                  observation.body
                                                                                ),
                                                                            },
                                                                        }
                                                                      ),
                                                                      observation.improved_with_ia
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "observation-text-ia",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "Texto mejorado con IA"
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "observation-timestamp",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "observation-timestamp-time",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.timestampFormat(
                                                                                    observation.created_at,
                                                                                    "time"
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "observation-timestamp-date",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.timestampFormat(
                                                                                    observation.created_at,
                                                                                    "date"
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        )
                                                      : _c("div", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "observation-card",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "vx-row",
                                                                },
                                                                [
                                                                  _c(
                                                                    "vs-col",
                                                                    {
                                                                      attrs: {
                                                                        "vs-type":
                                                                          "flex",
                                                                        "vs-justify":
                                                                          "start",
                                                                        "vs-align":
                                                                          "center",
                                                                        w: "12",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          staticClass:
                                                                            "icon-obs",
                                                                          attrs:
                                                                            {
                                                                              src: _vm.icon_url_validation(
                                                                                currentValidation.icon_name,
                                                                                project.remote
                                                                              ),
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "process-detail-container-title",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              currentValidation.name
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "observation-text",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "No hay observaciones en esta verificación."
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                    currentValidation.extra !==
                                                    null
                                                      ? _c("div", [
                                                          currentValidation
                                                            .extra
                                                            .connection_succeed
                                                            ? _c("div", [
                                                                currentValidation
                                                                  .extra
                                                                  .has_pila_periods
                                                                  ? _c("div", [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "observation-card",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "vx-row",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "vs-col",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      "vs-type":
                                                                                        "flex",
                                                                                      "vs-justify":
                                                                                        "start",
                                                                                      "vs-align":
                                                                                        "center",
                                                                                      w: "12",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "img",
                                                                                    {
                                                                                      staticClass:
                                                                                        "icon-obs",
                                                                                      attrs:
                                                                                        {
                                                                                          src: _vm.icon_url_validation(
                                                                                            currentValidation.icon_name,
                                                                                            project.remote
                                                                                          ),
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "process-detail-container-title",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Historico laboral"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "observation-text",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    "Se ha obtenido información adicional confiable sobre el historial laboral del candidato\n                                        "
                                                                                  ),
                                                                                  _c(
                                                                                    "strong",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "durante los últimos 36 meses, correspondiente al período de recopilación de datos de PILA."
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "observation-timestamp",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "observation-timestamp-time",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.timestampFormat(
                                                                                        currentValidation
                                                                                          .extra
                                                                                          .time,
                                                                                        "time"
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "observation-timestamp-date",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.timestampFormat(
                                                                                        currentValidation
                                                                                          .extra
                                                                                          .time,
                                                                                        "date"
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "observation-card",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "observation-text",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    "Tras revisar en la "
                                                                                  ),
                                                                                  _c(
                                                                                    "strong",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "base de datos PILA de enlace operativo"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " encontramos que "
                                                                                  ),
                                                                                  _c(
                                                                                    "strong",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          project.person_full_name
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " ha laborado en las siguientes empresas:"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "observation-text",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "vs-row",
                                                                                [
                                                                                  _c(
                                                                                    "vs-col",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          "vs-type":
                                                                                            "flex",
                                                                                          "vs-justify":
                                                                                            "start",
                                                                                          "vs-align":
                                                                                            "center",
                                                                                          "vs-w":
                                                                                            "5",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "strong",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Empresa:"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "vs-col",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          "vs-type":
                                                                                            "flex",
                                                                                          "vs-justify":
                                                                                            "start",
                                                                                          "vs-align":
                                                                                            "center",
                                                                                          "vs-w":
                                                                                            "5",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "strong",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Periodo:"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "vs-col",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          "vs-type":
                                                                                            "flex",
                                                                                          "vs-justify":
                                                                                            "start",
                                                                                          "vs-align":
                                                                                            "center",
                                                                                          "vs-w":
                                                                                            "2",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "strong",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Duración:"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._l(
                                                                                currentValidation
                                                                                  .extra
                                                                                  .pila_periods,
                                                                                function (
                                                                                  pila_period,
                                                                                  i
                                                                                ) {
                                                                                  return _c(
                                                                                    "vs-row",
                                                                                    {
                                                                                      key: i,
                                                                                      staticStyle:
                                                                                        {
                                                                                          "margin-bottom":
                                                                                            "2%",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      i <
                                                                                      5
                                                                                        ? _c(
                                                                                            "vs-col",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  "vs-type":
                                                                                                    "flex",
                                                                                                  "vs-justify":
                                                                                                    "start",
                                                                                                  "vs-align":
                                                                                                    "top",
                                                                                                  "vs-w":
                                                                                                    "4",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                              " +
                                                                                                  _vm._s(
                                                                                                    pila_period.company
                                                                                                  ) +
                                                                                                  "\n                                            "
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      i <
                                                                                      5
                                                                                        ? _c(
                                                                                            "vs-col",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  "vs-type":
                                                                                                    "",
                                                                                                  "vs-justify":
                                                                                                    "",
                                                                                                  "vs-align":
                                                                                                    "",
                                                                                                  "vs-w":
                                                                                                    "8",
                                                                                                },
                                                                                            },
                                                                                            _vm._l(
                                                                                              pila_period.periods_per_company,
                                                                                              function (
                                                                                                period,
                                                                                                j
                                                                                              ) {
                                                                                                return _c(
                                                                                                  "vs-row",
                                                                                                  {
                                                                                                    key: j,
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "vs-col",
                                                                                                      {
                                                                                                        attrs:
                                                                                                          {
                                                                                                            "vs-type":
                                                                                                              "flex",
                                                                                                            "vs-justify":
                                                                                                              "",
                                                                                                            "vs-align":
                                                                                                              "",
                                                                                                            "vs-w":
                                                                                                              "9",
                                                                                                          },
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "\n                                                      " +
                                                                                                            _vm._s(
                                                                                                              period.start_month
                                                                                                            ) +
                                                                                                            " " +
                                                                                                            _vm._s(
                                                                                                              period.start_year
                                                                                                            ) +
                                                                                                            " a " +
                                                                                                            _vm._s(
                                                                                                              period.end_month
                                                                                                                ? period.end_month
                                                                                                                : "No reporta"
                                                                                                            ) +
                                                                                                            " " +
                                                                                                            _vm._s(
                                                                                                              period.end_year
                                                                                                            ) +
                                                                                                            "\n                                                  "
                                                                                                        ),
                                                                                                      ]
                                                                                                    ),
                                                                                                    _c(
                                                                                                      "vs-col",
                                                                                                      {
                                                                                                        attrs:
                                                                                                          {
                                                                                                            "vs-type":
                                                                                                              "flex",
                                                                                                            "vs-justify":
                                                                                                              "",
                                                                                                            "vs-align":
                                                                                                              "",
                                                                                                            "vs-w":
                                                                                                              "3",
                                                                                                          },
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "\n                                                      " +
                                                                                                            _vm._s(
                                                                                                              period.months
                                                                                                            ) +
                                                                                                            " " +
                                                                                                            _vm._s(
                                                                                                              period.months ==
                                                                                                                "1"
                                                                                                                ? "Mes"
                                                                                                                : "Meses "
                                                                                                            ) +
                                                                                                            "\n                                                  "
                                                                                                        ),
                                                                                                      ]
                                                                                                    ),
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                              }
                                                                                            ),
                                                                                            1
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                }
                                                                              ),
                                                                            ],
                                                                            2
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "pila-container",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "p",
                                                                                {
                                                                                  staticClass:
                                                                                    "pila-disclaimer",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "* Esta información no es exhaustiva. Puede haber otras experiencias laborales que no estén recogidas en esta base de datos"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                {
                                                                                  staticClass:
                                                                                    "pila-disclaimer",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    '* En el periodo cuando sale "No reporta" significa que la empresa no declaró o no ha declarado evento de salida de la persona'
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          currentValidation
                                                                            .extra
                                                                            .pila_periods
                                                                            .length >
                                                                          5
                                                                            ? _c(
                                                                                "div",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      width:
                                                                                        "100%",
                                                                                      display:
                                                                                        "flex",
                                                                                      "justify-content":
                                                                                        "center",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "btn-pila",
                                                                                      attrs:
                                                                                        {
                                                                                          href: {
                                                                                            url: "/project",
                                                                                          },
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.getProject(
                                                                                              project
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Ver histórico laboral completo ->"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "observation-timestamp",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "observation-timestamp-time",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.timestampFormat(
                                                                                        currentValidation
                                                                                          .extra
                                                                                          .time,
                                                                                        "time"
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "observation-timestamp-date",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.timestampFormat(
                                                                                        currentValidation
                                                                                          .extra
                                                                                          .time,
                                                                                        "date"
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ])
                                                                  : _c("div", [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "observation-card",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "vx-row",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "vs-col",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      "vs-type":
                                                                                        "flex",
                                                                                      "vs-justify":
                                                                                        "start",
                                                                                      "vs-align":
                                                                                        "center",
                                                                                      w: "12",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "img",
                                                                                    {
                                                                                      staticClass:
                                                                                        "icon-obs",
                                                                                      attrs:
                                                                                        {
                                                                                          src: _vm.icon_url_validation(
                                                                                            currentValidation.icon_name,
                                                                                            project.remote
                                                                                          ),
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "process-detail-container-title",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Historico laboral"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "observation-text",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _c(
                                                                                    "strong",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "No se ha encontrado información adicional sobre el histórico laboral "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    "\n                                        del candidato en la base de datos PILA de enlace operativo.\n                                      "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "observation-timestamp",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "observation-timestamp-time",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.timestampFormat(
                                                                                        currentValidation
                                                                                          .extra
                                                                                          .time,
                                                                                        "time"
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "observation-timestamp-date",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.timestampFormat(
                                                                                        currentValidation
                                                                                          .extra
                                                                                          .time,
                                                                                        "date"
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]),
                                                              ])
                                                            : _c("div", [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "observation-card",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "vx-row",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "vs-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "vs-type":
                                                                                  "flex",
                                                                                "vs-justify":
                                                                                  "start",
                                                                                "vs-align":
                                                                                  "center",
                                                                                w: "12",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "img",
                                                                              {
                                                                                staticClass:
                                                                                  "icon-obs",
                                                                                attrs:
                                                                                  {
                                                                                    src: _vm.icon_url_validation(
                                                                                      currentValidation.icon_name,
                                                                                      project.remote
                                                                                    ),
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "process-detail-container-title",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Historico laboral"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "observation-text",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "pila-error-connection",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "La conexión con la base de datos PILA de enlace operativo no se encuentra disponible en este momento."
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "observation-timestamp",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "observation-timestamp-time",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.timestampFormat(
                                                                                  currentValidation
                                                                                    .extra
                                                                                    .time,
                                                                                  "time"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "observation-timestamp-date",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.timestampFormat(
                                                                                  currentValidation
                                                                                    .extra
                                                                                    .time,
                                                                                  "date"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("vs-col", { attrs: { "vs-w": "3" } }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                }),
                0
              )
            : _c("div", [
                _c(
                  "div",
                  { staticClass: "process-detail-container" },
                  [
                    _c(
                      "vs-row",
                      [
                        _c(
                          "vs-col",
                          {
                            attrs: {
                              "vs-w": "1",
                              "vs-type": "flex",
                              "vs-justify": "center",
                              "vs-align": "center",
                            },
                          },
                          [
                            _c("div", [
                              _c("img", {
                                staticClass: "icon-face",
                                attrs: {
                                  src: require("../../assets/images/fast-tracking/utils/others/info-icon.svg"),
                                },
                              }),
                            ]),
                          ]
                        ),
                        _c(
                          "vs-col",
                          {
                            staticStyle: {
                              "border-right": "1px solid #AEAED7",
                            },
                            attrs: {
                              "vs-w": "6",
                              "vs-type": "flex",
                              "vs-justify": "center",
                              "vs-align": "center",
                            },
                          },
                          [
                            _c("div", [
                              _c(
                                "h5",
                                {
                                  staticClass: "process-detail-container-title",
                                },
                                [
                                  _vm._v(
                                    "No se han encontrado resultados para tu búsqueda."
                                  ),
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass: "process-detail-container-text",
                                },
                                [
                                  _vm._v(
                                    "\n                  Por favor revisa que la información ingresada sea correcta y vuelve a intentar.\n                  También puedes probar con otro nombre del candidato.\n                "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "vs-col",
                          {
                            attrs: {
                              "vs-w": "4",
                              "vs-type": "flex",
                              "vs-justify": "center",
                              "vs-align": "center",
                            },
                          },
                          [
                            _c("div", [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "process-detail-container-text-bold",
                                },
                                [
                                  _vm._v(
                                    "Si aún no encuentras lo que necesitas, no dudes en contactarnos para brindarte una atención personalizada."
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
        ]),
    this.fastTracking != undefined && this.fastTracking.length > 0
      ? _c(
          "div",
          [
            _c("vs-pagination", {
              attrs: {
                "not-margin": false,
                total: _vm.fastTrackingProjectsCount,
              },
              model: {
                value: _vm.currentPage,
                callback: function ($$v) {
                  _vm.currentPage = $$v
                },
                expression: "currentPage",
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }