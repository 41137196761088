<template>
  <div class="certificate">
    <div class="vx-card text-center bg-primary-gradient greet-user" slot="no-body">
      <div class="vx-card__collapsible-content vs-con-loading__container">
        <div class="vx-card__body">
          <img src="https://pixinvent.com/demo/vuesax-vuejs-admin-dashboard-template/demo-1/img/decore-left.2d9d23e0.png" alt="Decore Left" width="200" class="decore-left">
          <img src="https://pixinvent.com/demo/vuesax-vuejs-admin-dashboard-template/demo-1/img/decore-right.da0ab5cd.png" alt="Decore Right" width="175" class="decore-right">
          <span class="p-6 mb-8 bg-primary inline-flex rounded-full text-white shadow feather-icon select-none relative">
            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-award h-8 w-8">
            <circle cx="12" cy="8" r="7"></circle>
            <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline></svg></span>
            <h1 class="mb-6 text-white">Felicitaciones {{full_name}},</h1>
            <p class="xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-white">Has realizado <strong>57.6%</strong> de verificaciones confiables.</p>
        </div>
      </div>
      <div class="vx-card__code-container collapsed" style="max-height: 0px; display: none;">
        <div class="code-content">
          <pre class="language-markup">
            <code class="language-markup"></code>
          </pre>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';
import vSelect from 'vue-select'
import api from '../mixins/api'
import { mapGetters } from 'vuex'
export default {
  mixins: [api],
  data(){
    return{
      full_name: ""
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  methods:{
    
  },
  created: function(){
    var first_name = this.userInfo.user.first_name    
    var last_name = this.userInfo.user.last_name    
    this.full_name = first_name + " " + last_name
  },
  watch: {

  },  
  components: {
    VueApexCharts,
    vSelect
  }
}
</script>
<style>
  .vdp-datepicker input{
    width: 100%
  }
</style>

