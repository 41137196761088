var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          attrs: {
            "background-color": "rgba(255,255,255,.6)",
            title: "Proceso",
            active: _vm.popupShowActive,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupShowActive = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "vx-col w-full lg:w-1/1 sm:w-1/1 mb-base" },
            [
              _c(
                "div",
                { staticClass: "vx-card p-2" },
                [
                  _c("ProjectFormMisssing", {
                    attrs: { project: _vm.project },
                  }),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }