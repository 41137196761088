var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "certificate" }, [
    _c(
      "div",
      {
        staticClass: "vx-card text-center bg-primary-gradient greet-user",
        attrs: { slot: "no-body" },
        slot: "no-body",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vx-card__collapsible-content vs-con-loading__container",
          },
          [
            _c("div", { staticClass: "vx-card__body" }, [
              _c("img", {
                staticClass: "decore-left",
                attrs: {
                  src: "https://pixinvent.com/demo/vuesax-vuejs-admin-dashboard-template/demo-1/img/decore-left.2d9d23e0.png",
                  alt: "Decore Left",
                  width: "200",
                },
              }),
              _c("img", {
                staticClass: "decore-right",
                attrs: {
                  src: "https://pixinvent.com/demo/vuesax-vuejs-admin-dashboard-template/demo-1/img/decore-right.da0ab5cd.png",
                  alt: "Decore Right",
                  width: "175",
                },
              }),
              _c(
                "span",
                {
                  staticClass:
                    "p-6 mb-8 bg-primary inline-flex rounded-full text-white shadow feather-icon select-none relative",
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "feather feather-award h-8 w-8",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "24px",
                        height: "24px",
                        viewBox: "0 0 24 24",
                        fill: "none",
                        stroke: "currentColor",
                        "stroke-width": "2",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                      },
                    },
                    [
                      _c("circle", { attrs: { cx: "12", cy: "8", r: "7" } }),
                      _c("polyline", {
                        attrs: {
                          points: "8.21 13.89 7 23 12 20 17 23 15.79 13.88",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _c("h1", { staticClass: "mb-6 text-white" }, [
                _vm._v("Felicitaciones " + _vm._s(_vm.full_name) + ","),
              ]),
              _vm._m(0),
            ]),
          ]
        ),
        _vm._m(1),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticClass: "xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-white" },
      [
        _vm._v("Has realizado "),
        _c("strong", [_vm._v("57.6%")]),
        _vm._v(" de verificaciones confiables."),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "vx-card__code-container collapsed",
        staticStyle: { "max-height": "0px", display: "none" },
      },
      [
        _c("div", { staticClass: "code-content" }, [
          _c("pre", { staticClass: "language-markup" }, [
            _vm._v("          "),
            _c("code", { staticClass: "language-markup" }),
            _vm._v("\n        "),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }