<template>
  <div class="list-traceability">
    <div class="events-traceability" v-for="(event, index) in events" :key="`${event.event_type}-${index}`">
      <template v-if="event.childrens.length > 0">
        <h4 class="title-colum-event">{{event.event_type}}</h4>
        <RenderChildrens :childrens="event.childrens" :img="img" />
      </template>
    </div>
  </div>
</template>

<script>
  import RenderChildrens from './RenderChildrens'
  export default {
    props: {
      events: null,
      img: null
    },
    components: {
      RenderChildrens,
    },
  }
</script>
