<template>
  <div>
    <ProjectHeaderPreliminary :project="project"/>
    <br>
    <ProjectObservationsFormMissing :project="project"/>
    <!-- <ProjectConceptGeneral :project="project"/> -->
    <!-- <br>    
    <br v-if="project.credit_validation">
    <ProjectCreditValidation :project="project" v-if="project.credit_validation"/>
    <br v-if="project.judicial_verification.length > 0">
    <ProjectJudicialVerification :project="project" v-if="project.judicial_verification.length > 0"/>    
    <br> 
    <ProjectDataForm :project="project"/> -->
    <!-- <br v-if="project.analyst_information.fields.length > 0">
    <ProjectAnalyst :project="project" v-if="project.analyst_information.fields.length > 0"/>     -->
    <br>    
    <vx-card>  
      <!-- <vs-button class="button-report" type="gradient" style="background: #640064;" v-bind:href="linkReport()">Descargar reporte</vs-button> -->
      <!-- <a v-bind:href="linkReport()" target="_blank" type="gradient" name="button" class="vs-component vs-button w-full mt-6 vs-button-null isActive button-report" style="background: #640064;">
        <span class="vs-button-text vs-button--text">Descargar reporte</span>
      </a> -->
      <vs-button type="gradient" style="background: #640064;" :href="{url: '/project'}">Ver resultado</vs-button>
    </vx-card>
  </div>
</template>

<script>
import ProjectHeaderPreliminary from './ProjectHeaderPreliminary.vue'
import ProjectConceptGeneral from './ProjectConceptGeneral.vue'
import ProjectDataForm from './ProjectDataForm.vue'
import ProjectObservationsFormMissing from './ProjectObservationsFormMissing.vue'
import ProjectCreditValidation from './ProjectCreditValidation.vue'
import ProjectJudicialVerification from './ProjectJudicialVerification.vue'
import ProjectAnalyst from './ProjectAnalyst'
import api from '../../mixins/api.js'
export default {
  mixins: [api],
  props: {
    popupShowActive: Boolean,
    project: Object,
  },
  data (){
    return{
      colorx:"rgba(255,255,255,255)"
    }
  },
  methods: {
    goToProject(){
      this.$router.push('project')
    }
  },
  created(){

  },
  mounted(){

  },
  components:{
    ProjectHeaderPreliminary,
    ProjectConceptGeneral,
    ProjectDataForm,
    ProjectObservationsFormMissing,
    ProjectCreditValidation,
    ProjectJudicialVerification,
    ProjectAnalyst
  }

}
</script>

<style>
  .vs-popup{
    width: 80% !important;
  }
  .button-report{
    margin-right: 1rem !important; 
  }
</style>