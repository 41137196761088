var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.childrens != null
    ? _c(
        "div",
        { staticClass: "render-children" },
        _vm._l(_vm.childrens, function (children) {
          return _c(
            "div",
            {
              key: children[0],
              staticClass:
                "event-children event-children--information-completed",
            },
            [
              children[2]
                ? [
                    _c("div", { staticClass: "event-children-icon" }, [
                      _c("img", { attrs: { src: _vm.img, alt: "En espera" } }),
                    ]),
                  ]
                : _c("div", { staticClass: "event-children-icon" }, [
                    _c(
                      "svg",
                      {
                        staticClass: "bi bi-chat-left-dots",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "32",
                          height: "32",
                          fill: "#408530",
                          viewBox: "0 0 16 16",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z",
                          },
                        }),
                        _c("path", {
                          attrs: {
                            d: "M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z",
                          },
                        }),
                      ]
                    ),
                  ]),
              _c("div", { staticClass: "event-children-content" }, [
                _c("div", { staticClass: "event-checklist-item" }, [
                  _vm._v(_vm._s(children[0])),
                ]),
              ]),
            ],
            2
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }