var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-card card-traceability card-traceability-project" },
    [
      _c("div", { staticClass: "vx-card__header" }, [
        _c("div", { staticClass: "card__title" }, [
          _c("p", [_vm._v(_vm._s(_vm.record.project.project_type))]),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "vx-card__collapsible-content vs-con-loading__container",
        },
        [
          _c("div", { staticClass: "vx-card__body card-body-traceability" }, [
            _c(
              "h6",
              {
                staticClass: "full-name-traceability",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.goToDetails(_vm.record.project)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.record.project.full_name))]
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "labels" }, [
        _vm.record.project.status_name != null
          ? _c(
              "span",
              { class: "label " + _vm.record.project.status_name_class },
              [
                _vm._v(
                  _vm._s(_vm._f("capitalize")(_vm.record.project.status_name))
                ),
              ]
            )
          : _vm._e(),
        _vm.record.project.overall_final_name != null
          ? _c(
              "span",
              { class: "label " + _vm.record.project.overall_final_name_class },
              [
                _vm._v(
                  _vm._s(
                    _vm._f("capitalize")(_vm.record.project.overall_final_name)
                  )
                ),
              ]
            )
          : _vm._e(),
      ]),
      _c(
        "div",
        {
          staticClass: "event-checklist-progress",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.actionShowModal(_vm.record.checklist)
            },
          },
        },
        [
          _vm.type.name == "project_finished"
            ? _c(
                "svg",
                {
                  staticClass: "bi bi-list-task",
                  staticStyle: { cursor: "pointer" },
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "32",
                    height: "32",
                    fill: "rgb(95, 0, 145)",
                    viewBox: "0 0 16 16",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      d: "M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z",
                    },
                  }),
                ]
              )
            : _c("p", { staticClass: "event-checklist-progress-text" }, [
                _vm._v(_vm._s(_vm.record.checklist.progress)),
              ]),
        ]
      ),
      _vm.record.event != null
        ? _c(
            "div",
            { staticClass: "event-date event-date-traceability" },
            [_c("EventDate", { attrs: { event: _vm.record.event } })],
            1
          )
        : _vm._e(),
      _vm.checklist != null && _vm.showModal
        ? _c("TraceabilityModal", {
            attrs: { checklist: _vm.checklist, missing: _vm.showColumnMissing },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }