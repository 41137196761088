var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.checklist != null && _vm.missing != null
    ? _c("div", { staticClass: "event-modal-traceability" }, [
        _c(
          "div",
          { class: "modal-childrens-completed-pending " + _vm.onlyColumn() },
          [
            _c(
              "span",
              {
                staticClass: "close-modal-event",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.actionHideModal.apply(null, arguments)
                  },
                },
              },
              [_c("vs-icon", { attrs: { icon: "close" } })],
              1
            ),
            _c("div", { staticClass: "container-completed-pending" }, [
              _c("div", { staticClass: "column-container-completed-pending" }, [
                _c(
                  "div",
                  { staticClass: "list-events-childrens" },
                  [
                    _c("h4", { staticClass: "title-colum" }, [
                      _vm._v("Procesos realizados"),
                    ]),
                    _c("ListTraceabilityCompleted", {
                      attrs: {
                        events: _vm.checklist.completed_events,
                        img: _vm.imgCheck,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm.missing
                ? _c(
                    "div",
                    { staticClass: "column-container-completed-pending" },
                    [
                      _c(
                        "div",
                        { staticClass: "list-events-childrens" },
                        [
                          _c("h4", { staticClass: "title-colum" }, [
                            _vm._v("Procesos pendientes"),
                          ]),
                          _c("ListTraceabilityPending", {
                            attrs: {
                              events: _vm.checklist.pending_events,
                              img: _vm.imgTimer,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }