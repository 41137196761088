<template>  
  <div class="vx-card card-traceability card-traceability-project">
    <div class="vx-card__header">
      <div class="card__title">
        <p>{{record.project.project_type}}</p>                
      </div>          
    </div>
    <div class="vx-card__collapsible-content vs-con-loading__container">
      <div class="vx-card__body card-body-traceability">
        <h6 class="full-name-traceability" @click.prevent="goToDetails(record.project)">{{record.project.full_name}}</h6>
      </div>
    </div>
    <div class="labels">
      <!-- status -->
      <span :class="`label ${record.project.status_name_class}`" v-if="record.project.status_name != null">{{record.project.status_name | capitalize }}</span> 
      <!-- overall_final -->
      <span :class="`label ${record.project.overall_final_name_class}`" v-if="record.project.overall_final_name != null">{{record.project.overall_final_name | capitalize }}</span>
    </div>
    <div class="event-checklist-progress" @click.prevent="actionShowModal(record.checklist)">
      <svg style="cursor: pointer;" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="rgb(95, 0, 145)" class="bi bi-list-task" viewBox="0 0 16 16" v-if="type.name == 'project_finished'">
        <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
      </svg>
      <p class="event-checklist-progress-text" v-else>{{ record.checklist.progress }}</p>            
    </div>                    
    <div class="event-date event-date-traceability" v-if="record.event != null">
      <EventDate :event="record.event" />      
    </div>
    <TraceabilityModal :checklist="checklist" :missing="showColumnMissing" v-if="checklist != null && showModal"/>
  </div>  
</template>

<script>
  import { eventBus } from './../../main'
  import EventDate from './../projects/events/EventDate.vue'  
  import TraceabilityModal from './../traceability/TraceabilityModal.vue'
  import { mapMutations } from 'vuex';
  export default {
    data() {
      return {
        showModal: false,
        checklist: null,
        showColumnMissing: null
      }
    },
    props: {
      record: null,
      type: null
    },
    methods: {
      ...mapMutations(['changeCurrentProject']),
      goToDetails(project) {
        this.changeCurrentProject(project)
        window.open(`/project`, '_blank')
      },
      actionShowModal(checklist) {
        document.querySelector(".vx-navbar-wrapper").style['z-index'] = 1;
        document.getElementById('navBar').style = 'z-index:1;'
        this.checklist = checklist 
        this.showModal = true
        if (this.type.name == 'project_finished') {
          this.showColumnMissing = false
        } else {
          this.showColumnMissing = true
        }
      },                             
    },
    components: {
      EventDate,
      TraceabilityModal
    },
    mounted () {
      eventBus.$on('hideTraceabilityModal', () => {
        document.getElementById('navBar').style = 'z-index:41000;'
        this.checklist = null 
        this.showModal = false
      })      
    }    
  }
</script>

<style scoped>

</style>
