var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "notifications" }, [
    _c("div", { attrs: { id: "popupsProject" } }),
    _c("div", { attrs: { id: "popupsProjectFormsMissing" } }),
    _vm.projectShow != {}
      ? _c("div", { staticClass: "vx-row" }, [
          _vm.projectsInProcess != []
            ? _c(
                "div",
                { staticClass: "vx-col w-full lg:w-1/3 mb-base" },
                [
                  _c(
                    "vx-card",
                    { attrs: { title: "Nuevos" } },
                    _vm._l(
                      _vm.projectsInProcess,
                      function (projectInProcess, index_inProcess) {
                        return _c(
                          "ul",
                          {
                            key: index_inProcess,
                            staticClass: "activity-timeline",
                          },
                          [
                            _c(
                              "li",
                              [
                                _c("div", { staticClass: "timeline-info" }, [
                                  _c("p", { staticClass: "font-semibold" }, [
                                    _vm._v(_vm._s(projectInProcess.full_name)),
                                  ]),
                                  _c("span", { staticClass: "activity-desc" }, [
                                    _vm._v(
                                      "Proceso: " +
                                        _vm._s(projectInProcess.project_type)
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "vs-row",
                                  [
                                    _c(
                                      "vs-col",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: "col - 1",
                                            expression: "'col - 1'",
                                          },
                                        ],
                                        attrs: {
                                          "vs-offset": "10",
                                          "vs-type": "flex",
                                          "vs-justify": "center",
                                          "vs-align": "center",
                                          "vs-w": "2",
                                        },
                                      },
                                      [
                                        projectInProcess.notifications.length >
                                        0
                                          ? _c("vs-button", {
                                              staticClass:
                                                "button-notification",
                                              attrs: {
                                                icon: "notification_important",
                                                size: "26px",
                                                color: "primary",
                                                type: "flat",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showNotifications(
                                                    projectInProcess.id
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "vs-row",
                                  {
                                    attrs: {
                                      "vs-type": "flex",
                                      "vs-justify": "space-between",
                                    },
                                  },
                                  [
                                    _c("vs-col", {
                                      staticClass: "ago",
                                      attrs: {
                                        "vs-type": "flex",
                                        "vs-justify": "center",
                                        "vs-align": "center",
                                        "vs-w": "2",
                                      },
                                    }),
                                    _c(
                                      "vs-col",
                                      {
                                        staticClass: "view-more-in-process",
                                        attrs: {
                                          "vs-type": "flex",
                                          "vs-justify": "center",
                                          "vs-align": "center",
                                          "vs-w": "10",
                                        },
                                      },
                                      [
                                        _c(
                                          "vs-button",
                                          {
                                            staticClass: "button-view-more",
                                            attrs: {
                                              color: "primary",
                                              type: "flat",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.getProjectFormsMissing(
                                                  projectInProcess.id
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Pendiente por")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "vx-col w-1/1 mb-3" },
                                  [
                                    _c(
                                      "vx-tooltip",
                                      {
                                        attrs: {
                                          color: "primary",
                                          text: _vm.informationAdvance(
                                            projectInProcess
                                          ),
                                        },
                                      },
                                      [
                                        _c("vs-progress", {
                                          staticClass: "block mt-1",
                                          attrs: {
                                            percent: parseInt(
                                              projectInProcess.percentage
                                                .percentage_complete
                                            ),
                                            color: "primary",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.projectsPreliminaries != []
            ? _c(
                "div",
                { staticClass: "vx-col w-full lg:w-1/3 mb-base" },
                [
                  _c(
                    "vx-card",
                    { attrs: { title: "En proceso" } },
                    _vm._l(
                      _vm.projectsPreliminaries,
                      function (projectPreliminaries, index_Preliminaries) {
                        return _c(
                          "ul",
                          {
                            key: index_Preliminaries,
                            staticClass: "activity-timeline",
                          },
                          [
                            _c(
                              "li",
                              [
                                _c("div", { staticClass: "timeline-info" }, [
                                  _c("p", { staticClass: "font-semibold" }, [
                                    _vm._v(
                                      _vm._s(projectPreliminaries.full_name)
                                    ),
                                  ]),
                                  _c("span", { staticClass: "activity-desc" }, [
                                    _vm._v(
                                      "Proceso: " +
                                        _vm._s(
                                          projectPreliminaries.project_type
                                        )
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "vs-row",
                                  [
                                    _c(
                                      "vs-col",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: "col - 1",
                                            expression: "'col - 1'",
                                          },
                                        ],
                                        attrs: {
                                          "vs-offset": "10",
                                          "vs-type": "flex",
                                          "vs-justify": "center",
                                          "vs-align": "center",
                                          "vs-w": "2",
                                        },
                                      },
                                      [
                                        projectPreliminaries.notifications
                                          .length > 0
                                          ? _c("vs-button", {
                                              staticClass:
                                                "button-notification",
                                              attrs: {
                                                icon: "notification_important",
                                                size: "26px",
                                                color: "primary",
                                                type: "flat",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showNotifications(
                                                    projectPreliminaries.id
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "vs-row",
                                  {
                                    attrs: {
                                      "vs-type": "flex",
                                      "vs-justify": "space-between",
                                    },
                                  },
                                  [
                                    _c(
                                      "vs-col",
                                      {
                                        staticClass: "ago-preliminary",
                                        attrs: {
                                          "vs-type": "flex",
                                          "vs-justify": "center",
                                          "vs-align": "center",
                                          "vs-w": "6",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                Creado el: " +
                                            _vm._s(
                                              _vm.convertTimeStamp(
                                                projectPreliminaries.created_at
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "vs-col",
                                      {
                                        staticClass: "view-more",
                                        attrs: {
                                          "vs-type": "flex",
                                          "vs-justify": "center",
                                          "vs-align": "center",
                                          "vs-w": "6",
                                        },
                                      },
                                      [
                                        _c(
                                          "vs-button",
                                          {
                                            staticClass: "button-view-more",
                                            attrs: {
                                              color: "primary",
                                              type: "flat",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.getProjectFormsMissing(
                                                  projectPreliminaries.id
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Pendiente por")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "vx-col w-1/1 mb-3" },
                                  [
                                    _c(
                                      "vx-tooltip",
                                      {
                                        attrs: {
                                          color: "primary",
                                          text: _vm.informationAdvance(
                                            projectPreliminaries
                                          ),
                                        },
                                      },
                                      [
                                        _c("vs-progress", {
                                          staticClass: "block mt-1",
                                          attrs: {
                                            percent: parseInt(
                                              projectPreliminaries.percentage
                                                .percentage_complete
                                            ),
                                            color: "primary",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                  _c("div", { staticClass: "point-notifications" }),
                ],
                1
              )
            : _vm._e(),
          _vm.projectsFinished != []
            ? _c(
                "div",
                { staticClass: "vx-col w-full lg:w-1/3 mb-base" },
                [
                  _c(
                    "vx-card",
                    { attrs: { title: "Finalizado" } },
                    _vm._l(
                      _vm.projectsFinished,
                      function (projectFinished, index_projectsFinished) {
                        return _c(
                          "ul",
                          {
                            key: index_projectsFinished,
                            staticClass: "activity-timeline",
                          },
                          [
                            _c(
                              "li",
                              [
                                _c(
                                  "div",
                                  { staticClass: "timeline-info" },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "font-semibold",
                                        on: {
                                          click: function ($event) {
                                            return _vm.showProject(
                                              projectFinished.id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(projectFinished.full_name)
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "activity-desc" },
                                      [
                                        _vm._v(
                                          "Proceso: " +
                                            _vm._s(projectFinished.project_type)
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "vs-row",
                                      [
                                        _c(
                                          "vs-col",
                                          {
                                            staticClass: "text-result",
                                            attrs: {
                                              "vs-type": "flex",
                                              "vs-justify": "center",
                                              "vs-align": "center",
                                              "vs-w": "6",
                                            },
                                          },
                                          [
                                            _c(
                                              "p",
                                              { staticClass: "font-semibold" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    projectFinished.overall_final_name
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "vs-col",
                                          {
                                            attrs: {
                                              "vs-type": "flex",
                                              "vs-align": "center",
                                              "vs-w": "6",
                                            },
                                          },
                                          [
                                            _c("vs-icon", {
                                              attrs: {
                                                icon: _vm.iconsOveral[
                                                  projectFinished.overall_final
                                                ],
                                                size: "medium",
                                                color:
                                                  _vm.colorsOveral[
                                                    projectFinished
                                                      .overall_final
                                                  ],
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "vs-row",
                                  {
                                    attrs: {
                                      "vs-type": "flex",
                                      "vs-justify": "space-between",
                                    },
                                  },
                                  [
                                    _c("vs-col", {
                                      staticClass: "ago",
                                      attrs: {
                                        "vs-type": "flex",
                                        "vs-justify": "center",
                                        "vs-align": "center",
                                        "vs-w": "6",
                                      },
                                    }),
                                    _c(
                                      "vs-col",
                                      {
                                        staticClass: "view-more",
                                        attrs: {
                                          "vs-type": "flex",
                                          "vs-justify": "center",
                                          "vs-align": "center",
                                          "vs-w": "6",
                                        },
                                      },
                                      [
                                        _c(
                                          "vs-button",
                                          {
                                            staticClass: "button-view-more",
                                            attrs: {
                                              color: "primary",
                                              type: "flat",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showProject(
                                                  projectFinished.id
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Ver más")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "vx-col w-1/1 mb-3" },
                                  [
                                    _c(
                                      "vx-tooltip",
                                      {
                                        attrs: {
                                          color: "primary",
                                          text: "100%",
                                        },
                                      },
                                      [
                                        _c("vs-progress", {
                                          staticClass: "block mt-1",
                                          attrs: {
                                            percent:
                                              projectFinished.percentage
                                                .percentage_complete,
                                            color: "primary",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }