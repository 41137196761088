var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.childrens != null
    ? _c(
        "div",
        { staticClass: "render-childrens-documents" },
        [
          _vm.childrens.length > 0
            ? _vm._l(_vm.childrens, function (children) {
                return _c(
                  "div",
                  {
                    key: children,
                    staticClass:
                      "event-children event-children-information-completed",
                  },
                  [
                    _c("div", { staticClass: "event-children-icon" }, [
                      _c("img", { attrs: { src: _vm.img, alt: "En espera" } }),
                    ]),
                    _c("div", { staticClass: "event-children-content" }, [
                      _c("div", { staticClass: "event-checklist-item" }, [
                        _vm._v(
                          _vm._s(_vm._f("textRule")(children.split(" ")[0]))
                        ),
                      ]),
                    ]),
                  ]
                )
              })
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }