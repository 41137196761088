<template>
  <div>
    <div id="popupsProject"></div>
    <div id="search-bar-section">
      <search-bar @refreshPage="setPage()" @updateLoading="loadingState" :page="this.currentPage">
      </search-bar>
    </div>
      <div v-if="loading">
        <div v-for="index in 7" :key="index">
          <div class="process-detail-container">
              <vs-row>

                <vs-col vs-w="3" vs-type="flex" vs-justify="center" vs-align="center">
                  <vs-col vs-w="4">
                    <div class="skeleton skeleton-circle-border"></div>
                    <p class="skeleton skeleton-text"></p>
                  </vs-col>
                  <vs-col vs-w="8">
                    <p class="skeleton skeleton-title"></p>
                    <p class="skeleton skeleton-text"></p>
                    <p class="skeleton skeleton-text-60"></p>
                    <p class="skeleton skeleton-text-60"></p>
                    <p class="skeleton skeleton-text-20"></p>
                  </vs-col>
                </vs-col>

                <vs-col vs-w="6" vs-type="flex" vs-justify="start" vs-align="center">
                  <vs-row direction="column" class="process-detail-container-border">
                    <vs-col>
                      <p class="skeleton skeleton-subtitle-40"></p>
                    </vs-col>
                    <vs-col>
                      <vs-row direction="row">
                        <vs-col vs-w="10" vs-type="flex" vs-justify="start" vs-align="center">
                          <div v-for="index in 5" :key="index" class="skeleton skeleton-circle">
                          </div>
                        </vs-col>

                        <vs-col vs-w="2" vs-type="flex" vs-justify="end" vs-align="center">
                          <p class="skeleton skeleton-text-60"></p>
                        </vs-col>

                      </vs-row>
                      </vs-col>
                  </vs-row>
                </vs-col>

                <vs-col vs-w="2" vs-type="flex" vs-justify="center" vs-align="center">
                  <vs-row direction="column">
                    <vs-col>
                      <p class="skeleton skeleton-subtitle-40"></p>
                    </vs-col>
                    <vs-col>
                      <div class="skeleton skeleton-circle"></div>
                    </vs-col>
                    <vs-col>
                      <div>
                        <img src="../../assets/images/fast-tracking/utils/stars/star-loader.png" class="skeleton-star"/>
                        <img src="../../assets/images/fast-tracking/utils/stars/star-loader.png" class="skeleton-star"/>
                        <img src="../../assets/images/fast-tracking/utils/stars/star-loader.png" class="skeleton-star"/>
                        <img src="../../assets/images/fast-tracking/utils/stars/star-loader.png" class="skeleton-star"/>
                        <img src="../../assets/images/fast-tracking/utils/stars/star-loader.png" class="skeleton-star"/>
                      </div>
                    </vs-col>
                  </vs-row>
                </vs-col>

                <vs-col vs-w="1" vs-type="flex" vs-justify="end" vs-align="center">
                  <div class="skeleton skeleton-circle"></div>
                </vs-col>

              </vs-row>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="fastTracking.length > 0">
          <div v-for="(project, projectIndex) in fastTracking" :key="project.id">
            <div class="process-detail-container">

                <vs-row>
                  <vs-col vs-w="3" vs-type="flex" vs-justify="center" vs-align="center" style="padding: 0px;">
                    <vs-col vs-w="4">
                      <vs-row vs-type="flex" vs-justify="center" vs-align="center" vs-direction="column" style="padding: 0px;">
                        <vs-col vs-w="12" style="padding: 0px;">
                          <div class="icon-percentage legendClass">
                            <vue-ellipse-progress
                            :progress="project.fihished_validations_rate"
                            :size="75"
                            :thickness="4"
                            color="#5F008F"
                            >
                            <span slot="legend-value">%</span>
                          </vue-ellipse-progress>
                          </div>
                          <div class="icon-popup">Porcentaje de avance</div>
                        </vs-col>
                        <vs-col vs-w="12" style="padding: 0px;">
                          <div>
                            <p class="process-detail-container-text process-detail-container-validation-rate">{{ project.fihished_validations }}/{{ project.total_validations }}</p>
                          </div>
                        </vs-col>
                      </vs-row>
                    </vs-col>
                    <vs-col vs-w="8">
                      <h5 class="process-detail-container-title">{{ project.person_full_name }}</h5>
                      <p class="process-detail-container-text">{{ project.project_type_name }} {{ project.polygraphy_type }} </p>
                      <p class="process-detail-container-text">{{ project.city_name }}</p>
                      <p class="process-detail-container-text">{{ project.person_id }}</p>
                      <p class="process-detail-container-text-bold">ID {{ project.id }}</p>
                    </vs-col>
                  </vs-col>

                  <vs-col vs-w="6">
                      <div class="process-detail-container-border">
                        <h5 class="process-detail-container-title">Observaciones</h5>

                        <div class="vx-row">

                          <div v-for="(validation) in project.validations" :key="validation.id" style="padding: 0px 10px;">
                            <div v-bind:id="`con-${project.id}-${validation.icon_name}`" class="icon-container" v-on:click="displayObservations([validation], validation.icon_name, project), updateObservationRead(project.id, validation, projectIndex), removeBadge(project.id, validation.icon_name), updatePilaNotificationRead(validation)">
                                <span v-bind:id="`obs-${project.id}-${validation.icon_name}`" v-if="unseen_notifications(validation) > 0" class="unread-observations" >{{ unseen_notifications(validation) }}</span>
                                <img v-bind:src="icon_url_validation(validation.icon_name, project.remote)" class="icon-circle-background"/>
                            </div>

                            <div class="icon-popup">{{ validation.name }}</div>
                            <div v-if="validation.done">
                              <img v-bind:src="icon_url_check(validation.qualification)" class="icon-check"/>

                              <div v-if="validation.qualification != null" class="icon-popup">
                                  <img v-bind:src="icon_url_star(validation.qualification)" class="icon-star"/>
                              </div>
                            </div>
                          </div>

                          <div class="process-detail-container-title process-detail-container-show-all">
                            <p v-on:click="displayObservations(project.validations, 'todos', project), updateAllObservationRead(project)">Ver todas</p>
                          </div>

                        </div>
                      </div>

                  <!-- Aca van obs -->

                  </vs-col>

                  <vs-col vs-w="2" vs-type="flex" vs-justify="start" vs-align="center">
                    <div>
                      <div v-if="project.finished">
                        <h5 v-if="project.overall_final_name === 'Confiable' " class="process-detail-container-title-trust">{{ project.overall_final_name }}</h5>
                        <h5 v-else-if="project.overall_final_name === 'Confiable Con Observaciones'" class="process-detail-container-title-unreliable">{{ project.overall_final_name }}</h5>
                        <h5 v-else-if="project.overall_final_name === 'Finalizado Con Restricciones'" class="process-detail-container-title-restriction">{{ project.overall_final_name }}</h5>
                        <h5 v-else-if="project.overall_final_name === 'No Confiable'" class="process-detail-container-title-untrust">{{ project.overall_final_name }}</h5>

                        <div>
                          <img v-bind:src="icon_url_face(project.overall_final_name)" class="icon-face"/>
                        </div>
                        <div v-if="project.overall_general > 0 && project.overall_general < 6">
                          <img v-bind:src="star_color_concept(project.overall_final_name, project.overall_general)" class="icon-star"/>
                        </div>
                      </div>
                    </div>
                  </vs-col>

                  <vs-col vs-w="1" vs-type="flex" vs-justify="start" vs-align="center">
                      <vs-button class="icon-container" v-on:click="getProject(project)" :href="{url: '/project'}" style="background: white !important; padding: 0px !important;">
                          <chevron-right-icon size="1.5x" class="custom-class"></chevron-right-icon>
                      </vs-button>
                  </vs-col>
                </vs-row>

                <vs-row>
                    <vs-col vs-w="3"></vs-col>

                    <vs-col vs-w="6">
                        <div class="vx-row" style="padding: 0px 10px;">
                          <transition name="slide">

                            <div class="w-full" v-if="currentProject.id == project.id && toggleObservations">
                              <div class="w-full" v-for="(currentValidation) in currentValidations" :key="currentValidation.id">
                                <div v-if="currentValidation.observations.length > 0">
                                  <div v-for="(observation, index) in currentValidation.observations" :key="observation.id">
                                    <div class="observation-card">

                                      <div v-if="index === 0" class="vx-row">
                                        <vs-col vs-type="flex" vs-justify="start" vs-align="center" w="12">
                                          <img v-bind:src="icon_url_validation(currentValidation.icon_name, project.remote)" class="icon-obs"/>
                                          <span class="process-detail-container-title">{{ currentValidation.name }}</span>
                                        </vs-col>
                                      </div>

                                      <div class="observation-text" v-html="observation.body"></div>
                                      <div class="observation-text-ia" v-if="observation.improved_with_ia">Texto mejorado con IA</div>
                                      <div class="observation-timestamp">
                                        <span class="observation-timestamp-time" >{{ timestampFormat(observation.created_at, 'time') }}</span>
                                        <span class="observation-timestamp-date">{{ timestampFormat(observation.created_at, 'date') }}</span>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                                <div v-else>
                                    <div class="observation-card">

                                      <div class="vx-row">
                                        <vs-col vs-type="flex" vs-justify="start" vs-align="center" w="12">
                                          <img v-bind:src="icon_url_validation(currentValidation.icon_name, project.remote)" class="icon-obs"/>
                                          <span class="process-detail-container-title">{{ currentValidation.name }}</span>
                                        </vs-col>
                                      </div>

                                      <p class="observation-text">No hay observaciones en esta verificación.</p>

                                    </div>
                                </div>
                                <div v-if="currentValidation.extra !== null">

                                  <div v-if="currentValidation.extra.connection_succeed">
                                    <div v-if="currentValidation.extra.has_pila_periods">
                                      <div class="observation-card">
                                        <div class="vx-row">
                                          <vs-col vs-type="flex" vs-justify="start" vs-align="center" w="12">
                                            <img v-bind:src="icon_url_validation(currentValidation.icon_name, project.remote)" class="icon-obs"/>
                                            <span class="process-detail-container-title">Historico laboral</span>
                                          </vs-col>
                                        </div>

                                      <div class="observation-text">
                                        <span>Se ha obtenido información adicional confiable sobre el historial laboral del candidato
                                          <strong>durante los últimos 36 meses, correspondiente al período de recopilación de datos de PILA.</strong>
                                        </span>
                                      </div>
                                        <div class="observation-timestamp">
                                          <span class="observation-timestamp-time" >{{ timestampFormat(currentValidation.extra.time, 'time') }}</span>
                                          <span class="observation-timestamp-date">{{ timestampFormat(currentValidation.extra.time, 'date') }}</span>
                                        </div>
                                      </div>

                                      <div class="observation-card">
                                        <div class="observation-text">
                                          <span>Tras revisar en la <strong>base de datos PILA de enlace operativo</strong> encontramos que <strong>{{ project.person_full_name }}</strong> ha laborado en las siguientes empresas:</span>
                                        </div>

                                      <div class="observation-text">
                                          <vs-row>
                                              <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="5">
                                                <strong>Empresa:</strong>
                                              </vs-col>
                                              <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="5">
                                                <strong>Periodo:</strong>
                                              </vs-col>
                                              <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="2">
                                                <strong>Duración:</strong>
                                              </vs-col>
                                          </vs-row>
                                          <br>
                                          <vs-row style="margin-bottom: 2%" v-for="(pila_period, i) in currentValidation.extra.pila_periods" :key="i">
                                              <vs-col vs-type="flex" vs-justify="start" vs-align="top" vs-w="4" v-if="i < 5">
                                                {{ pila_period.company }}
                                              </vs-col>
                                              <vs-col vs-type="" vs-justify="" vs-align="" vs-w="8" v-if="i < 5">
                                                <vs-row v-for="(period, j) in pila_period.periods_per_company" :key="j">
                                                    <vs-col vs-type="flex" vs-justify="" vs-align="" vs-w="9">
                                                        {{ period.start_month }} {{ period.start_year }} a {{ period.end_month ? period.end_month : 'No reporta' }} {{ period.end_year }}
                                                    </vs-col>
                                                    <vs-col vs-type="flex" vs-justify="" vs-align="" vs-w="3">
                                                        {{ period.months }} {{ period.months == '1' ? 'Mes' : 'Meses '}}
                                                    </vs-col>
                                                </vs-row>
                                              </vs-col>
                                          </vs-row>
                                      </div>

                                      <div class="pila-container">
                                        <p class="pila-disclaimer">* Esta información no es exhaustiva. Puede haber otras experiencias laborales que no estén recogidas en esta base de datos</p>
                                        <p class="pila-disclaimer">* En el periodo cuando sale "No reporta" significa que la empresa no declaró o no ha declarado evento de salida de la persona</p>
                                      </div>

                                        <div v-if="currentValidation.extra.pila_periods.length > 5" style="width:100%; display:flex; justify-content: center;">
                                          <span class="btn-pila" v-on:click="getProject(project)" :href="{url: '/project'}">Ver histórico laboral completo -></span>
                                        </div>

                                        <div class="observation-timestamp">
                                          <span class="observation-timestamp-time" >{{ timestampFormat(currentValidation.extra.time, 'time') }}</span>
                                          <span class="observation-timestamp-date">{{ timestampFormat(currentValidation.extra.time, 'date') }}</span>
                                        </div>

                                      </div>
                                    </div>
                                    <div v-else>
                                      <div class="observation-card">
                                        <div class="vx-row">
                                          <vs-col vs-type="flex" vs-justify="start" vs-align="center" w="12">
                                            <img v-bind:src="icon_url_validation(currentValidation.icon_name, project.remote)" class="icon-obs"/>
                                            <span class="process-detail-container-title">Historico laboral</span>
                                          </vs-col>
                                        </div>

                                      <div class="observation-text">
                                        <span>
                                          <strong>No se ha encontrado información adicional sobre el histórico laboral </strong>
                                          del candidato en la base de datos PILA de enlace operativo.
                                        </span>
                                      </div>
                                        <div class="observation-timestamp">
                                          <span class="observation-timestamp-time" >{{ timestampFormat(currentValidation.extra.time, 'time') }}</span>
                                          <span class="observation-timestamp-date">{{ timestampFormat(currentValidation.extra.time, 'date') }}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div v-else>
                                    <div class="observation-card">
                                      <div class="vx-row">
                                        <vs-col vs-type="flex" vs-justify="start" vs-align="center" w="12">
                                          <img v-bind:src="icon_url_validation(currentValidation.icon_name, project.remote)" class="icon-obs"/>
                                          <span class="process-detail-container-title">Historico laboral</span>
                                        </vs-col>
                                      </div>

                                    <div class="observation-text">
                                      <span class="pila-error-connection">La conexión con la base de datos PILA de enlace operativo no se encuentra disponible en este momento.</span>
                                    </div>
                                      <div class="observation-timestamp">
                                        <span class="observation-timestamp-time" >{{ timestampFormat(currentValidation.extra.time, 'time') }}</span>
                                        <span class="observation-timestamp-date">{{ timestampFormat(currentValidation.extra.time, 'date') }}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </transition>
                        </div>
                    </vs-col>

                    <vs-col vs-w="3"></vs-col>
                </vs-row>

            </div>
          </div>
        </div>
        <div v-else>
          <div class="process-detail-container">
            <vs-row>
              <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">
                <div>
                  <img src="../../assets/images/fast-tracking/utils/others/info-icon.svg" class="icon-face"/>
                </div>
              </vs-col>
              <vs-col vs-w="6" vs-type="flex" vs-justify="center" vs-align="center" style="border-right: 1px solid #AEAED7;">
                <div>
                  <h5 class="process-detail-container-title">No se han encontrado resultados para tu búsqueda.</h5>
                  <p class="process-detail-container-text">
                    Por favor revisa que la información ingresada sea correcta y vuelve a intentar.
                    También puedes probar con otro nombre del candidato.
                  </p>
                </div>
              </vs-col>
              <vs-col vs-w="4" vs-type="flex" vs-justify="center" vs-align="center">
                <div>
                  <p class="process-detail-container-text-bold">Si aún no encuentras lo que necesitas, no dudes en contactarnos para brindarte una atención personalizada.</p>
                </div>
              </vs-col>
            </vs-row>
          </div>
        </div>
      </div>

    <div v-if="this.fastTracking != undefined && this.fastTracking.length > 0">
      <vs-pagination :not-margin="false" :total="fastTrackingProjectsCount" v-model="currentPage"></vs-pagination>
    </div>

  </div>
</template>

<script>
import moment from 'moment'
import Search from './Search.vue'
import { VueEllipseProgress } from "vue-ellipse-progress";
import api from '../../mixins/api.js'
import { mapGetters, mapMutations } from 'vuex';
import { ChevronRightIcon } from 'vue-feather-icons'
import ProjectComponent from '../projects/Project'
import Vue from 'vue'

export default ({
  mixins: [api],

  data() {
    return {
      path: '',
      currentPage: 1,
      currentProject: {},
      currentValidations: [],
      currentDisplayObs: '',
      toggleObservations: false,
      loading: true
    }
  },
  watch: {
    currentPage(){
      window.scrollTo({
        top: document.getElementById('search-bar-section').offsetTop,
        left: 0,
        behavior: "smooth",
      });
    },
    fastTracking(){
      this.projects = this.fastTracking
    }
  },
  computed: {
    ...mapGetters(['fastTracking', 'fastTrackingProjectsCount', 'userInfo']),
  },
  methods:{
    ...mapMutations(['changeCurrentProject']),

    setPage(){
      this.currentPage = 1
    },

    loadingState(value){
      this.loading = value
    },

    unseenObservations(observations){
      observations = observations.filter(function(e){
        return (e.seen == false)
      })
      return observations.length
    },

    updateAllObservationRead(project, inxProject){
      project.validations.forEach(element => {
        if (element.observations.length > 0) {
          this.updateObservationRead(project.id, element, inxProject)
          this.updatePilaNotificationRead(element)
          this.removeBadge(project.id, element.icon_name)
        }
      });
    },

    updateObservationRead(project_id, validation, inxProject){
        let observationsToUpdateSeen = []

        observationsToUpdateSeen = validation.observations
        .filter(obj => obj.seen == false) // filter objects that satisfy condition
        .map(obs => {
          return {
            id: obs.id,
            classname: obs.classname
          };
        });

        this.path = "/api/v1/projects/update_observations?observations="+JSON.stringify(observationsToUpdateSeen)+"&project_id="+project_id

        this.requestGetWithoutContent(this.url() + this.path, {}).then(response => {
          if(response.data.success == true){
            this.projects[inxProject] = response.data.project
          }else{
            console.log("error -> " + response.data.message);
          }
        }, (err) => {
          console.log('err users -> ' +  JSON.stringify(err))
        })
    },

    updatePilaNotificationRead(validation) {

        if (validation.extra !== null && validation.extra.pila_notification_seen === false) {

          this.path = "/api/v1/pilas/"+ validation.extra.pila_id +"/update_pila_read"
          this.requestPost(this.url() + this.path, {}).then(response => {
            if(response.data.success == true){
            }else{
              console.log("error -> " + response.data.message);
            }
          }, (err) => {
            console.log('err users -> ' +  JSON.stringify(err))
          })

        }
    },

    unseen_notifications(validation) {
      let unseen_notifications_count = validation.unseen_observations_count
      if (validation.extra !== null && validation.extra.pila_notification_seen === false) {
        return unseen_notifications_count += 1
      } else {
        return unseen_notifications_count
      }
    },

    icon_url_validation(validation, remote){
      if (validation == 'visit' && remote == true) {
        validation = 'remote-work-visit'
      }
      return require(`../../assets/images/fast-tracking/validations/${ validation }.svg`)
    },

    icon_url_star(star){
      let iconurl = ''

      if (star == 1 || star == 2) {
        iconurl = require(`../../assets/images/fast-tracking/utils/stars/red/star-${ star }.svg`)
      } else if (star == 4 || star == 5) {
        iconurl = require(`../../assets/images/fast-tracking/utils/stars/green/star-${ star }.svg`)
      } else if (star == 3){
        iconurl = require(`../../assets/images/fast-tracking/utils/stars/yellow/star-${ star }.svg`)
      } else {
        iconurl = ''
      }
      return iconurl
    },

    star_color_concept(concept, star){
      let iconurl = ''
      if (star > 0 && star < 6) {
        if (concept == 'Confiable') {
          iconurl = require(`../../assets/images/fast-tracking/utils/stars/green/star-${ star }.svg`)
        } else if (concept == 'Confiable Con Observaciones') {
          iconurl = require(`../../assets/images/fast-tracking/utils/stars/yellow/star-${ star }.svg`)
        } else if (concept == 'Finalizado Con Restricciones'){
          iconurl = ''
        } else if (concept == 'No Confiable'){
          iconurl = require(`../../assets/images/fast-tracking/utils/stars/red/star-${ star }.svg`)
        } else {
          iconurl = ''
        }
      }
      return iconurl
    },

    icon_url_face(face){
      let iconurl = ''
      if (face == 'Confiable') {
        iconurl = require(`../../assets/images/fast-tracking/utils/faces/face-happy.svg`)
      } else if (face == 'Confiable Con Observaciones') {
        iconurl = require(`../../assets/images/fast-tracking/utils/faces/face-neutral.svg`)
      } else if (face == 'Finalizado Con Restricciones'){
        iconurl = require(`../../assets/images/fast-tracking/utils/faces/face-doubt.svg`)
      } else if (face == 'No Confiable'){
        iconurl = require(`../../assets/images/fast-tracking/utils/faces/face-sad.svg`)
      } else {
        iconurl = ''
      }
      return iconurl
    },

    icon_url_check(star){
      let iconurl = ''
      if (star == 1 || star == 2) {
        iconurl = require(`../../assets/images/fast-tracking/utils/checks/red-check.svg`)
      } else if (star == 4 || star == 5) {
        iconurl = require(`../../assets/images/fast-tracking/utils/checks/green-check.svg`)
      } else if (star == 3){
        iconurl = require(`../../assets/images/fast-tracking/utils/checks/yellow-check.svg`)
      } else {
        iconurl = require(`../../assets/images/fast-tracking/utils/checks/green-check.svg`)
      }
      return iconurl
    },

    displayObservations(validations, iconName, project){
      if (this.currentDisplayObs == `${project.id}-${iconName}`) {
        this.toggleObservations = !this.toggleObservations
      } else {
        this.toggleObservations = false
        this.toggleObservations = true
      }
      this.currentDisplayObs = `${project.id}-${iconName}`
      this.toogleIconSelection(project, iconName)
      this.currentProject = project
      this.currentValidations = validations
    },

    timestampFormat(value, format){
      if (format == 'time') {
        return moment(value).format('h:mm a');
      } else if (format == 'date') {
        return moment(value).format('D/M [del] Y');
      } else {
        return moment(value).format('LL h:mm:ss a');
      }
    },

    removeBadge(project_id, icon_name){
      const element = document.getElementById(`obs-${project_id}-${icon_name}`);
      if (element) {
          element.remove();
        }
    },

    toogleIconSelection(project, icon_name){
      const allSelected = document.getElementsByClassName("icon-container-selected");
      const selectedIcon = document.getElementById(`con-${project.id}-${icon_name}`);
      if (selectedIcon) {
        selectedIcon.classList.toggle("icon-container-selected");
      }

      allSelected.forEach((el) => {
        if (el.id !== `con-${project.id}-${icon_name}`) {
          el.classList.remove("icon-container-selected");
        }
      });

    },

    getProject(project){
      this.changeCurrentProject(project)

			let user_id = this.userInfo.user.id
			let path = "/api/v1/projects/" + project.id + "?user_id="+user_id;
			let url = this.url()+path
			this.requestGetWithoutContent(url, {}).then(response => {
				if(response.data.success == true){
					this.changeCurrentProject(response.data.data)

          let projectComponent = Vue.extend(ProjectComponent)
          let instanceProjectComponent = new projectComponent({
            propsData: { project: response.data.data, popupShowActive: false }
          })
          instanceProjectComponent.$mount()
          let popup = document.getElementById('popupsProject')
          popup.appendChild(instanceProjectComponent.$el)

				}else{
          console.log("error -> " + JSON.stringify(response.data.message));
				}
			}, (err) => {
				console.log('err users -> ' +  JSON.stringify(err))
			})
      this.$router.push('/project')
    }
  },
  components: {
    VueEllipseProgress,
    'search-bar': Search,
    ChevronRightIcon,
    ProjectComponent,
  },

  created(){
    this.loading = true
  }

})
</script>

<style lang="scss">
.process-detail-container{
/* Auto layout */

  align-items: center;
  padding: 20px 0px;
  gap: 24px;
  margin: 20px 0px;
  background: #F7F5FA;
  border-radius: 8px;

  &-border{
    border-left-style: solid;
    border-left-color: #AEAED7;
    border-right-style: solid;
    border-right-color: #AEAED7;
    padding: 10px 20px;
  }

  &-title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    margin: 7px 0px;
    white-space: normal;
    /* Morado Globalwork */

    color: #5F008F;

    &-trust{
      color: #00AF50;
      font-weight: 600;
    }

    &-untrust{
      color: #EA5455;
      font-weight: 600;
    }

    &-unreliable{
      color: #FFC057;
      font-weight: 600;
    }

    &-restriction{
      color: #634871;
      font-weight: 600;
    }
  }

  &-text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    margin: 7px 0px;
    color: #3C1A4E;
    white-space: normal;

    &-bold{
      font-size: 13px;
      font-weight: 500;
      color: #3C1A4E;

    }
  }

  &-validation-rate{
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
  }

  &-show-all{
    align-self: end;
    margin-left: auto;
    margin-right: 0;
    padding: 0px 20px;
    cursor: pointer;
  }
}

.icon{
  &-container{
    margin: 10px;
    background: white;
    border-radius: 50%;
    position: relative;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5F008F;
    cursor: pointer;

    &-selected{
      background: #F2EDFC;
    }
  }

  &-container:hover + .icon-popup {
    display: block;
    box-shadow: 0px 10px 20px -9px rgba(99,72,113,1);
    z-index:100;
    position: absolute;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    padding: 5px 10px;
    margin-left: 50px;
    border-radius: 10%;
    color: #5F008F;
    background-color: white;
  }

  &-check:hover + .icon-popup {
    display: block;
    box-shadow: 0px 10px 20px -9px rgba(99,72,113,1);
    z-index:100;
    position: absolute;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    padding: 5px 10px;
    margin-left: 50px;
    border-radius: 10%;
    color: #5F008F;
    background-color: white;
  }

  &-percentage:hover + .icon-popup {
    display: block;
    box-shadow: 0px 10px 20px -9px rgba(99,72,113,1);
    z-index:100;
    position: absolute;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    padding: 5px 10px;
    margin-left: 50px;
    border-radius: 10%;
    color: #5F008F;
    background-color: white;
  }

  &-circle-background{
    position: absolute;
    width: 22px;
    height: 22px;
  }

  &-check{
    width: 18px;
    height: 18px;
    margin: auto;
    display: flex;
  }

  &-face{
    width: 40px;
  }

  &-star{
    width: 80px;
  }

  &-popup{
    display: none;
  }

  &-obs{
    margin: 0px 4px;
    width: 20px;
    height: 20px;
  }
}

.observation{
  // display: flex;

  &-card{
    background: #FFFFFF;
    border-radius: 8px;
    padding: 10px;
    margin: 15px;
  }

  &-text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    padding: 5px 0px;
    color: #3C1A4E;
  }

  &-text-ia{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 9px;
    padding: 5px 0px;
    color: #3C1A4E;
  }

  &-timestamp {
    display: flex;
    justify-content: space-between;

    &-time {
      order: 1; /* makes this span appear first in the flex container */
      font-size: 12px;
    }
    &-date{
      order: 2; /* makes this span appear second in the flex container */
      font-size: 12px;
    }
  }
}

.unread-observations {
  position: absolute;
  top: -4px;
  right: -5px;
  background-color: rgb(199, 0, 0);
  width: 17px;
  height: 17px;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 0.8rem;
}

.skeleton{
  width: 100%;
  background-color: #AEAED3;
  border-radius: 2px;
  margin: 8px;
  animation-name: skeletonAnimation;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes skeletonAnimation {
    0% {
      opacity: 0.8;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 0.8;
    }
  }

  &-title{
    height: 1.4rem;
  }

  &-subtitle{
    height: 1.1rem;
  }

  &-subtitle-30{
    width: 30%;
    height: 1.1rem;
  }

  &-subtitle-40{
    width: 40%;
    height: 1.1rem;
  }

  &-text{
    height: 0.5rem;
  }

  &-text-20{
    width: 20%;
    height: 0.5rem;
  }

  &-text-60{
    width: 60%;
    height: 0.5rem;
  }

  &-circle {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 2rem;
  }

  &-circle-border {
    background-color: #F7F5FA;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    border: solid 4px #AEAED3;
  }

  &-star {
    animation-name: skeletonAnimation;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    height: 10px;
    width: 10px;
    margin: 2px;
  }
}

.slide-enter-active {
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
}

.slide-enter-to, .slide-leave {
  max-height: 100vh;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.legendClass{
    font-size: 1.4rem;
    font-weight: 600;
    color:#5F008F;
    display: flex;
    justify-content: center;
  }

.btn-pila {
  background-color: #F2EDFC;
  color: #3C1A4E;
  font-weight: 500;
  padding: 1% 10%;
  cursor: pointer;
  border-radius: 5px;
}

.pila-container {
  padding: 1% 2%;
  background-color: #F7F5FA;
  border-radius: 2%;
}

.pila-disclaimer {
  color: #5F008F;
  font-size: 9px;
  font-weight: 600;
  line-height: 1;
  padding: 0.5% 0;
}

.pila-error-connection {
  color: #EA5455;
}
</style>
