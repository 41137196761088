<template>
  <div class="global-events">
    <template id="modal-template" v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div :class="`modal-wrapper ${global_events[index].global_event_type == 3 ? 'modal-wrapper-bulletin' : ''}`">
            <div class="button-moves">
              <button class="button-move button-move-left" v-if="index > 0" @click.prevent="movement(-1)">
                <svg class="arrow" width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 1L0.999999 7L7 13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <button class="button-move button-move-right" v-if="index < ( global_events.length - 1 )" @click.prevent="movement(1)">
                <svg class="arrow" width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 13L7 7L1 1" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </div>                              
            <button class="button-move button-close" @click.prevent="goToStart()">
              <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 490.4 490.4" style="enable-background:new 0 0 490.4 490.4;" xml:space="preserve">
                <path d="M180.3,310.1c2.4,2.4,5.5,3.6,8.7,3.6s6.3-1.2,8.7-3.6l47.6-47.6l47.6,47.6c2.4,2.4,5.5,3.6,8.7,3.6s6.3-1.2,8.7-3.6
                  c4.8-4.8,4.8-12.5,0-17.3l-47.8-47.6l47.6-47.6c4.8-4.8,4.8-12.5,0-17.3s-12.5-4.8-17.3,0l-47.6,47.6l-47.6-47.6
                  c-4.8-4.8-12.5-4.8-17.3,0s-4.8,12.5,0,17.3l47.6,47.6l-47.6,47.6C175.5,297.6,175.5,305.3,180.3,310.1z"/>
              </svg>
            </button>                        
            <div :class="`modal-container ${global_events[index].global_event_type == 3 ? 'bulletin' : ''}`">
              <div class="content-event">
                <div class="event-header">
                  <img class="event-img-cover" :src="global_events[index].cover" alt="" v-if="global_events[index] != null">
                </div>
                <template v-if="global_events[index].global_event_type == 1">
                  <div class="event-content">
                    <p class="event-name">{{global_events[index].name}}</p>
                    <p class="event-description">{{global_events[index].description}}</p>
                    <p class="event-date">{{ global_events[index].event_date | formatDate }}</p>
                    <p class="event-time-zone">Horario (GMT-5)</p>
                    <count-down
                      :year="parseInt(global_events[index].event_date.split('T')[0].split('-')[0])"
                      :month="parseInt(global_events[index].event_date.split('T')[0].split('-')[1])"
                      :day="parseInt(global_events[index].event_date.split('T')[0].split('-')[2])"
                      :hour="parseInt(global_events[index].event_date.split('T')[1].split(':')[0])"
                      :minute="0"
                      :second="0"
                    />
                    <div class="contentainer-actions">
                      <button @click.prevent="assist" class="event-join">
                        Regístrate gratis
                      </button>
                      <button class="go-start" @click.prevent="goToStart">
                        Cerrar
                      </button>
                    </div>
                  </div>
                </template>
                <template v-if="global_events[index].global_event_type == 2">
                  <div class="event-content">
                    <p class="event-name">{{global_events[index].name}}</p>
                    <p class="event-description">{{global_events[index].description}}</p>
                    <div class="contentainer-actions-survey">
                      <button @click.prevent="assist" class="event-survey">
                        Diligenciar encuesta
                      </button>
                      <button class="go-start" @click.prevent="goToStart">
                        Cerrar
                      </button>
                    </div>
                  </div>
                </template>
              </div>
              <div class="dates">
              </div>
            </div>
          </div>
        </div>
      </transition>
    </template>
  </div>
</template>
<script>
import CountDown from './count-down.vue'
import api from '../mixins/api'
import moment from 'moment'
import 'moment/locale/es'  // without this line it didn't work
moment.locale('es')
export default {
  mixins: [api],
  props:['user'],
  data(){
    return{
      showModal: false,
      index: 0,
      global_events: [],
      showEvents: true
    }
  },
  methods:{
    async goToStart(){
      const path = `/api/v1/assist?global_event_id=${this.global_events[this.index].id}&user_id=${this.user.id}&assist=false&view=true&to_show=false`
      const request = await this.requestGetWithoutContent(this.url()+path, {})
      if (request.status == 200){
        this.global_events.splice(this.index, 1)
      }
      this.showModal = false
      window.localStorage.setItem('showEvents', false)
    },
    async checkView(){
      const path = `/api/v1/assist?global_event_id=${this.global_events[this.index].id}&user_id=${this.user.id}&assist=false&view=true&to_show=true`
      const request = await this.requestGetWithoutContent(this.url()+path, {})
    },
    async assist () {
      const path = `/api/v1/assist?global_event_id=${this.global_events[this.index].id}&user_id=${this.user.id}&assist=true&view=true&to_show=false`
      const request = await this.requestGetWithoutContent(this.url()+path, {})
      if (request.status == 200){
        window.open(this.global_events[this.index].url, '_blank');
        this.global_events.splice(this.index, 1)
      }
      window.localStorage.setItem('viewButtonPoll', true)
      this.showModal = false
    },
    movement (movement) {
      this.index = this.index + (movement)
      if (this.global_events[this.index] == null) {
        this.showModal = false
      }
    },
    closeModal () {
      this.showModal = false
      document.querySelector(".vx-navbar-wrapper").style['z-index'] = 41000;
    }
  },
  async mounted () {
    var path = `api/v1/global_events?user_id=${this.user.id}`;
    const request = await this.requestGetWithoutContent(this.url()+path, {})
    this.showEvents = window.localStorage.getItem('showEvents')
    if (this.showEvents == null) this.showEvents = 'true'
    if (request.status == 200) {
      this.global_events = request.data.global_events
      if (this.global_events.length > 0 && this.showEvents == 'true') {
        this.showModal = true
        this.checkView()
      } else {
        console.log(`${this.url()}${path} line 169`)
      }
    } else {
      console.log(`request.status != 200 line 170`)
      console.log(`request ${JSON.stringify(request)}.`)
    }
    document.querySelector(".vx-navbar-wrapper").style['z-index'] = 1;
    document.getElementById('navBar').style = 'z-index:1;'
  },
  watch: {
    index(newValue, oldValue) {
      this.checkView()
      console.log(`change index ${oldValue} -> ${newValue}`)
    }
  },
  components: {
    template: "#modal-template",
    'count-down': CountDown
  },
  filters: {
    formatDate: function(value) {
      let date =  moment(value)
      // date = date.add(5, 'hours')
      date = date.format("LLLL")
      return date.charAt(0).toUpperCase() + date.slice(1)
    }
  }
}
</script>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(60, 26, 78, 0.4);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  margin-top: 10%;
}

.modal-wrapper-bulletin {
  /* margin-top: 2% !important; */
}

.bulletin {
  /* width: 750px !important;
  height: 750px !important; */
  width: 416px !important;
  height: 416px !important;  
}

.modal-container {
  display: flex;
  flex-direction: column;  
  width: 850px;
  height: 415px;
  margin: 0px auto;
  background-color: #FFFFFF;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.content-event{
  display: flex;
  flex-direction: row;
}

.event-header {
  border-radius: 10px 10px 0px 0px;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container, .modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.event-img-cover  {
  width: 416px;
  height: 416px;
  border-radius: 10px;
}

.event-name, .event-description, .event-date, .event-time-zone, .event-join, .event-survey, .go-start{
  font-family: Montserrat;
  font-style: normal;
}

.event-name {
  font-weight: bold;
  font-size: 19px;
  line-height: 28px;
  color: #626262;
  margin-top: 24px;
  margin-left: 24px;    
  margin-right: 24px;      
}

.event-description {
  font-size: 11px;
  line-height: 22px;
  color: #626262;
  margin-top: 24px;
  margin-left: 24px;    
  margin-right: 24px;    
}

.event-date {
  font-weight: bold;
  font-size: 15px;
  line-height: 26px;
  text-align: center;
  color: #626262;
  margin-top: 16px;
}

.event-time-zone {
  font-weight: bold;
  font-size: 9px;
  line-height: 12px;
  text-align: center;
  color: #626262;
  margin-top: 8px;
}

.contentainer-actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 12px;  
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 18px;
}

.contentainer-actions-survey {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 200px;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 18px;
}

.event-join {
  margin: 0 auto;
  margin-top: 0px;
  width: 404px;
  height: 48px;
  background: linear-gradient(45deg, #5F0091 0%, #7F1CB2 100%);
  border-radius: 10px;
  border-style: none;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: #FFFFFF;
  cursor: pointer;
}

.event-survey {
  margin: 0 auto;
  margin-top: 0px;
  width: 404px;
  height: 48px;
  background: linear-gradient(45deg, #5F0091 0%, #7F1CB2 100%);
  border-radius: 10px;
  border-style: none;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: #FFFFFF;
  cursor: pointer;
}

.go-start {
  margin: 0 auto;
  margin-top: 24px;
  margin-bottom: 24px;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: #626262;
  border-style: none;
  background: none;
  cursor: pointer;
}

.button-moves {
  border-radius: 15px;
  position: absolute;
}

.button-move {
  cursor: pointer;
  text-align: center;
  width: 31px;
  height: 31px;
  top: 35%;
  border-style: none;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.5);  
}

.arrow {
  margin-top: 6px;
}

.button-move-left {
  position: fixed;
  left: 5%;
  right: 35%;
}

.button-move-right {
  position: fixed;
  right: 5%;
}
.button-close {
  position: fixed;
  right: 5%;  
  top: 5%;  
}
</style>


