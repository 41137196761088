var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showDetailsAcademic
        ? _c("vs-card", { staticClass: "card-details-academic" }, [
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full lg:w-1/2 mb-base" },
                [
                  _vm.index > 0
                    ? _c(
                        "vs-button",
                        {
                          staticClass: "move-index move-index-left",
                          attrs: { color: "dark", type: "flat" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.index += -1
                            },
                          },
                        },
                        [_vm._v("Anterior")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-full lg:w-1/2 mb-base" },
                [
                  this.project.academic_references.length - 1 > _vm.index
                    ? _c(
                        "vs-button",
                        {
                          staticClass: "move-index move-index-right",
                          attrs: { color: "dark", type: "flat" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.index += 1
                            },
                          },
                        },
                        [_vm._v("Siguiente")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "vs-list" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "vs-list--header title-list vs-header-list-primary",
                  },
                  [
                    _c("div", { staticClass: "list-titles" }, [
                      _c("div", { staticClass: "vs-list--title" }, [
                        _vm._v(_vm._s(_vm.academic_reference.name_institution)),
                      ]),
                    ]),
                  ]
                ),
                _vm._l(_vm.steps, function (step) {
                  return [
                    _vm.academic_reference.checklist[step.key].status > 0
                      ? _c(
                          "div",
                          {
                            key: step.key,
                            staticClass: "vs-list--item item-list",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "vs-list--icon" },
                              [
                                _vm.academic_reference.checklist[step.key]
                                  .status == 1
                                  ? _c("vs-icon", {
                                      attrs: {
                                        icon: "alarm",
                                        color: "warning",
                                      },
                                    })
                                  : _vm._e(),
                                _vm.academic_reference.checklist[step.key]
                                  .status == 2
                                  ? _c("vs-icon", {
                                      attrs: { icon: "done", color: "success" },
                                    })
                                  : _vm._e(),
                                _vm.academic_reference.checklist[step.key]
                                  .status == 3
                                  ? _c("vs-icon", {
                                      attrs: { icon: "clear", color: "danger" },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "list-titles" }, [
                              _c("div", { staticClass: "vs-list--title" }, [
                                _vm._v(_vm._s(step.text)),
                              ]),
                              _c("div", { staticClass: "vs-list--subtitle" }, [
                                _vm._v(
                                  _vm._s(
                                    step.textInformation[
                                      _vm.academic_reference.checklist[step.key]
                                        .status
                                    ]
                                  )
                                ),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ])
        : _vm._e(),
      this.project.academic_references.length > 0
        ? _c(
            "vs-button",
            {
              staticClass: "academic-details",
              attrs: { color: "dark", type: "flat" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.showAcademicCheckList.apply(null, arguments)
                },
              },
            },
            [
              _vm.showDetailsAcademic
                ? [_vm._v("Ocultar detalles")]
                : [_vm._v("Ver detalles")],
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }