var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          attrs: {
            "background-color": "rgba(255,255,255,.6)",
            title: "Proceso",
            active: _vm.popupShowActive,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupShowActive = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "vx-col w-full lg:w-1/1 sm:w-1/1 mb-base" },
            [
              _c(
                "div",
                { staticClass: "vx-card p-2" },
                [
                  _c("div", { staticClass: "text-center" }, [
                    _c("h4", { staticStyle: { color: "#5F0090" } }, [
                      _vm._v(_vm._s(_vm.project.data_basic.person_full_name)),
                    ]),
                    _c("p", { staticClass: "text-grey" }, [
                      _vm._v(
                        "Cédula: " + _vm._s(_vm.project.data_basic.person_id)
                      ),
                    ]),
                    _c("p", { staticClass: "text-grey" }, [
                      _vm._v(_vm._s(_vm.project.data_basic.current_activity)),
                    ]),
                  ]),
                  Object.keys(_vm.details).length > 0
                    ? _c(
                        "vs-tabs",
                        _vm._l(_vm.details, function (detail, index_detail) {
                          return _c(
                            "vs-tab",
                            {
                              key: index_detail,
                              attrs: { label: detail.title },
                            },
                            [
                              _c(
                                "vs-table",
                                { attrs: { data: _vm.users, noDataText: "" } },
                                [
                                  _c(
                                    "template",
                                    { slot: "thead" },
                                    [
                                      _c("vs-th", [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(detail.observations) +
                                            "\n                "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }